import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./constants";
import { getInformationSelector } from "modals/Informationcontactpouppage/Features/informationSlice";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // sign in
    signIn: builder.mutation({
      query: ({ email, password }) => ({
        url: `sign-in`,
        method: "POST",
        body: { email, password },
      }),
    }),
    // sign up
    signUp: builder.mutation({
      query: (data) => ({
        url: `sign-up`,
        method: "POST",
        body: data,
      }),
    }),
    //   partner-account-detail
    partnerAccountDetail: builder.mutation({
      query: ({ id }) => ({
        url: `partner-account-detail?id=${id}`,
        method: "GET",
      }),
    }),
    // get bank
    getBank: builder.mutation({
      query: () => ({
        url: `banks`,
        method: "GET",
      }),
    }),
    // get province and city
    getProvince: builder.mutation({
      query: () => ({
        url: `get-province-and-city`,
        method: "GET",
      }),
    }),
    // get artist
    getArtist: builder.mutation({
      query: ({ id }) => ({
        url: `get-artist?id=${id}`,
        method: "GET",
      }),
    }),
    // create artist
    createArtist: builder.mutation({
      query: (data) => ({
        url: `create-artist`,
        method: "POST",
        body: data,
      }),
    }),
    // update artist
    updateArtist: builder.mutation({
      query: (data) => ({
        url: `update-artist`,
        method: "PUT",
        body: data,
      }),
    }),
    // get all partner management
    getAllPartnerManagement: builder.mutation({
      query: ({ id }) => ({
        url: `partner-management?id=${id}`,
        method: "GET",
      }),
    }),
    // partner assesment
    partnerAssesment: builder.mutation({
      query: (data) => ({
        url: `partner-assesment`,
        method: "POST",
        body: data,
      }),
    }),
    // parner deactivate
    partnerDeactivate: builder.mutation({
      query: (data) => ({
        url: `partner-deactivate`,
        method: "POST",
        body: data,
      }),
    }),
    // get all inboxes
    getAllInboxes: builder.mutation({
      query: ({ uid }) => ({
        url: `get-all-messages?uid=${uid}`,
        method: "GET",
      }),
    }),
    // update inboxes
    updateInboxes: builder.mutation({
      query: (data) => ({
        url: "update-messages",
        method: "PUT",
        body: data,
      }),
    }),
    // check email
    checkEmail: builder.mutation({
      query: ({ email }) => ({
        url: `check-email?email=${email}`,
        method: "GET",
      }),
    }),
    // check company name
    checkCompanyName: builder.mutation({
      query: ({ company_name }) => ({
        url: `check-company-name?company_name=${company_name}`,
        method: "GET",
      }),
    }),
    // get categories content
    getCategoriesContent: builder.mutation({
      query: () => ({
        url: `get-categories`,
        method: "GET",
      }),
    }),
    // get content type
    getContentType: builder.mutation({
      query: () => ({
        url: `get-content-type`,
        method: "GET",
      }),
    }),
    // get subs plan
    getSubsPlan: builder.mutation({
      query: () => ({
        url: `get-subs-plan`,
        method: "GET",
      }),
    }),
    // get content list
    getContentList: builder.mutation({
      query: ({ id }) => ({
        url: `get-content-list?id=${id}`,
        method: "GET",
      }),
    }),
    // create content
    createContent: builder.mutation({
      query: (data) => ({
        url: "create-content",
        method: "POST",
        body: data,
      }),
    }),
    // content detail
    contentDetail: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-detail-content?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // get price by content id
    getPriceContent: builder.mutation({
      query: ({ content_id }) => ({
        url: `get-price-content?content_id=${content_id}`,
        method: "GET",
      }),
    }),
    // accept content
    acceptContent: builder.mutation({
      query: (data) => ({
        url: `accept-content`,
        method: "POST",
        body: data,
      }),
    }),
    // reject content
    rejectContent: builder.mutation({
      query: (data) => ({
        url: `reject-content`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useGetProvinceMutation,
  usePartnerAccountDetailMutation,
  useGetBankMutation,
  useGetArtistMutation,
  useCreateArtistMutation,
  useUpdateArtistMutation,
  useGetAllPartnerManagementMutation,
  usePartnerAssesmentMutation,
  usePartnerDeactivateMutation,
  useGetAllInboxesMutation,
  useUpdateInboxesMutation,
  useCheckEmailMutation,
  useCheckCompanyNameMutation,
  useGetCategoriesContentMutation,
  useGetContentListMutation,
  useGetContentTypeMutation,
  useGetSubsPlanMutation,
  useCreateContentMutation,
  useContentDetailMutation,
  useGetPriceContentMutation,
  useAcceptContentMutation,
  useRejectContentMutation,
} = api;
