import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { format, eachDayOfInterval, parse, compareDesc } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile } from "sheetjs-style";

const TableReportDR = forwardRef(
  ({ tableData, start_date, end_date, isLoading }, ref) => {
    const [data, setData] = useState([]);

    const staticColumns = useMemo(
      () => [
        {
          accessorKey: "subject",
          header: "Subjek",
        },
        {
          accessorKey: "sub_subject",
          header: "",
        },
        {
          accessorKey: "total",
          header: "Total",
          className: "text-right",
        },
      ],
      []
    );

    const generateDynamicColumns = (startDate, endDate) => {
      const start = parse(startDate, "yyyy-MM-dd", new Date());
      const end = parse(endDate, "yyyy-MM-dd", new Date());

      if (start > end) {
        toast.error("Tanggal Akhir harus lebih besar dari Tanggal Mulai", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
        return [];
      }

      try {
        const dates = eachDayOfInterval({ start, end });

        dates.sort((a, b) => compareDesc(a, b));

        return dates.map((date) => ({
          accessorKey: format(date, "MM-dd"),
          header: format(date, "MM-dd"),
          className: "text-right px-2",
        }));
      } catch (error) {
        toast.error("Interval tidak valid");
        return [];
      }
    };

    const dynamicColumns = useMemo(
      () => generateDynamicColumns(start_date, end_date),
      [start_date, end_date]
    );

    const columns = useMemo(
      () => [...staticColumns, ...dynamicColumns],
      [staticColumns, dynamicColumns]
    );

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
    const rows = table.getPrePaginationRowModel().rows;

    const handleDownload = (type) => {
      if (type === "pdf") {
        const doc = new jsPDF("landscape");
        const maxColumnsPerTable = 15;

        const dataTable = rows.map((row) =>
          columns.map((col) => row.original[col.accessorKey])
        );
        const tableHeaders = columns.map((c) => c.header);

        const createPDFTable = (
          doc,
          headers,
          data,
          startX = 10,
          startY = 10
        ) => {
          autoTable(doc, {
            head: [headers],
            body: data,
            startY: startY,
            margin: { left: startX, right: startX },
            styles: {
              cellWidth: "wrap",
              halign: "right", // Align right by default
            },
            headStyles: {
              halign: "right", // Align headers to right by default
            },
            columnStyles: {
              0: { halign: "left" },
              1: { halign: "left" }, // First column align left
            },
          });
        };

        if (columns.length > maxColumnsPerTable) {
          let startY = 10;
          let remainingColumns = columns.length - 4;
          let currentColumns = 4;
          let currentTableData = [];

          const fixedColumns = columns.slice(0, 4);
          const fixedHeaders = fixedColumns.map((c) => c.header);
          const fixedData = dataTable.map((row) => row.slice(0, 4));

          while (remainingColumns > 0) {
            currentColumns = Math.min(remainingColumns, maxColumnsPerTable - 4);
            const startColumnIndex = columns.length - remainingColumns;
            const dynamicColumns = columns.slice(
              startColumnIndex,
              startColumnIndex + currentColumns
            );
            const dynamicHeaders = dynamicColumns.map((c) => c.header);
            const dynamicData = dataTable.map((row) =>
              row.slice(startColumnIndex, startColumnIndex + currentColumns)
            );

            const headers = [...fixedHeaders, ...dynamicHeaders];
            currentTableData = fixedData.map((row, index) => [
              ...row,
              ...dynamicData[index],
            ]);

            createPDFTable(doc, headers, currentTableData, 10, startY);

            startY = doc.autoTable.previous.finalY + 10;
            remainingColumns -= currentColumns;
          }
        } else {
          createPDFTable(doc, tableHeaders, dataTable);
        }

        doc.save("rekap-DR.pdf");
      } else if (type === "excel") {
        const worksheetData = [columns.map((col) => col.header)];
        rows.forEach((row) => {
          worksheetData.push(
            columns.map((col) => row.original[col.accessorKey])
          );
        });

        const worksheet = utils.aoa_to_sheet(worksheetData);
        const columnWidths = columns.map(() => ({ wch: 15 }));
        worksheet["!cols"] = columnWidths;

        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };

        const backgroundStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align data cells to right by default
          },
        };

        const headerStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "E4EDFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align headers to right by default
          },
        };

        columns.forEach((col, colIndex) => {
          const cellAddress = utils.encode_cell({ r: 0, c: colIndex });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = {
              ...headerStyle,
              border: borderStyle,
              alignment: {
                ...headerStyle.alignment,
                horizontal: colIndex <= 1 ? "left" : "right", // First header column align left
              },
            };
          }
        });

        worksheetData.forEach((row, rowIndex) => {
          row.forEach((_, colIndex) => {
            const cellAddress = utils.encode_cell({ r: rowIndex, c: colIndex });
            if (worksheet[cellAddress]) {
              if (rowIndex === 0) {
                worksheet[cellAddress].s = {
                  ...headerStyle,
                  border: borderStyle,
                  alignment: {
                    ...headerStyle.alignment,
                    horizontal: colIndex <= 1 ? "left" : "right", // First header column align left
                  },
                };
              } else {
                worksheet[cellAddress].s = {
                  ...backgroundStyle,
                  border: borderStyle,
                  alignment: {
                    ...backgroundStyle.alignment,
                    horizontal: colIndex <= 1 ? "left" : "right", // First column align left, others align right
                  },
                };
              }
            }
          });
        });

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Report");
        writeFile(workbook, "rekap-DR.xlsx");
      }
    };

    useImperativeHandle(ref, () => ({
      handleDownload,
    }));

    useEffect(() => {
      if (tableData && tableData.length > 0) {
        const allDates = generateDynamicColumns(start_date, end_date).map(
          (col) => col.accessorKey
        );

        const newData = tableData?.flatMap((entry) =>
          entry.data?.map((subEntry, index) => {
            const dateValues = {};
            allDates.forEach((date) => {
              dateValues[date] = 0;
            });

            subEntry.date?.forEach((d) => {
              dateValues[d.date] = Number(d.trx) || 0;
            });

            if (subEntry.dr_status === "Unknown") {
              return {
                subject: index === 0 ? entry.subject : "",
                sub_subject: "Unknown",
                total: Number(subEntry.total || 0),
                dr_status: "Unknown",
                ...dateValues,
              };
            }

            return {
              subject: index === 0 ? entry.subject : "",
              sub_subject: subEntry.dr_code.includes(
                "<h1>Developer Over Qps</h1>"
              )
                ? "Developer Over Qps"
                : `${subEntry.dr_code}`,
              total: Number(subEntry.total || 0),
              dr_status: subEntry.dr_status.includes(
                "<h1>Developer Over Qps</h1>"
              )
                ? "Developer Over Qps"
                : `${subEntry.dr_status}`,
              ...dateValues,
            };
          })
        );

        setData(newData);
      } else {
        setData([]);
      }
    }, [tableData, start_date, end_date]); // Include start_date and end_date as dependencies

    return (
      <>
        <ToastContainer />
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`first:rounded-l-xl last:rounded-r-xl px-2 py-4 whitespace-nowrap bg-gray_100_01 text-blue_gray_400 text-left ${
                      header.column.columnDef.className || ""
                    }`}
                  >
                    {header.column.columnDef.header}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? // Render skeleton rows
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    {columns.map((col) => (
                      <td key={col.id}>
                        <Skeleton width={col.width || 100} height={30} />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row, rowIndex) => (
                  <React.Fragment key={row.id}>
                    {row.original.subject && rowIndex !== 0 && (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{
                            borderBottom: "1px solid #EBEBEB",
                            height: "10px",
                          }}
                        ></td>
                      </tr>
                    )}
                    <tr>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={`px-2 py-2 ${
                            cell.column.id === "sub_subject"
                              ? "cursor-pointer"
                              : ""
                          } ${cell.column.columnDef.className || ""}`}
                          title={
                            cell.column.id === "sub_subject"
                              ? row.original.dr_status
                              : ""
                          }
                        >
                          {cell.column.id === "total" ||
                          dynamicColumns
                            .map((col) => col.accessorKey)
                            .includes(cell.column.id)
                            ? Number(cell.getValue()).toLocaleString("id-ID")
                            : cell.getValue()}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
          </tbody>
        </table>
      </>
    );
  }
);

export default TableReportDR;
