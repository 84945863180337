import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const userSignIn = createAsyncThunk(
  "userSignIn/userSignIn",
  async ({ email, password }) => {
    try {
      const response = await axios.post(`${baseUrl}login`, {
        email: email,
        password: password,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

const userSignInEntity = createEntityAdapter({
  selectId: (userSignIn) => {
    // const storedUser = JSON.parse(localStorage.getItem('user'));
    // console.log(storedUser.id)
    return userSignIn.id
    // return storedUser.id
  },
});

const userSignInSlice = createSlice({
  name: "userSignIn",
  initialState: userSignInEntity.getInitialState({
    firstName: null,
    lastName: null,
    idLevel: null,
    id: null,
  }),
  extraReducers: {
    [userSignIn.pending]: (state) => {
      // console.log("pending");
    },
    [userSignIn.fulfilled]: (state, action) => {
      userSignInEntity.setAll(state, action.payload);
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.idLevel = action.payload.id_level;
      state.id = action.payload.id;
      localStorage.setItem('user', JSON.stringify({
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        idLevel: action.payload.id_level,
        id: action.payload.id,
      }));
      // console.log(action.payload);
    },
    [userSignIn.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

// export const userSignInSelector = userSignInEntity.getSelectors(
//   (state) => state.userSignIn
//);

export const userSignInSelector = (state) => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  if (storedUser) {
    // console.log(storedUser)
    return storedUser;
  } else {
    return state.userSignIn;
  }
};

export default userSignInSlice.reducer;
