import React, { useEffect, useState } from "react";
import { SelectBox, Img, Button } from "components";
import { BASE_PATH_IMAGE, baseUrl } from "app/constants";
import { useGetPartnerQuery } from "app/api1";
import TableReportTarif from "./TableReportTarif";
import axios from "axios";
import { utils, writeFile } from "sheetjs-style";

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2014; year <= currentYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
  }
  return years;
};

const generateMonths = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames.map((name, index) => ({
    label: name,
    value: (index + 1).toString(),
  }));
};

const months = generateMonths();
const years = generateYears();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const methods = [
  { value: "", label: "Semua" },
  { value: "PREMIUM", label: "Premium" },
  { value: "1x", label: "Pembelian 1x" },
  { value: "RENEWAL", label: "Renewal" },
];

const selectDownload = [
  // { value: "pdf", label: "PDF" },
  { value: "excel", label: "Excel" },
];

const columns = ["Keyword", "Tarif", "Transaksi Sukses", "Total Pendapatan"];

const ReportTarifComponents = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const idLevel = users.idLevel;

  const [filter, setFilter] = useState({
    year: currentYear.toString(),
    month: currentMonth.toString(),
    method: "",
    partner: "",
    uid: users?.id,
  });

  const { data: partners } = useGetPartnerQuery();

  const filteredMonths =
    filter.year === currentYear.toString()
      ? months.filter((month) => parseInt(month.value, 10) <= currentMonth)
      : months;
  const selectedMonth = filteredMonths.find((m) => m.value === filter.month);
  const selectedYear = years.find((y) => y.value === filter.year);
  const selectedMethod = methods.find((m) => m.value === filter.method);

  const handleSelectChange = (selectedOption) => {
    downloadCSV();
  };

  const [responseTarif, setReportTarif] = useState([]);
  const [loading, setLoading] = useState(true);

  const GenerateReportTarif = async () => {
    const dataResponse = await axios.post(`${baseUrl}report_tarif`, {
      uid: filter.uid,
      month: filter.month,
      year: filter.year,
      partner: filter.partner,
    });

    if (dataResponse.data.response == null) {
      setReportTarif([]);
      setLoading(false);
    } else {
      setReportTarif(dataResponse.data.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    GenerateReportTarif();
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setLoading(true);
    GenerateReportTarif();
  };

  const getFormattedDate = (date) => {
    const day = date.getDate();
    return day < 10 ? `0${day}` : day;
  };

  const getDaysInCurrentMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const dates = [];

    // Get the last day of the current month
    const lastDay = new Date().getDate();

    for (let day = lastDay; day >= 1; day--) {
      const date = new Date(year, month, day);
      dates.push(getFormattedDate(date));
    }

    return dates;
  };

  const lastDays = getDaysInCurrentMonth();
  const downloadCSV = () => {
    const csvData = [];

    // Add header
    const headers = [...columns, ...lastDays];
    csvData.push(headers);

    // Add rows to the csvData
    responseTarif.forEach((item) => {
      const row = [
        item.keyword,
        item.tarif,
        item.transaksiSukses,
        item.totalPendapatan,
      ];

      // Append the values
      lastDays.forEach((day) => {
        row.push(item.dataPerDay[parseInt(day)] || 0);
      });

      csvData.push(row);
    });

    const worksheet = utils.aoa_to_sheet(csvData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Report Tarif");

    writeFile(workbook, "report_tarif.xls");
  };

  return (
    <>
      <div className="bg-white_A700 flex flex-col gap-6 items-start justify-center max-w-full p-[26px] sm:px-5 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none w-full">
        <div
          className={
            idLevel === 1 || idLevel === 14
              ? "grid grid-cols-6 gap-3 w-[100%]"
              : "grid grid-cols-6 gap-6 w-[100%]"
          }
        >
          {/* Months */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628297"
              placeholder={selectedMonth ? selectedMonth.label : ""}
              options={filteredMonths}
              getOptionLabel={(e) => (
                <div className="flex items-center">
                  <span>{e.label}</span>
                </div>
              )}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
              value={selectedMonth ? selectedMonth.value : ""}
              onChange={(value) => {
                setFilter({ ...filter, month: value });
              }}
            />
          </div>

          {/* Years */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="zipcode"
              placeholder={selectedYear.label}
              isSearchable={false}
              isMulti={false}
              options={years}
              getOptionLabel={(e) => (
                <div className="flex items-center">
                  <span>{e.value}</span>
                </div>
              )}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
              value={selectedYear ? selectedYear.value : ""}
              onChange={(value) => {
                setFilter({ ...filter, year: value });
              }}
            />
          </div>

          {/* Method */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628351"
              placeholder={selectedMethod.label}
              isSearchable={false}
              isMulti={false}
              onChange={(value) => {
                setFilter({ ...filter, method: value });
              }}
              options={methods?.map((method) => ({
                label: method.label,
                value: method.value,
              }))}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span>{option.label}</span>
                </div>
              )}
              value={selectedMethod ? selectedMethod.value : ""}
              indicator={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_arrowdown_gray_800.svg"
                  }
                  className="h-6 w-6"
                  alt="arrow_down"
                />
              }
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
            />
          </div>

          {/* Partner */}
          {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              {idLevel === 1 || idLevel === 14 ? (
                <SelectBox
                  className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full max-w-screen-mdreal"
                  placeholderClassName="text-gray_800"
                  name="frame628350"
                  placeholder={"Semua Partner"}
                  isSearchable={false}
                  isMulti={false}
                  options={[
                    { label: "Semua Partner", value: "" },
                    ...(partners?.response?.map((partner) => ({
                      label: partner.partner_name,
                      value: partner.value,
                    })) || []),
                  ]}
                  getOptionLabel={(option) => (
                    <div className="flex items-center">
                      <span>{option.label}</span>
                    </div>
                  )}
                  shape="RoundedBorder6"
                  size="sm"
                  variant="OutlineGray200"
                  onChange={(selectedOption) => {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      partner: selectedOption,
                    }));
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {/* Update button */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[104px] w-[125px] h-[47px]"
              leftIcon={
                <Img
                  src={BASE_PATH_IMAGE + "img_search_white_a700.svg"}
                  className="mr-2.5"
                  alt="search"
                />
              }
              shape="RoundedBorder8"
              size="md"
              variant="OutlineGray200"
              onClick={handleButtonClick}
            >
              <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                Update
              </div>
            </Button>
          </div>

          {/* Unduh Laporan */}
          {idLevel === 1 || idLevel === 14 ? (
            <>
              <div className="justify-end place-items-center">
                <SelectBox
                  className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full w-full"
                  placeholderClassName="text-gray_800"
                  name="frame628350"
                  placeholder="Unduh Laporan"
                  isSearchable={false}
                  isMulti={false}
                  options={selectDownload}
                  onChange={handleSelectChange}
                  shape="RoundedBorder6"
                  size="sm"
                  variant="OutlineGray200"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-span-3 mdreal:col-span-1 place-items-center"></div>
              <div className="flex justify-end place-items-center">
                <SelectBox
                  className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full w-full"
                  placeholderClassName="text-gray_800"
                  name="frame628350"
                  placeholder="Unduh Laporan"
                  isSearchable={false}
                  isMulti={false}
                  options={selectDownload}
                  onChange={handleSelectChange}
                  shape="RoundedBorder6"
                  size="sm"
                  variant="OutlineGray200"
                />
              </div>
            </>
          )}
        </div>

        <div className="flex w-full">
          <TableReportTarif
            responseTarif={responseTarif}
            columns={columns}
            loading={loading}
            day={lastDays}
          />
        </div>
      </div>
    </>
  );
};

export default ReportTarifComponents;
