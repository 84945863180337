import { Text, Line, Input, Img } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCompany } from "../Features/getCompanySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { useSelector } from "react-redux";
import { BASE_PATH_IMAGE } from "app/constants";

export const CompanyDetailComponents = () => {
  // ======= State Get Company =======
  const dispatch = useDispatch();
  const [company, setCompany] = useState(null);
  const users = useSelector(userSignInSelector);

  // Get Company
  useEffect(() => {
    if (users) {
      let uuid = users.id;
      dispatch(getCompany({ uid: uuid }))
        .then(unwrapResult)
        .then((obj) => {
          const companys = obj;
          setCompany(companys);
        })
        .catch((obj) => {
          alert(obj.message);
        });
    }
  }, []);

  // ================================
  return (
    <>
      <div className="flex flex-col gap-6 items-start justify-start w-full">
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text
            className="font-medium text-gray_800 text-left w-auto"
            as="h5"
            variant="h5"
          >
            Nama Perusahaan
          </Text>
          <Input
            wrapClassName="flex w-full"
            className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
            name="frame628247"
            placeholder={company?.company_name ?? ""}
            prefix={
              <Img
                src={
                  process.env.PUBLIC_URL +
                  "/images/img_heroiconsbuildingoffice2solid.svg"
                }
                className="mt-auto mb-px mr-2.5"
                alt="heroicons:building-office-2-solid"
              />
            }
            shape="RoundedBorder10"
            size="md"
            variant="OutlineGray400"
            readOnly
          ></Input>
        </div>
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text
            className="font-medium text-gray_800 text-left w-auto"
            as="h5"
            variant="h5"
          >
            Alamat Perusahaan
          </Text>
          <Input
            wrapClassName="flex w-full"
            className="font-normal leading-[normal] md:h-auto not-italic p-0 placeholder:text-gray_800 sm:h-auto text-gray_800 text-left text-sm tracking-[0.14px] w-full"
            name="frame628247_One"
            placeholder={company?.company_address ?? ""}
            prefix={
              <Img
                src={
                  process.env.PUBLIC_URL +
                  "/images/img_carbonlocationcompanyfilled.svg"
                }
                className="mt-px mb-auto mr-2.5"
                alt="carbon:location-company-filled"
              />
            }
            shape="RoundedBorder10"
            size="lg"
            variant="OutlineGray400"
            readOnly
          ></Input>
        </div>
        <div className="gap-5 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Provinsi
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="frame628247_Two"
              placeholder={company?.provinces ?? ""}
              prefix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_phmappinfill.svg"}
                  className="mt-px mb-auto mr-2.5"
                  alt="ph:map-pin-fill"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
              readOnly
            ></Input>
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Kota
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="frame628247_Three"
              placeholder={company?.city ?? ""}
              prefix={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_icroundlocationcity.svg"
                  }
                  className="mt-px mb-auto mr-2.5"
                  alt="ic:round-location-city"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
              readOnly
            ></Input>
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Kode Pos
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="zipcode"
              placeholder={company?.postal_code ?? ""}
              prefix={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_carboncharacterwholenumber.svg"
                  }
                  className="mt-px mb-auto mr-2.5"
                  alt="carbon:character-whole-number"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
              readOnly
            ></Input>
          </div>
        </div>
        <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen NIB
            </Text>
            {company?.nib !== "" ? (
              <a href={company?.nib} download={company?.nib} target="_blank">
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.nib ? "Download NIB" : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen PKP
            </Text>
            {company?.pkp !== "" ? (
              <a href={company?.pkp} download={company?.pkp} target="_blank">
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.pkp ? "Download PKP" : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
        </div>
        <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen NPWP
            </Text>
            {company?.npwp !== "" ? (
              <a href={company?.npwp} download={company?.npwp} target="_blank">
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.npwp ? "Download NPWP" : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen Akta Notaris Pendirian
            </Text>
            {company?.akta_url !== "" ? (
              <a
                href={company?.akta_url}
                download={company?.akta_url}
                target="_blank"
              >
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.akta_url
                    ? "Download Akta Notaris Pendirian"
                    : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
        </div>
        <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen Akta Notaris Perubahan Terakhir
            </Text>
            {company?.change_akta !== "" ? (
              <a
                href={company?.change_akta}
                download={company?.change_akta}
                target="_blank"
              >
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.change_akta
                    ? "Download Akta Notaris Perubahan Terakhir"
                    : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Dokumen Surat Pernyataan yang Ditandatangani Direktur
            </Text>
            {company?.statements !== "" ? (
              <a
                href={company?.statements}
                download={company?.statements}
                target="_blank"
              >
                <Text
                  className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {company?.statements
                    ? "Download Surat Pernyataan yang Ditandatangani Direktur"
                    : "Data tidak ditemukan"}
                </Text>
              </a>
            ) : (
              <Text
                className="font-normal not-italic text-blue_900 text-left tracking-[0.14px] w-auto"
                variant="body1"
              >
                Data tidak ditemukan
              </Text>
            )}
          </div>
        </div>
      </div>
      <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
        <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
          <Text
            className="font-medium text-gray_800 text-left w-auto"
            as="h5"
            variant="h5"
          >
            Username
          </Text>
          <Input
            wrapClassName="flex w-full"
            className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
            name="frame628247_Four"
            placeholder={company?.email ?? ""}
            prefix={
              <Img
                src={
                  process.env.PUBLIC_URL +
                  "/images/img_iconparkoutlineeditname.svg"
                }
                className="mt-auto mb-px mr-2.5"
                alt="icon-park-outline:edit-name"
              />
            }
            shape="RoundedBorder10"
            size="md"
            variant="OutlineGray400"
            readOnly
          ></Input>
        </div>
        <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
          <Text
            className="font-medium text-gray_800 text-left w-auto"
            as="h5"
            variant="h5"
          >
            Kata Sandi
          </Text>
          <Input
            wrapClassName="flex w-full"
            className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
            name="password"
            placeholder="*********"
            prefix={
              <Img
                src={
                  process.env.PUBLIC_URL +
                  "/images/img_solarlockpasswordbold.svg"
                }
                className="mt-[3px] mb-auto mr-2.5"
                alt="solar:lock-password-bold"
              />
            }
            shape="RoundedBorder10"
            size="sm"
            variant="OutlineGray400"
            readOnly
          ></Input>
        </div>
      </div>
    </>
  );
};
export default CompanyDetailComponents;
