import React from "react";
import { Text } from "components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";

const MessageContent = ({ messages }) => {
  const { messageId } = useParams();
  // console.log(messageId);
  const selectedMessage = messages?.find(
    (message) => message.id === Number(messageId)
  );
  const users = useSelector(userSignInSelector);
  // const users = useSelector((state) => state.userSignIn);
  const idLevel = users.idLevel ?? 0;

  // Contoh Response Message yang Contain HTML
  const APIResponsePartner = {
    html: selectedMessage.message,
    // html: "<p>Kepada DPA Partner, <p><br>Terima kasih, Akun DPA Anda telah aktif!</br><br>Silahkan Login di https://staging.ina17.com/tsel/ menggunakan username dan kata sandi yang telah berhasil didaftarkan.. </br><br>Terima kasih,</br><br>DPA Support</br>",
  };

  const APIResponseAdmin = {
    html: selectedMessage.message,
    // html: "<p>Kepada Admin DPA,</p><br>Satu Partner. Telah berhasil mendaftar sebagai partner baru.</br><br>Terima kasih,</br><br>DPA Support</br>",
  };

  if (!selectedMessage) {
    return (
      <Text
        className="font-semibold text-blue_gray_400 text-left w-auto"
        as="h4"
        variant="h4"
      >
        Pilih pesan apa pun dalam daftar untuk melihatnya di sini.
      </Text>
    );
  }
  return (
    <>
      <div className="w-full max-w-[820px] h-full p-5">
        <div className="flex flex-col gap-4 divide-y divide divide-gray_200 h-full bg-white_A700 rounded-xl shadow">
          <div className="flex flex-col gap-2 px-5 pt-5">
            <Text
              className="font-semibold text-gray_800 text-left w-auto"
              as="h4"
              variant="h4"
            >
              {selectedMessage.sender} - {selectedMessage.title}
            </Text>
            <Text
              className="font-normal not-italic text-blue_gray_400 text-left tracking-[0.14px] w-auto"
              variant="body1"
            >
              {selectedMessage.date}
            </Text>
          </div>
          {idLevel === 1 || idLevel === 14 ? (
            <div
              className="p-5"
              dangerouslySetInnerHTML={{
                __html: APIResponseAdmin.html,
              }}
            />
          ) : (
            <div
              className="p-5"
              dangerouslySetInnerHTML={{
                __html: APIResponsePartner.html,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MessageContent;
