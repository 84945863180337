import { Menu } from "@headlessui/react";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { baseTsel, baseVrbt } from "app/constants";
import { useAuth } from "app/AuthContext";

const HeaderNavDropDown = () => {
  const { user } = useAuth();
  const levelId = user.type;

  const handleLogout = () => {
    localStorage.removeItem("auth");
    localStorage.clear();
  };

  return (
    <>
      <Menu as="div" className="relative font-poppins text-sm z-10">
        <Menu.Button
          as="button"
          className="p-2 bg-white rounded-full drop-shadow"
        >
          <BiChevronDown />
        </Menu.Button>

        {levelId === 1 || levelId === "1" ? (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to={`${baseVrbt}`} onClick={handleLogout}>
                Keluar
              </Link>
            </Menu.Item>
          </Menu.Items>
        ) : (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to="account">My Account</Link>
            </Menu.Item>
            <Menu.Item as="div">
              <Link to="help">Help</Link>
            </Menu.Item>
            <Menu.Item as="div" className="border"></Menu.Item>
            <Menu.Item as="div">
              <Link to="/" onClick={handleLogout}>
                Log Out
              </Link>
            </Menu.Item>
          </Menu.Items>
        )}
      </Menu>
    </>
  );
};

export default HeaderNavDropDown;
