import { Img, Text } from "components";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const CustomLink = ({
  iconActiveURL,
  iconInActiveURL,
  title,
  to,
  isSubMenu,
}) => {
  const location = useLocation();
  const [isCleaned, setCleaned] = useState(true);

  const checkPath = (path) => {
    if (location.pathname.endsWith("/")) {
      setCleaned(false);
    }

    if (!isCleaned) {
      const newPath = location.pathname.slice(0, -1);
      if (newPath === path) {
        return true;
      }
    }

    if (location.pathname === path) {
      return true;
    }
  };

  const isActive = () => {
    return location.pathname === to || location.pathname.startsWith(`${to}/`);
  };

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        checkPath(to)
          ? "flex items-center gap-3.5 px-5 py-3 rounded-[10px] bg-[#B90024] text-white_A700"
          : "flex items-center gap-3.5 px-5 py-3 rounded-[10px] bg-white_A700 text-blue_gray_400"
      }
      end
    >
      {!isSubMenu && (
        <Img
          src={checkPath(to) ? iconActiveURL : iconInActiveURL}
          className="h-7 w-[25px]"
          alt="icon"
        />
      )}
      <Text
        className="font-medium font-poppins text-left w-auto"
        as="h5"
        variant="h5"
      >
        {title}
      </Text>
    </NavLink>
  );
};

export default CustomLink;
