import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getPeriode = createAsyncThunk(
  "getPeriode/getPeriode",
  async () => {
    /// ******** API Periode ********
    const response = await axios.get(`${baseUrl}get_price_rate`);
    return response.data.response;
  }
);

const getPeriodeEntity = createEntityAdapter({
  selectId: (getPeriode) => getPeriode.id,
});

const getPeriodeSlice = createSlice({
  name: "getPeriode",
  initialState: getPeriodeEntity.getInitialState(),
  extraReducers: {
    [getPeriode.pending]: (state) => {
      // console.log('pending')
    },
    [getPeriode.fulfilled]: (state, action) => {
      getPeriodeEntity.setAll(state, action.payload);
    },
    [getPeriode.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getPeriodeSelector = getPeriodeEntity.getSelectors(
  (state) => state.getPeriode
);
export default getPeriodeSlice.reducer;
