import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const TransactionRevenue = createAsyncThunk(
  "TransactionRevenue/TransactionRevenue",
  async ({ uid }) => {
    /// ******** API oncetime_success_transaction_per_day ********
    const response = await axios.post(`${baseUrl}v2/mt-transaction-revenue`, {
      uid: uid,
    });
    return response.data.response;
  }
);
