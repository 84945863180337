import { Text, Line, Input, Img } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBank } from "../Features/getBankSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { useSelector } from "react-redux";
import { BASE_PATH_IMAGE } from "app/constants";

export const BankAccountComponents = () => {
  // ======= State Get Bank =======
  const dispatch = useDispatch();
  const [bank, setBank] = useState(null);
  const users = useSelector(userSignInSelector);

  // Get Bank
  useEffect(() => {
    if (users) {
      let uuid = users.id;
      dispatch(getBank({ uid: uuid }))
        .then(unwrapResult)
        .then((obj) => {
          const bank = obj;
          // console.log(bank);
          setBank(bank);
        })
        .catch((obj) => {
          alert(obj.message);
        });
    }
  }, []);

  // ================================
  return (
    <>
      <div className="flex flex-col gap-6 items-start justify-start w-full">
        <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Nama Bank
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="frame628247"
              placeholder={bank?.bank_name ?? ""}
              prefix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_home.svg"}
                  className="mt-px mb-auto mr-2.5"
                  alt="home"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
            ></Input>
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Cabang Bank
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="frame628247_One"
              placeholder={bank?.owner_name ?? ""}
              prefix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_home.svg"}
                  className="mt-px mb-auto mr-2.5"
                  alt="home"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
            ></Input>
          </div>
        </div>
        <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Nomor Rekening Bank
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              type="number"
              name="frame628247_Two"
              placeholder={bank?.no_rekening ?? ""}
              prefix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_mobile.svg"}
                  className="mt-px mb-auto mr-2.5"
                  alt="mobile"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
            ></Input>
          </div>
          <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="font-medium text-gray_800 text-left w-auto"
              as="h5"
              variant="h5"
            >
              Nama Pemilik Rekening
            </Text>
            <Input
              wrapClassName="flex w-full"
              className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
              name="frame628247_Three"
              placeholder={bank?.bank_branch ?? ""}
              prefix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_mdiuser.svg"}
                  className="mt-auto mb-px mr-2.5"
                  alt="mdi:user"
                />
              }
              shape="RoundedBorder10"
              size="md"
              variant="OutlineGray400"
            ></Input>
          </div>
        </div>
      </div>
    </>
  );
};
export default BankAccountComponents;
