import React, { useEffect, useState } from "react";
import { Button, Img, Text } from "components";
import { Link, useSearchParams } from "react-router-dom";

const SuccessPage = () => {
  const [searchParams, setSearchParams] = useState("");
  const [decryptLink, setDecryptLink] = useState("");

  useEffect(() => {
    const params = window.location.href.split("/");
    params.length > 5 && setSearchParams(params[5]);

    console.log(searchParams);
  }, []);

  const decryptLinkApps = () => {
    const url = searchParams ? searchParams : "";
  };

  return (
    <div className="bg-gradient2 h-screen flex justify-center items-center">
      <div className="flex flex-col items-start justify-start w-full mt-4r mb-4r sm:scale-75 scale-100">
        <div className="gap-10 md:gap-5 grid grid-cols-1 justify-center md:px-5 px-[100px] min-h-[auto] w-full">
          <div className="flex flex-col gap-4 items-start justify-start w-auto sm:w-full">
            <div className="flex justify-center items-center w-full">
              <div className="bg-slate-50 flex flex-col gap-8 items-center justify-center p-8 md:px-5 rounded-[16px] md:w-[560px]">
                <Img
                  src="https://staging.digipac.id/public/images/ic_chart_tq.jpg"
                  className="h-[100px] w-[100px]"
                  alt="volume"
                />
                <div className="flex flex-col gap-4 items-center justify-start w-full">
                  <Text
                    className="font-semibold text-left w-auto text-lg"
                    as="h4"
                    variant="h4"
                  >
                    Yesss! Pembelian Sukses
                  </Text>
                  <Text
                    className="font-medium max-w-[496px] md:max-w-full text-blue_gray_400 text-center tracking-[0.14px] text-blue_gray-400"
                    variant="body1"
                  >
                    Klik tombol di bawah untuk nikmati aplikasimu
                  </Text>
                </div>
                <Link
                  to="/home"
                  className="bg-gradient1 cursor-pointer font-poppins font-semibold leading-[normal] min-w-[106px] py-1.5 rounded-[16px] text-center text-sm text-white w-80"
                >
                  Ambil Aplikasi
                </Link>

                {/* <Button
                  className="bg-gradient1 cursor-pointer font-poppins font-semibold leading-[normal] min-w-[106px] py-1.5 rounded-[16px] text-center text-sm text-white w-80"
                >
                  Ambil Aplikasi
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SuccessPage.defaultProps = {};

export default SuccessPage;
