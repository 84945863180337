// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { decodeBase64, encodeBase64 } from "./utils";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("auth");
    if (storedUser) {
      try {
        const decodedUser = JSON.parse(decodeBase64(storedUser)); // Decode the user data from Base64
        setUser(decodedUser);
      } catch (error) {
        console.error("Error decoding user data", error);
      }
    }
  }, []);

  const login = (userData) => {
    const encodedData = encodeBase64(JSON.stringify(userData)); // Encode the data to Base64 before storing
    localStorage.setItem("auth", encodedData);
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem("auth");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
