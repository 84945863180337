import React from 'react';

const generateRandomData = () => {
  const data = [];
  for (let i = 0; i < 5; i++) {  // 5 rows of data
    const row = Array.from({ length: 31 }, () => Math.floor(Math.random() * 10000));
    data.push(row);
  }
  
  // Add a row containing sums for each subject
  const sums = data.reduce((acc, curr) => {
    curr.forEach((val, idx) => {
      acc[idx] = (acc[idx] || 0) + val;
    });
    return acc;
  }, []);
  
  data.push(sums);

  // Add a row containing sums for each date
  const dateSums = data[0].map((_, idx) => data.reduce((acc, curr) => acc + curr[idx], 0));
  data.push(dateSums);
  
  return data;
};

const Table = () => {
  const dates = Array.from({ length: 31 }, (_, i) => i + 1);
  const data = generateRandomData();

  const rows = [
    "001288923"
  ];

  const subjects = [
    "Total Per Jam",
    "Balap 1",
    "Balap 2",
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-md">
        <thead className="bg-gray-200 rounded-lg">
          <tr>
            <th className="text-blue_gray_400 first:rounded-l-xl last:rounded-r-xl px-4 py-2 bg-gray_100_01 text-left">Keyword</th>
            <th className="text-blue_gray_400 first:rounded-l-xl last:rounded-r-xl px-4 py-2 bg-gray_100_01 text-right">Total</th>
            {dates.map(date => (
              <th key={date} className="text-blue_gray_400 first:rounded-l-xl last:rounded-r-xl px-4 py-2 bg-gray_100_01 text-right">{date}</th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200">
          {subjects.map((subject, subjectIdx) => (
            <React.Fragment key={subject}>
              <tr className={subject === "Total Per Jam" ? "bg-blue-100" : "bg-white"}>
                <th className="px-4 pt-4 whitespace-nowrap text-left font-normal">
                  {subject}
                </th>
                <td className="px-4 py-2 whitespace-nowrap text-left">{data[subjects.length + subjectIdx].reduce((acc, curr) => acc + curr, 0)}</td>
                {data[subjectIdx].map((value, dateIdx) => (
                  <td key={dateIdx} className="px-4 py-2 whitespace-nowrap text-left">{value}</td>
                ))}
              </tr>
              {rows.slice(1).map((row, rowIdx) => (
                <tr key={rowIdx} className={subject === "Total Per Jam" ? "bg-blue-100" : "bg-white"}>
                  <td></td>
                  <td className="px-4 py-2 whitespace-nowrap text-left">{data[subjectIdx + 1].reduce((acc, curr) => acc + curr, 0)}</td>
                  {data[subjectIdx + 1].map((value, dateIdx) => (
                    <td key={dateIdx} className="px-4 py-2 whitespace-nowrap text-left">{value}</td>
                  ))}
                </tr>
              ))}
              {/* Add a separator row */}
              {subjectIdx < subjects.length - 1 && (
                <tr key={`separator-${subjectIdx}`} className="border-b border-gray-300">
                  <td colSpan="32" className="px-4 py-1"></td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
