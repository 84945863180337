import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import axios from "axios"
import {baseUrl} from '../../../app/constants'


export const getPic = createAsyncThunk("getPic/getPic", async({uid})=>{
      /// ******** API Get Company ********
    const response = await axios.post(`${baseUrl}get_pic`, {
        uid: uid,
    })
    return response.data.response;
})

const getPicEntity = createEntityAdapter({
    selectId: (getPic) => getPic.id
})

const getPicSlice = createSlice({
    name: "getPic",
    initialState: 
    getPicEntity.getInitialState(),
    extraReducers:{
        [getPic.pending]: (state) => {
            console.log('pending')
        },
        [getPic.fulfilled]: (state, action) => {
            getPicEntity.setAll(state, action.payload);
        },
        [getPic.rejected]: (state) => {
            console.log('rejected')
        },
    }
})

export const getPicSelector = getPicEntity.getSelectors(state => state.getPic)
export default getPicSlice.reducer;