import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getBuyMethod = createAsyncThunk(
  "getBuyMethod/getBuyMethod",
  async () => {
    /// ******** API Buy Method ********
    const response = await axios.get(`${baseUrl}get_price_method`);
    return response.data.response;
  }
);

const getBuyMethodEntity = createEntityAdapter({
  selectId: (getBuyMethod) => getBuyMethod.id,
});

const getBuyMethodSlice = createSlice({
  name: "getBuyMethod",
  initialState: getBuyMethodEntity.getInitialState(),
  extraReducers: {
    [getBuyMethod.pending]: (state) => {
      // console.log('pending')
    },
    [getBuyMethod.fulfilled]: (state, action) => {
      getBuyMethodEntity.setAll(state, action.payload);
    },
    [getBuyMethod.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getBuyMethodSelector = getBuyMethodEntity.getSelectors(
  (state) => state.getBuyMethod
);
export default getBuyMethodSlice.reducer;
