import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const urlProduct = createAsyncThunk(
  "urlProduct/urlProduct",
  async ({ appid }) => {
    /// ******** API Get Product ********
    const response = await axios.post(`${baseUrl}lp_request_link`, {
      app_id: appid,
    });
    return response.data.response;
  }
);

const urlProductEntity = createEntityAdapter({
  selectId: (urlProduct) => urlProduct.id,
});

const urlProductSlice = createSlice({
  name: "urlProduct",
  initialState: urlProductEntity.getInitialState(),
  extraReducers: {
    [urlProduct.pending]: (state) => {
      console.log("pending");
    },
    [urlProduct.fulfilled]: (state, action) => {
      urlProductEntity.setAll(state, action.payload);
    },
    [urlProduct.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const urlProductSelector = urlProductEntity.getSelectors(
  (state) => state.urlProduct
);
export default urlProductSlice.reducer;
