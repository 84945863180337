import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const uploadImages = createAsyncThunk(
  "uploadImages/uploadImages",
  async ({ formData }) => {
    const response = await axios({
      method: "post",
      url: `${baseUrl}create_data_service`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    // console.log(response.data.response);
    return response.data.response;
  }
);

const uploadImagesInEntity = createEntityAdapter({
  selectId: (uploadImages) => uploadImages.id,
  companyId: (uploadImageProduct) => uploadImageProduct.company_id,
  name: (uploadImages) => uploadImages.name,
});

const uploadImagesSlice = createSlice({
  name: "uploadImages",
  initialState: uploadImagesInEntity.getInitialState(),
  extraReducers: {
    [uploadImages.pending]: (state) => {
      console.log("pending");
    },
    [uploadImages.fulfilled]: (state, action) => {
      uploadImagesInEntity.setAll(state, action.payload);
    },
    [uploadImages.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const uploadImagesSelector = uploadImagesInEntity.getSelectors(
  (state) => state.uploadImages
);
export default uploadImagesSlice.reducer;
