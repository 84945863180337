import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getTypeProduct = createAsyncThunk(
  "getTypeProduct/getTypeProduct",
  async () => {
    /// ******** API Type Product ********
    const response = await axios.get(`${baseUrl}get_service_category`);
    return response.data.response;
  }
);

const getTypeProductEntity = createEntityAdapter({
  selectId: (getTypeProduct) => getTypeProduct.id,
});

const getTypeProductSlice = createSlice({
  name: "getTypeProduct",
  initialState: getTypeProductEntity.getInitialState(),
  extraReducers: {
    [getTypeProduct.pending]: (state) => {
      // console.log('pending')
    },
    [getTypeProduct.fulfilled]: (state, action) => {
      getTypeProductEntity.setAll(state, action.payload);
    },
    [getTypeProduct.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getTypeProductSelector = getTypeProductEntity.getSelectors(
  (state) => state.getTypeProduct
);
export default getTypeProductSlice.reducer;
