import React from "react";
import PropTypes from "prop-types";

const shapes = {
  RoundedBorder4: "rounded",
  RoundedBorder8: "rounded-lg",
  CustomBorderLR10:
    "rounded-bl-none rounded-br-[10px] rounded-tl-none rounded-tr-[10px]",
  CustomBorderTL10:
    "rounded-bl-[10px] rounded-br-none rounded-tl-[10px] rounded-tr-none",
  icbCircleBorder24: "rounded-[24px]",
  icbRoundedBorder10: "rounded-[10px]",
  icbRoundedBorder6: "rounded-md",
  icbCircleBorder30: "rounded-[30px]",
};
const variants = {
  OutlineGray400_1: "outline outline-[1px] outline-gray_400 text-cyan_500",
  FillBlue900: "bg-blue_900 text-white_A700",
  OutlineGray400:
    "border-gray_400 border-r border-solid border-y text-indigo_900",
  OutlineBlue900:
    "bg-white_A700 border border-blue_900 border-solid text-blue_900",
  OutlineGray100: "outline outline-[1px] outline-gray_100 text-gray_800",
  OutlineGray100_2: "outline outline-[1px] outline-gray_100 text-gray_400",
  OutlineGray100_1: "outline outline-gray_100 text-gray_400",
  OutlineGray200:
    "bg-gray_800 outline outline-[1px] outline-gray_200 text-white_A700",
  FillRed500: "bg-red_500 text-white_A700",
  icbFillBluegray300: "bg-blue_gray_300",
  icbOutlineGray100: "outline outline-[2px] outline-gray_100",
  icbFillBlue900: "bg-blue_900",
  icbFillIndigo200: "bg-indigo_200",
  NoBorder: "border-none",
};
const sizes = {
  sm: "p-1",
  md: "p-3.5",
  smIcn: "p-1.5",
  mdIcn: "p-2.5",
  lgIcn: "p-[15px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf([
    "RoundedBorder4",
    "RoundedBorder8",
    "CustomBorderLR10",
    "CustomBorderTL10",
    "icbCircleBorder24",
    "icbRoundedBorder10",
    "icbRoundedBorder6",
    "icbCircleBorder30",
  ]),
  variant: PropTypes.oneOf([
    "OutlineGray400_1",
    "FillBlue900",
    "OutlineGray400",
    "OutlineBlue900",
    "OutlineGray100",
    "OutlineGray100_1",
    "OutlineGray200",
    "FillRed500",
    "icbFillBluegray300",
    "icbOutlineGray100",
    "icbFillBlue900",
    "icbFillIndigo200",
    "NoBorder",
  ]),
  size: PropTypes.oneOf(["sm", "md", "smIcn", "mdIcn", "lgIcn"]),
};

Button.defaultProps = { className: "", shape: "", variant: "", size: "" };
export { Button };
