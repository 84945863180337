import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const updatePic = async ({ params }) => {
  /// ******** API Update PIC ********
  // console.log(params);
  const response = await axios.post(`${baseUrl}update_pic`, params);
  return response.data.response;
};

export const getCompanyPic = async ({ uid }) => {
  const response = await axios.post(`${baseUrl}get_pic`, {
    uid: uid,
  });
  return response.data.response;
};

export const companyDetail = async ({ uid }) => {
  const response = await axios.post(`${baseUrl}cooperation`, {
    uid: uid,
  });
  return response.data.response;
};
