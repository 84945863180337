import React, { useCallback, useState } from "react";
import { Text, Line } from "components";
import ReportActiveUsersComponents from "./Components/report_active_users_components";
import ReportRecapIncomeComponents from "./Components/report_recap_income_components";
import ReportTransactionComponents from "./Components/report_transaction_components";
import ReportDetailProductComponents from "./Components/report_detail_product_components";
import ReportRecapComponents from "./Components/report_recap_components";
import ReportMediaStatusComponents from "./Components/report_media_status_components";
import ReportDrComponents from "./Components/report_dr_components";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportTarifComponents from "./Components/report_tarif_components";

const ProductreporttotalactiveuserspagePage = () => {
  const [statusRoute, setStatusRoute] = useState("recap_income");
  const { laporanUserAktifColumns, laporanUserAktifData } = useSelector(
    (state) => state.tabelLaporan
  );

  const handleStatusRouteChange = useCallback((value) => {
    setStatusRoute(value);
  }, []);

  const navigationItems = [
    { label: "Rekap Pendapatan", value: "recap_income" },
    { label: "Rekap Pembelian", value: "transaction" },
    { label: "Rekap Produk", value: "detail" },
    { label: "Rekap Subjek", value: "rekap_laporan" },
    { label: "Rekap DR", value: "dr" },

    /// NEW REPORT TAB
    // { label: "Rekap Media Status", value: "media_status" },
    { label: "Rekap Tarif", value: "tarif" },
    { label: "Rekap Subscriber", value: "active_users" },
  ];

  const componentMap = {
    recap_income: ReportRecapIncomeComponents, /// REKAP PENDAPATAN
    transaction: ReportTransactionComponents, /// REKAP PEMBELIAN
    detail: ReportDetailProductComponents, /// REKAP PRODUK
    dr: ReportDrComponents, /// REKAP DR
    rekap_laporan: ReportRecapComponents, /// REKAP SUBJECT

    /// NEW REPORT TAB
    media_status: ReportMediaStatusComponents,
    active_users: ReportActiveUsersComponents,
    tarif: ReportTarifComponents,
  };

  const ComponentToRender =
    componentMap[statusRoute] || ReportRecapIncomeComponents;

  return (
    <>
      <ToastContainer stacked />
      <div className="flex flex-col font-poppins gap-6 items-start justify-start max-w-full mt-0.5 p-8 sm:px-5 w-full">
        <Text className="text-gray_800 text-left w-auto" as="h1" variant="h1">
          Laporan Produk
        </Text>
        <div className="flex flex-col items-start justify-start self-stretch w-auto md:w-full">
          <div className="bg-white_A700 border-b border-gray_200 border-solid flex flex-col gap-6 items-start justify-center max-w-full p-[26px] sm:px-5 rounded-bl-none rounded-br-none rounded-tl-[16px] rounded-tr-[16px] w-full">
            <div className="flex md:flex-col flex-row gap-6 items-start justify-start self-stretch w-auto md:w-full">
              {navigationItems.map((item) => (
                <NavigationItem
                  key={item.value}
                  label={item.label}
                  value={item.value}
                  statusRoute={statusRoute}
                  setStatusRoute={handleStatusRouteChange}
                />
              ))}
            </div>
          </div>

          {/* {statusRoute === "active_users" ? (
            <ReportActiveUsersComponents tableHeader={laporanUserAktifColumns} tableData={laporanUserAktifData} />
          ) : ( */}
          <ComponentToRender />
          {/* )} */}
        </div>
      </div>
    </>
  );
};

const NavigationItem = ({ label, value, statusRoute, setStatusRoute }) => (
  <div
    className="cursor-pointer flex flex-col gap-2.5 items-start justify-start self-stretch w-auto"
    onClick={() => setStatusRoute(value)}
  >
    {statusRoute === value ? (
      <Text
        className="font-semibold text-gray_800 text-left w-auto"
        as="h4"
        variant="h4"
      >
        {label}
      </Text>
    ) : (
      <Text
        className="font-medium text-gray_400 text-left w-auto"
        as="h4"
        variant="h4"
      >
        {label}
      </Text>
    )}

    {statusRoute === value ? (
      <Line className="bg-blue_gray_300 h-[3px] w-full" />
    ) : (
      <Line className="bg-white_A700 h-[3px] w-full" />
    )}
  </div>
);

export default ProductreporttotalactiveuserspagePage;
