import { Text, Input, Img, Button } from "components";
import React, { useState, useEffect } from "react";
import {
  updatePic,
  companyDetail,
  getCompanyPic,
} from "../Features/serviceAccount";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import MyaccountcontactdetaileditsuccesspageModal from "modals/Myaccountcontactdetaileditsuccesspage";
import { useSelector } from "react-redux";

export const ContactDetailEditComponents = (props) => {
  //     // ======= State Get PIC =======
  const [pic, setPic] = useState([]);
  const [isOpened, setIsOpened] = useState(false);
  const [showLoaderActive, setShowLoaderActive] = useState(false);
  const users = useSelector(userSignInSelector);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({});

  const handleChange = (event, field) => {
    // console.log(event, field);
    setInputs((values) => ({ ...values, [field]: event }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const saveUpdate = () => {
    const validationErrors = {};

    pic.forEach((dataPic) => {
      // Validasi Nama Depan
      const firstNameField = setName(dataPic.pic_category_name, "first_name");
      if (!inputs[firstNameField]) {
        validationErrors[
          firstNameField
        ] = `Nama Depan ${dataPic.pic_category_name} harus diisi.`;
      }

      if (!/^[a-zA-Z ]+$/.test(inputs[firstNameField])) {
        validationErrors[
          firstNameField
        ] = `Nama Depan ${dataPic.pic_category_name} harus berupa text.`;
      }

      // Validasi Nama Belakang
      const lastNameField = setName(dataPic.pic_category_name, "last_name");
      if (!inputs[lastNameField]) {
        validationErrors[
          lastNameField
        ] = `Nama Belakang ${dataPic.pic_category_name} harus diisi.`;
      }

      if (!/^[a-zA-Z ]+$/.test(inputs[lastNameField])) {
        validationErrors[
          lastNameField
        ] = `Nama Belakang ${dataPic.pic_category_name} harus berupa text.`;
      }

      // Validasi Email
      const emailField = setName(dataPic.pic_category_name, "email");
      if (!inputs[emailField]) {
        validationErrors[
          emailField
        ] = `Email ${dataPic.pic_category_name} harus diisi.`;
      }

      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(inputs[emailField])) {
        // console.log("tidak valid!");
        validationErrors[
          emailField
        ] = `Email ${dataPic.pic_category_name} tidak valid!`;
      }

      // Validasi Nomor Telepon/Handphone
      const phoneField = setName(
        dataPic.pic_category_name,
        "phone_number"
      ).toString();
      if (!inputs[phoneField]) {
        validationErrors[
          phoneField
        ] = `Nomor Telepon/Handphone ${dataPic.pic_category_name} harus diisi.`;
      }
    });

    if (Object.keys(validationErrors).length === 0) {
      setShowLoaderActive(true);
      // console.log(inputs);
      updatePic({ params: inputs })
        .then(() => {
          setShowLoaderActive(false);
          setIsOpened(true);
        })
        .catch((obj) => {
          setShowLoaderActive(false);
          alert(obj.message);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  // Get PIC
  useEffect(() => {
    if (users) {
      let uuid = users.id;
      getCompanyPic({ uid: uuid })
        .then((obj) => {
          const pics = obj;
          setPic(pics);
          setPicList(pics);
          getCompanyDetail(uuid);
          // console.log(pics);
        })
        .catch((obj) => {
          // alert(obj.message);
          // console.log(obj.message);
        });
    }
  }, []);

  const getCompanyDetail = (uid) => {
    companyDetail({ uid: uid })
      .then((obj) => {
        setInputs((values) => ({ ...values, company_id: obj.company_id }));
      })
      .catch((obj) => {
        alert(obj.message);
      });
  };

  const setCategory = (obj) => {
    switch (obj) {
      case "Direktur":
        return {
          name: "d_pic_category_id",
          value: 1,
        };

      case "Bisnis":
        return {
          name: "b_pic_category_id",
          value: 2,
        };

      case "IT":
        return {
          name: "i_pic_category_id",
          value: 3,
        };

      case "Finance":
        return {
          name: "f_pic_category_id",
          value: 4,
        };

      default:
        break;
    }
  };

  const setName = (obj, field) => {
    switch (obj) {
      case "Direktur":
        return "d_" + field;

      case "Bisnis":
        return "b_" + field;

      case "IT":
        return "i_" + field;

      case "Finance":
        return "f_" + field;

      default:
        break;
    }
  };

  const setValue = (obj, field) => {
    switch (obj) {
      case "Direktur":
        return inputs["d_" + field] || "";

      case "Bisnis":
        return inputs["b_" + field] || "";

      case "IT":
        return inputs["i_" + field] || "";

      case "Finance":
        return inputs["f_" + field] || "";

      default:
        break;
    }
  };

  const setPicList = (picList) => {
    picList.forEach((element) => {
      let fName = setName(element.pic_category_name, "first_name");
      let lName = setName(element.pic_category_name, "last_name");
      let mail = setName(element.pic_category_name, "email");
      let phone = setName(element.pic_category_name, "phone_number");
      let category = setCategory(element.pic_category_name);

      setInputs((values) => ({ ...values, [fName]: element.first_name }));
      setInputs((values) => ({ ...values, [lName]: element.last_name }));
      setInputs((values) => ({ ...values, [mail]: element.email }));
      setInputs((values) => ({
        ...values,
        [phone]: element.phone_number,
      }));
      setInputs((values) => ({ ...values, [category.name]: category.value }));
    });
  };

  // ================================
  return (
    <>
      <div className="flex flex-col gap-6 items-start justify-start w-full">
        {/* Detail PIC */}
        {pic.map((dataPic) => (
          <>
            <div className="flex flex-row md:gap-10 items-center justify-between w-full">
              <Text
                className="flex-1 font-semibold text-gray_800 text-left w-auto"
                as="h4"
                variant="h4"
              >
                PIC {dataPic.pic_category_name}
              </Text>
            </div>
            <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nama Depan
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className={`leading-[normal] not-italic p-0 placeholder:text-gray_400 text-left text-sm tracking-[0.14px] w-full ${
                    (dataPic.pic_category_name,
                    "first_name"
                      ? "font-semibold text-black"
                      : "font-normal text-gray_400")
                  }`}
                  name={setName(dataPic.pic_category_name, "first_name")}
                  // placeholder={dataPic?.first_name ?? ''}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_iconparkoutlineeditname.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="icon-park-outline:edit-name"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                  value={setValue(dataPic.pic_category_name, "first_name")}
                  onChange={(e) =>
                    handleChange(
                      e,
                      setName(dataPic.pic_category_name, "first_name")
                    )
                  }
                />
                {errors[setName(dataPic.pic_category_name, "first_name")] && (
                  <div className="text-red-500 text-sm">
                    {errors[setName(dataPic.pic_category_name, "first_name")]}
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nama Belakang
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className={`leading-[normal] not-italic p-0 placeholder:text-gray_400 text-left text-sm tracking-[0.14px] w-full ${
                    (dataPic.pic_category_name,
                    "last_name"
                      ? "font-semibold text-black"
                      : "font-normal text-gray_400")
                  }`}
                  name={setName(dataPic.pic_category_name, "last_name")}
                  // placeholder={dataPic?.last_name ?? ''}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_iconparkoutlineeditname.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="icon-park-outline:edit-name"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                  value={setValue(dataPic.pic_category_name, "last_name")}
                  onChange={(e) =>
                    handleChange(
                      e,
                      setName(dataPic.pic_category_name, "last_name")
                    )
                  }
                />
                {errors[setName(dataPic.pic_category_name, "last_name")] && (
                  <div className="text-red-500 text-sm">
                    {errors[setName(dataPic.pic_category_name, "last_name")]}
                  </div>
                )}
              </div>
            </div>
            <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Email
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className={`leading-[normal] not-italic p-0 placeholder:text-gray_400 text-left text-sm tracking-[0.14px] w-full ${
                    (dataPic.pic_category_name,
                    "email"
                      ? "font-semibold text-black"
                      : "font-normal text-gray_400")
                  }`}
                  type="email"
                  name={setName(dataPic.pic_category_name, "email")}
                  // placeholder={dataPic?.email ?? ''}
                  prefix={
                    <Img
                      src={process.env.PUBLIC_URL + "/images/img_mail.svg"}
                      className="mt-auto mb-px mr-2.5"
                      alt="mail"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                  value={setValue(dataPic.pic_category_name, "email")}
                  onChange={(e) =>
                    handleChange(e, setName(dataPic.pic_category_name, "email"))
                  }
                />
                {errors[setName(dataPic.pic_category_name, "email")] && (
                  <div className="text-red-500 text-sm">
                    {errors[setName(dataPic.pic_category_name, "email")]}
                  </div>
                )}
              </div>
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nomor Telepon/Handphone
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className={`leading-[normal] not-italic p-0 placeholder:text-gray_400 text-left text-sm tracking-[0.14px] w-full ${
                    (dataPic.pic_category_name,
                    "phone_number"
                      ? "font-semibold text-black"
                      : "font-normal text-gray_400")
                  }`}
                  type="text"
                  name={setName(dataPic.pic_category_name, "phone_number")}
                  // placeholder={dataPic?.phone_number ?? ''}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_solarphonebold.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="solar:phone-bold"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                  value={setValue(dataPic.pic_category_name, "phone_number")}
                  onChange={(e) =>
                    handleChange(
                      e,
                      setName(dataPic.pic_category_name, "phone_number")
                    )
                  }
                />
                {errors[setName(dataPic.pic_category_name, "phone_number")] && (
                  <div className="text-red-500 text-sm">
                    {errors[setName(dataPic.pic_category_name, "phone_number")]}
                  </div>
                )}
              </div>
            </div>
          </>
        ))}

        {console.log(pic)}

        {!pic && <p>data tidak ditemukan</p>}

        {/* ======================= */}

        <div className="flex sm:flex-col flex-row gap-5 items-start justify-end w-full">
          <Button
            className="cursor-pointer font-semibold leading-[normal] min-w-[243px] text-base text-center text-white_A700 w-auto"
            shape="RoundedBorder8"
            size="md"
            variant="FillRed500"
            onClick={(e) => {
              e.preventDefault();
              props?.onClickBack();
            }}
          >
            Batal
          </Button>
          <Button
            className="cursor-pointer font-semibold leading-[normal] min-w-[264px] text-base text-center text-white_A700 w-auto"
            shape="RoundedBorder8"
            size="md"
            variant="FillBlue900"
            onClick={(e) => {
              e.preventDefault();
              //   props?.onClickBack()
              saveUpdate();
            }}
          >
            {showLoaderActive ? (
              <div className="App">
                <div className="spinner-container">
                  <div className="loading-spinner" />
                </div>
              </div>
            ) : (
              <div>Simpan</div>
            )}
          </Button>
        </div>

        {/* ======================= */}
        <MyaccountcontactdetaileditsuccesspageModal
          isOpen={isOpened}
          setIsOpenParse={setIsOpened}
          closePopup={() => props?.onClickBack()}
        />
      </div>
    </>
  );
};
export default ContactDetailEditComponents;
