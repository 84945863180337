import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { getInboxes } from "pages/Inboxpage/Features/inboxSlice";
import { baseUrl, baseTsel, BASE_PATH_IMAGE } from "../../../app/constants";

import SideNav from "./SideNav";
import SideNavMobile from "./SideNavMobile";
import DashboardPageHeader from "./PageHeader";
import { useAuth } from "app/AuthContext";

const PageLayout = () => {
  // guard
  const { user } = useAuth();
  const users = user;

  const [isOpen, setIsOpen] = useState(false);
  const [menus, setMenus] = useState([]);

  const firstName = users.company_name || "";
  const lastName = "" || "";
  const idLevel = users.type || "";
  const id = users.id;

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // const dummyNavbar = [
  //   {
  //     feature_name: "Dashboard",
  //     icon_active: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_dashboard_open.svg",
  //     icon_incative: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_dashboard.svg",
  //   },
  //   {
  //     feature_name: "Content Management",
  //     icon_active: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_video_open.svg",
  //     icon_incative: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_video.svg",
  //     submenu: [
  //       {
  //         sub: "Content Management",
  //         feature_name: "Content List",
  //       },
  //       {
  //         sub: "Content Management",
  //         feature_name: "Artist/Talent",
  //       },
  //       {
  //         sub: "Content Management",
  //         feature_name: "Content Class",
  //       },
  //     ],
  //   },
  //   {
  //     feature_name: "Partner Management",
  //     icon_active:
  //       BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_partner_management_open.svg",
  //     icon_incative:
  //       BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_partner_management.svg",
  //   },
  //   {
  //     feature_name: "Report",
  //     icon_active: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_report_open.svg",
  //     icon_incative: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_report.svg",
  //   },
  //   {
  //     feature_name: "Inbox",
  //     icon_active: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_inbox_open.svg",
  //     icon_incative: BASE_PATH_IMAGE + "vrbt/icon_sidebar/ic_inbox.svg",
  //   },
  // ];

  const dataSideBar = user.menus;

  return (
    <>
      <div className="relative flex min-h-screen">
        <SideNav menus={dataSideBar} />
        <SideNavMobile
          menus={dataSideBar}
          open={isOpen}
          close={() => setIsOpen(false)}
        />
        <div className="grow w-[calc(100%-280px)]">
          <div className="flex flex-col min-h-full">
            <DashboardPageHeader
              className="
              bg-white_A700 
              border-b 
              border-gray_200 
              border-solid 
              flex flex-wrap 
              justify-between 
              items-center 
              mx-auto 
              max-w-screen-xl
              w-full
              "
              groupone={firstName.charAt(0) + lastName.charAt(0)}
              theoedwards={firstName + " " + lastName}
              administrator={
                idLevel === 1 || idLevel === "1"
                  ? "Super Admin"
                  : idLevel === 2 || idLevel === "2"
                  ? "Partner"
                  : idLevel === 7
                  ? "Business"
                  : idLevel === 8
                  ? "Partner Telco"
                  : idLevel === 9
                  ? "Sahabat Dahsyat"
                  : idLevel === 10
                  ? "Manager Keuangan"
                  : idLevel === 11
                  ? "Customer Service"
                  : idLevel === 12
                  ? "Admin Partner"
                  : idLevel === 13
                  ? "Admin FB"
                  : idLevel === 14
                  ? "Admin Telco L2"
                  : idLevel === 15
                  ? "Adnet"
                  : "Other"
              }
              someProps="foo"
              onClickAccount={null}
              onClickStatus={null}
              onClickMessage={null}
              toggleSideBar={handleOpen}
            />
            <div className="grow bg-[#F7FAFC] p-6">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
