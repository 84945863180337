import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const topPartnerProduct = createAsyncThunk(
  "topPartnerProduct/topPartnerProduct",
  async ({ uid }) => {
    /// ******** API oncetime_revenue_per_day ********
    const response = await axios.post(`${baseUrl}top_partner_product`, {
      uid: uid,
    });
    return response.data.response;
  }
);

const changePasswordEntity = createEntityAdapter({
  selectId: (topPartnerProduct) => topPartnerProduct.id,
});

const changePasswordSlice = createSlice({
  name: "topPartnerProduct",
  initialState: changePasswordEntity.getInitialState(),
  extraReducers: {
    [topPartnerProduct.pending]: (state) => {
      // console.log('pending')
    },
    [topPartnerProduct.fulfilled]: (state, action) => {
      changePasswordEntity.setAll(state, action.payload);
    },
    [topPartnerProduct.rejected]: (state) => {
      // console.log("rejected");
    },
  },
});

export const changePasswordSelector = changePasswordEntity.getSelectors(
  (state) => state.topPartnerProduct
);
export default changePasswordSlice.reducer;
