import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../app/constants";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const fetchRekapLaporanColumns = createAsyncThunk(
  "tabelLaporan/fetchRekapLaporanColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(
        `${baseUrl}report_income_recap_colums`,
        {
          uid: uid,
        }
      );
      // console.log(response.data);
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchRekapLaporanData = createAsyncThunk(
  "tabelLaporan/fetchRekapLaporanData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}report_income_recap_data`, {
        uid: uid,
      });
      // console.log(response.data);
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchRekapPendapatan = createAsyncThunk(
  "report/fetchRekapPendapatan",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}v2/report_data_recap`, {});
      // console.log("Rekap Pendapatan Resutl: " + response.data);
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchRekapTransaksiColumns = createAsyncThunk(
  "tabelLaporan/fetchRekapTransaksiColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(
        `${baseUrl}report_request_transaction_colums`,
        {
          uid: uid,
        }
      );
      // console.log(response.data);
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchRekapTransaksiData = createAsyncThunk(
  "tabelLaporan/fetchRekapTransaksinData",
  async ({ uid }) => {
    try {
      const response = await axios.post(
        `${baseUrl}report_request_transaction_data`,
        {
          uid: uid,
        }
      );
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDetailProdukColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanDetailProdukColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}product_detail_colums`, {
        uid: uid,
      });
      // console.log("Detail Yeuh:", response.data);
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDetailProdukData = createAsyncThunk(
  "tabelLaporan/fetchLaporanDetailProdukData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}product_detail_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDetailHargaColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanDetailHargaColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}price_detail_colums`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDetailHargaData = createAsyncThunk(
  "tabelLaporan/fetchLaporanDetailHargaData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}price_detail_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanUserAktifColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanUserAktifColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(
        `${baseUrl}report_active_users_colums`,
        {
          uid: uid,
        }
      );
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanUserAktifData = createAsyncThunk(
  "tabelLaporan/fetchLaporanUserAktifData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}report_active_users_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanRekapColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanRekapColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}report_recap_colums`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanRekapData = createAsyncThunk(
  "tabelLaporan/fetchLaporanRekapData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}report_recap_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanMediaStatusColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanMediaStatusColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}media_report_colums`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanMediaStatusData = createAsyncThunk(
  "tabelLaporan/fetchLaporanMediaStatusData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}media_report_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDrStatusColumns = createAsyncThunk(
  "tabelLaporan/fetchLaporanDrStatusColumns",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}dr_report_colums`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

export const fetchLaporanDrStatusData = createAsyncThunk(
  "tabelLaporan/fetchLaporanDrStatusData",
  async ({ uid }) => {
    try {
      const response = await axios.post(`${baseUrl}dr_report_data`, {
        uid: uid,
      });
      return response.data.response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }
);

const initialState = {
  rekapLaporanColumns: [],
  rekapLaporanData: [],
  rekapTransaksiColumns: [],
  rekapTransaksiData: [],
  laporanDetailProdukColumns: [],
  laporanDetailProdukData: [],
  laporanDetailHargaColumns: [],
  laporanDetailHargaData: [],
  laporanUserAktifColumns: [],
  laporanUserAktifData: [],
  laporanRekapColumns: [],
  laporanRekapData: [],
  laporanMediaStatusColumns: [],
  laporanMediaStatusData: [],
  laporanDrStatusColumns: [],
  laporanDrStatusData: [],
  status: "idle",
  error: null,
};

const tabelLaporanSlice = createSlice({
  name: "tabelLaporan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRekapLaporanColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRekapLaporanColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rekapLaporanColumns = action.payload;
      })
      .addCase(fetchRekapLaporanColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRekapLaporanData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRekapLaporanData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rekapLaporanData = action.payload;
      })
      .addCase(fetchRekapLaporanData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRekapTransaksiColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRekapTransaksiColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rekapTransaksiColumns = action.payload;
      })
      .addCase(fetchRekapTransaksiColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRekapTransaksiData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRekapTransaksiData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rekapTransaksiData = action.payload;
      })
      .addCase(fetchRekapTransaksiData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDetailProdukColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDetailProdukColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDetailProdukColumns = action.payload;
      })
      .addCase(fetchLaporanDetailProdukColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDetailProdukData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDetailProdukData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDetailProdukData = action.payload;
      })
      .addCase(fetchLaporanDetailProdukData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDetailHargaColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDetailHargaColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDetailHargaColumns = action.payload;
      })
      .addCase(fetchLaporanDetailHargaColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDetailHargaData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDetailHargaData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDetailHargaData = action.payload;
      })
      .addCase(fetchLaporanDetailHargaData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanUserAktifColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanUserAktifColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanUserAktifColumns = action.payload;
      })
      .addCase(fetchLaporanUserAktifColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanUserAktifData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanUserAktifData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanUserAktifData = action.payload;
      })
      .addCase(fetchLaporanUserAktifData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanRekapColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanRekapColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanRekapColumns = action.payload;
      })
      .addCase(fetchLaporanRekapColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanRekapData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanRekapData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanRekapData = action.payload;
      })
      .addCase(fetchLaporanRekapData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanMediaStatusColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanMediaStatusColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanMediaStatusColumns = action.payload;
      })
      .addCase(fetchLaporanMediaStatusColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanMediaStatusData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanMediaStatusData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanMediaStatusData = action.payload;
      })
      .addCase(fetchLaporanMediaStatusData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDrStatusColumns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDrStatusColumns.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDrStatusColumns = action.payload;
      })
      .addCase(fetchLaporanDrStatusColumns.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLaporanDrStatusData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLaporanDrStatusData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.laporanDrStatusData = action.payload;
      })
      .addCase(fetchLaporanDrStatusData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tabelLaporanSlice.reducer;
