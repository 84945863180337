import React from "react";
import Skeleton from "react-loading-skeleton";

const TableReportSubscriptions = ({ responseSubs, loading, day }) => {
  const renderSkeletonRows = () => (
    <tr>
      <td className="p-4 text-left text-sm font-medium text-gray-900 border">
        <Skeleton height={20} />
      </td>
      <td className="p-4 text-right text-sm font-medium text-gray-900 border">
        <Skeleton height={20} />
      </td>
      {day.map((_, dayIndex) => (
        <React.Fragment key={dayIndex}>
          <td className="w-1/12 p-2 text-right font-medium text-gray_800 text-xs sm:w-full border">
            <Skeleton height={20} />
          </td>
          <td className="w-1/12 p-2 text-right font-medium text-gray_800 text-xs sm:w-full border">
            <Skeleton height={20} />
          </td>
        </React.Fragment>
      ))}
    </tr>
  );

  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(number);
  };

  return (
    <div className="flex w-full overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="bg-gray_100_01 text-center rounded-xl">
          <tr>
            <th
              rowSpan="2"
              className="py-3 text-sm font-bold text-blue_gray_400"
            >
              Keyword
            </th>
            <th
              rowSpan="2"
              className="px-6 py-3 text-sm font-bold text-blue_gray_400"
            >
              Aktif Subscriber
            </th>
            {day.map((day, index) => (
              <>
                <th
                  key={index}
                  colSpan="3"
                  className="p-1 text-sm font-bold text-blue_gray_400"
                >
                  {day}
                </th>
              </>
            ))}
          </tr>

          <tr className="bg-gray_100_01">
            {day.map(() => (
              <>
                <th className="py-4 px-4 text-sm font-bold text-blue_gray_400 sm:w-full">
                  Subs
                </th>
                <th className="py-4 text-blue_gray_400 border-r-4"></th>
                <th className="py-4 px-4 text-sm text-blue_gray_400 sm:w-full">
                  Unsub
                </th>
              </>
            ))}
          </tr>
        </thead>

        <tbody className="bg-white">
          {loading
            ? Array(5)
                .fill()
                .map((_, index) => (
                  <React.Fragment key={index}>
                    {renderSkeletonRows()}
                  </React.Fragment>
                ))
            : responseSubs === null
            ? "no data"
            : responseSubs.map((data, index) => (
                <tr key={index} className="border-b hover:bg-gray-100">
                  <td className="pl-4 py-2 text-left text-sm font-medium text-blue_gray_400">
                    {data.service}
                  </td>

                  <td className="pr-4 py-2 text-right text-sm font-medium text-blue_gray_400">
                    {data.active}
                  </td>

                  {/* reg and unreg */}
                  {day.map((day, dayIndex) => (
                    <React.Fragment key={dayIndex}>
                      <td className="w-1/6 pr-1 text-right font-medium text-blue_gray_400 text-sm sm:w-full">
                        {rupiah(data.reg[parseInt(day)] || 0)}
                      </td>
                      <td className="w-1/6 pr-2 text-right font-medium text-blue_gray_400 text-sm sm:w-full"></td>
                      <td className="w-1/6 pr-2 text-right font-medium text-blue_gray_400 text-sm sm:w-full">
                        {rupiah(data.unreg[parseInt(day)] || 0)}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );

  //   return (
  //     <div className="flex w-full">
  //       <table className="text-center table-auto border-collapse rounded-sm">
  //         <thead className="bg-gray-100">
  //           <tr>
  //             <th
  //               rowSpan="2"
  //               className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //             >
  //               Keyword
  //             </th>
  //             <th
  //               rowSpan="2"
  //               className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //             >
  //               Aktif Subscriber
  //             </th>
  //             {lastDays.map((day, index) => (
  //               <th
  //                 key={index}
  //                 colSpan="2"
  //                 className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //               >
  //                 {day}
  //               </th>
  //             ))}
  //           </tr>

  //           <tr className="bg-gray-100">
  //             {lastDays.map(() => (
  //               <>
  //                 <th className="px-6 py-3 text-sm font-medium text-gray-700 border">
  //                   Subs
  //                 </th>
  //                 <th className="px-6 py-3 text-sm font-medium text-gray-700 border">
  //                   Unsub
  //                 </th>
  //               </>
  //             ))}
  //           </tr>
  //         </thead>

  //         <tbody>
  //           {data.map((row, index) => (
  //             <tr key={index} className="border-t">
  //               <td className="px-6 py-4 text-sm font-medium text-blue_gray_400">
  //                 {row.keyword}
  //               </td>
  //               <td className="px-6 py-4 text-sm font-medium text-blue_gray_400">
  //                 {row.activeSubscribers}
  //               </td>
  //               {row.stats.map((stat, idx) => (
  //                 <React.Fragment key={idx}>
  //                   <td className="px-6 py-4 text-sm text-blue_gray_400">
  //                     {stat.subs}
  //                   </td>
  //                   <td className="px-6 py-4 text-sm text-blue_gray_400">
  //                     {stat.unsub}
  //                   </td>
  //                 </React.Fragment>
  //               ))}
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   );

  //   return (
  //     <div className="w-full">
  //       <table className="text-center table-auto border-collapse rounded-sm">
  //         <thead className="bg-gray-100">
  //           <tr>
  //             <th
  //               rowspan="2"
  //               className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //             >
  //               Keyword
  //             </th>

  //             <th
  //               rowspan="2"
  //               className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //             >
  //               Aktif Subscriber
  //             </th>

  //             {lastDays.map((day, index) => (
  //               <th
  //                 key={index}
  //                 className="px-6 py-3 text-sm font-medium text-gray-700 border"
  //               >
  //                 {day}
  //               </th>
  //             ))}
  //           </tr>

  //           <tr className="bg-gray-100">
  //             {[0, 1, 2, 3].map((day) => (
  //               <>
  //                 <th className="px-6 py-3 text-sm font-medium text-gray-700 border">
  //                   Subs
  //                 </th>
  //                 <th className="px-6 py-3 text-sm font-medium text-gray-700 border">
  //                   Unsub
  //                 </th>
  //               </>
  //             ))}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {data.map((row, index) => (
  //             <tr key={index} className="border-t">
  //               <td className="px-6 py-4 text-sm font-medium text-gray-900 border">
  //                 {row.keyword}
  //               </td>
  //               <td className="px-6 py-4 text-sm font-medium text-gray-900 border">
  //                 {row.activeSubscribers}
  //               </td>
  //               {row.stats.map((stat, idx) => (
  //                 <React.Fragment key={idx}>
  //                   <td className="px-6 py-4 text-sm text-gray-900 border">
  //                     {stat.subs}
  //                   </td>
  //                   <td className="px-6 py-4 text-sm text-gray-900 border">
  //                     {stat.unsub}
  //                   </td>
  //                 </React.Fragment>
  //               ))}
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   );
};

export default TableReportSubscriptions;
