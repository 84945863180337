import React, { useState, useEffect, useRef } from "react";
import { SelectBox, Img, Button, Text } from "components";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile, CellObject } from "sheetjs-style";
import "react-toastify/dist/ReactToastify.css";
import { useGetPartnerQuery, useGetReportRecapIncomeMutation } from "app/api1";
import TableReportRecapIncome from "./TableReportRecapIncome";
import TableReportRecapIncomeTopPartner from "./TableReportIncomeTopPartner";

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2014; year <= currentYear; year++) {
    years.push({ label: year.toString(), value: year.toString() });
  }
  return years;
};

const generateMonths = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames.map((name, index) => ({
    label: name,
    value: (index + 1).toString(),
  }));
};

const months = generateMonths();
const years = generateYears();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const selectDownload = [
  // { value: "pdf", label: "PDF" },
  { value: "excel", label: "Excel" },
];

const ReportRecapIncomeComponents = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const idLevel = users.idLevel;
  const tableReportRef = useRef();

  const [filter, setFilter] = useState({
    year: currentYear.toString(),
    month: currentMonth.toString(),
    partner: "",
    uid: users,
  });

  const [selectedPartner, setSelectedPartner] = useState("");

  const [getReport, { data, isLoading, reset }] =
    useGetReportRecapIncomeMutation();
  const { data: partners } = useGetPartnerQuery();
  const filteredMonths =
    filter.year === currentYear.toString()
      ? months.filter((month) => parseInt(month.value, 10) <= currentMonth)
      : months;

  const selectedMonth = filteredMonths.find((m) => m.value === filter.month);
  const selectedYear = years.find((y) => y.value === filter.year);

  const handleSelectChange = (selectedOption) => {
    if (tableReportRef.current) {
      const type = selectedOption ? selectedOption : null;
      if (type) {
        tableReportRef.current.handleDownload(type);
      }
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();

    reset();
    setFilter((prevFilter) => ({
      ...prevFilter,
      partner: selectedPartner,
    }));
    getReport({
      month: filter.month,
      years: filter.year,
      partner: selectedPartner,
      uid: users?.id,
    });
  };

  useEffect(() => {
    getReport({
      month: filter.month,
      years: filter.year,
      partner: filter.partner,
      uid: users?.id,
    });
  }, []);

  return (
    <>
      <div className="bg-white_A700 flex flex-col gap-6 items-start justify-center max-w-full p-[26px] sm:px-5 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none w-full">
        <div
          className={
            idLevel === 1 || idLevel === 14
              ? "grid grid-cols-5 gap-3 w-full"
              : "grid grid-cols-6 gap-6 w-[100%]"
          }
        >
          {/* Months */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628297"
              placeholder={selectedMonth ? selectedMonth.label : ""}
              options={filteredMonths}
              getOptionLabel={(e) => (
                <div className="flex items-center">
                  <span>{e.label}</span>
                </div>
              )}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
              value={selectedMonth ? selectedMonth.value : ""}
              onChange={(value) => {
                setFilter({ ...filter, month: value });
              }}
            />
          </div>

          {/* Years */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="zipcode"
              placeholder={selectedYear.label}
              isSearchable={false}
              isMulti={false}
              options={years}
              getOptionLabel={(e) => (
                <div className="flex items-center">
                  <span>{e.value}</span>
                </div>
              )}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
              value={selectedYear ? selectedYear.value : ""}
              onChange={(value) => {
                setFilter({ ...filter, year: value });
              }}
            />
          </div>

          {/* Partner */}
          {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              <SelectBox
                className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full max-w-screen-mdreal"
                placeholderClassName="text-gray_800"
                name="frame628350"
                placeholder={"Semua Partner"}
                isSearchable={false}
                isMulti={false}
                options={[
                  { label: "Semua Partner", value: "" }, // Default option
                  { label: "Top 5 Partner", value: "Top 5 Partner" }, // New option
                  ...(partners?.response.map((partner) => ({
                    label: partner.partner_name,
                    value: partner.value,
                  })) || []), // Existing options
                ]}
                getOptionLabel={(option) => (
                  <div className="flex items-center">
                    <span>{option.label}</span>
                  </div>
                )}
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineGray200"
                onChange={(selectedOption) => {
                  setSelectedPartner(selectedOption);
                }}
              />
            </div>
          ) : (
            <></>
          )}

          {/* Button update admin */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <Button
              className="cursor-pointer flex items-center justify-center w-[125px] h-[47px]"
              leftIcon={
                <Img
                  src={
                    process.env.PUBLIC_URL + "/images/img_search_white_a700.svg"
                  }
                  className="mr-2.5"
                  alt="search"
                />
              }
              shape="RoundedBorder8"
              size="md"
              variant="OutlineGray200"
              onClick={handleButtonClick}
            >
              <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                Update
              </div>
            </Button>
          </div>

          {/* Unduh Laporan */}

          {idLevel === 1 || idLevel === 14
            ? filter.partner !== "Top 5 Partner" && (
                <div className="justify-end place-items-center">
                  <SelectBox
                    className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full w-full"
                    placeholderClassName="text-gray_800"
                    name="frame628350"
                    placeholder="Unduh Laporan"
                    isSearchable={false}
                    isMulti={false}
                    options={selectDownload}
                    onChange={handleSelectChange}
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineGray200"
                  />
                </div>
              )
            : filter.partner !== "Top 5 Partner" && (
                <div className="flex justify-end col-span-3 place-items-center">
                  <SelectBox
                    className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full w-[180px]"
                    placeholderClassName="text-gray_800"
                    name="frame628350"
                    placeholder="Unduh Laporan"
                    isSearchable={false}
                    isMulti={false}
                    options={selectDownload}
                    onChange={handleSelectChange}
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineGray200"
                  />
                </div>
              )}
        </div>
      </div>
      <div className="bg-white_A700 flex flex-col gap-[26px] items-start justify-start w-full overflow-x-auto pr-6">
        {filter.partner !== "Top 5 Partner" && (
          <TableReportRecapIncome
            tableData={data?.data || []}
            month={filter.month}
            year={filter.year}
            isLoading={isLoading}
            ref={tableReportRef}
          />
        )}
      </div>

      {(idLevel === 1 || idLevel === 14) &&
        filter.partner === "Top 5 Partner" && (
          <>
            <div className="py-4">
              <Text className="font-semibold leading-[normal] text-gray_800 text-left text-2xl">
                5 Pendapatan Partner Teratas
              </Text>
            </div>
            <div className="flex flex-col gap-[26px] items-start justify-start w-full mb-4 overflow-x-auto">
              <TableReportRecapIncomeTopPartner
                tableData={data?.data || []}
                month={filter.month}
                year={filter.year}
              />
            </div>
          </>
        )}
    </>
  );
};

export default ReportRecapIncomeComponents;
