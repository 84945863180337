import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";
import { Text } from "components";

const selectOptions = [
  { value: "option1", label: "Option1" },
  { value: "option2", label: "Option2" },
  { value: "option3", label: "Option3" },
];

const variants = {
  OutlineGray200: "bg-white_A700 outline outline-[1px] outline-gray_200",
  OutlineGray400: "border border-gray_400 border-solid",
};
const shapes = {
  RoundedBorder6: "rounded-md",
  RoundedBorder10: "rounded-[10px]",
};
const sizes = { sm: "p-3" };

const SelectBox = React.forwardRef(
  (
    {
      children,
      placeholder = "Select",
      className = "",
      options = selectOptions,
      isSearchable = false,
      placeholderClassName = "",
      isMulti = false,
      isProvince = false,
      isCity = false,
      isBank = false,
      isTypeProduct = false,
      isBuyMethod = false,
      isPeriode = false,
      isPrice = false,
      isPriceValue = false,
      onChange,
      value = "",
      errors = [],
      indicator,
      shape = "",
      variant = "",
      size = "",
      label,
      required,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const [selectedVal, setSelectedVal] = React.useState(value);

    const handleChange = (data) => {
      setSelectedVal(data);
      if (isMulti) {
        onChange?.(data?.map((d) => d.value) || []);
      } else if (isProvince) {
        onChange?.(data?.provinces_id);
      } else if (isCity) {
        onChange?.(data?.regencies_id);
      } else if (isBank) {
        onChange?.(data?.bank_id);
      } else if (isTypeProduct) {
        onChange?.(data?.category_id);
      } else if (isBuyMethod) {
        onChange?.(data?.price_method_id);
      } else if (isPeriode) {
        onChange?.(data?.price_rates_id);
      } else if (isPrice) {
        onChange?.(data?.price_id);
      } else if (isPriceValue) {
        onChange?.(data?.price, data?.price_id);
      } else {
        onChange?.(data?.value);
      }
    };

    return (
      <>
        {!!label && (
          <div className="flex justify-start gap-2">
            <Text
              className="font-medium text-gray_800 text-left mb-4"
              as="h5"
              variant="h5"
            >
              {!!label && label}
            </Text>
            {required && <span className="text-red-500">*</span>}
          </div>
        )}
        <Select
          ref={ref}
          options={options}
          className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""
            } ${(variant && variants[variant]) || ""} !text-slate-500`}
          placeholder={
            <div className={`placeholder:!text-slate-500`}>{placeholder}</div>
          }
          isSearchable={isSearchable}
          isMulti={isMulti}
          isProvince={isProvince}
          isCity={isCity}
          isBank={isBank}
          isTypeProduct={isTypeProduct}
          isBuyMethod={isBuyMethod}
          isPeriode={isPeriode}
          isPrice={isPrice}
          isPriceValue={isPriceValue}
          components={{
            IndicatorSeparator: () => null,
            ...(indicator && { DropdownIndicator: () => indicator }),
          }}
          value={selectedVal}
          onChange={handleChange}
          styles={{
            container: (provided) => ({
              ...provided,
              zIndex: 0,
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: "transparent",
              border: "0 !important",
              boxShadow: "0 !important",
              minHeight: "auto",
              "&:hover": {
                border: "0 !important",
              },
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected && "inherit",
              backgroundColor: state.isSelected && "#ffffff",
              "&:hover": { backgroundColor: "#0730b8", color: "#ffffff" },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "inherit",
            }),
            input: (provided) => ({
              ...provided,
              color: "inherit",
              margin: "0",
              padding: "0",
              // height: "0",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "0",
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingTop: "0px",
              paddingBottom: "0px",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              padding: "0",
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              padding: "0",
            }),
            menuPortal: (base) => ({ ...base, zIndex: 999999 }),
            placeholder: (base) => ({
              ...base,
              margin: 0,
            }),
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(event) => {
            return event.target.id === "scrollContainer";
          }}
          required
          isDisabled={disabled}
          {...restProps}
        />
        <ErrorMessage errors={errors} />
        {children}
      </>
    );
  }
);

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholderClassName: PropTypes.string,
  isMulti: PropTypes.bool,
  isProvince: PropTypes.bool,
  isCity: PropTypes.bool,
  isBank: PropTypes.bool,
  isTypeProduct: PropTypes.bool,
  isBuyMethod: PropTypes.bool,
  isPeriode: PropTypes.bool,
  isPrice: PropTypes.bool,
  isPriceValue: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder6", "RoundedBorder10"]),
  variant: PropTypes.oneOf(["OutlineGray200", "OutlineGray400"]),
  size: PropTypes.oneOf(["sm"]),
};

SelectBox.defaultProps = {
  placeholder: "Select",
  className: "",
  isSearchable: false,
  placeholderClassName: "",
  isMulti: false,
  isProvince: false,
  isCity: false,
  isBank: false,
  isTypeProduct: false,
  isBuyMethod: false,
  isPeriode: false,
  isPrice: false,
  isPriceValue: false,
  value: "",
  shape: "",
  variant: "",
  size: "",
  options: selectOptions,
  onChange: () => { },
};
export { SelectBox };
