import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getKeyword = createAsyncThunk(
  "getKeyword/getKeyword",
  async () => {
    /// ******** API Periode ********
    const response = await axios.get(`${baseUrl}get_service_keyword`);
    return response.data.response;
  }
);

const getKeywordEntity = createEntityAdapter({
  selectId: (getKeyword) => getKeyword.id,
});

const getKeywordSlice = createSlice({
  name: "getPeriode",
  initialState: getKeywordEntity.getInitialState(),
  extraReducers: {
    [getKeyword.pending]: (state) => {
      // console.log('pending')
    },
    [getKeyword.fulfilled]: (state, action) => {
      getKeywordEntity.setAll(state, action.payload);
    },
    [getKeyword.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getKeywordSelector = getKeywordEntity.getSelectors(
  (state) => state.getKeyword
);
export default getKeywordSlice.reducer;
