/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Img, Button, Text, ReactTable } from "components";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { urlProduct } from "../Features/urlProductSlice";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import CopyMultipleLink from "modals/CopyMultipleLink";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";

const ListProductTable = ({ datas = [], detailHandler, statusSelected }) => {
  const [header, setHeader] = useState([]);
  const [status, setStatus] = useState("");
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();
  const users = useSelector(userSignInSelector);
  // const users = useSelector((state) => state.userSignIn);
  const levelId = users.idLevel;
  const [isOpened, setIsOpened] = useState(false);
  const [dataService, setDataService] = useState({});

  const dataColumn = [
    { value: "date_time", label: "Tanggal" },
    { value: "app_id", label: "Product ID" },
    { value: "partner_name", label: "Nama Partner" },
    { value: "service_name", label: "Nama Produk" },
    { value: "service_type", label: "Tipe" },
    { value: "method", label: "Metode" },
    { value: "rate", label: "Periode" },
    { value: "status_name", label: "Status" },
  ];

  const dataColumnPartner = [
    { value: "date_time", label: "Tanggal" },
    { value: "app_id", label: "Product ID" },
    { value: "service_name", label: "Nama Produk" },
    { value: "service_type", label: "Tipe" },
    { value: "method", label: "Metode" },
    { value: "rate", label: "Periode" },
    { value: "status_name", label: "Status" },
  ];

  const dataStatus = [
    { id: "active", label: "Aktif", color: "text-green-500" },
    { id: "pending", label: "Dalam Proses DPA", color: "text-yellow-500" },
    {
      id: "pending_telco",
      label: "Dalam Proses Telkomsel",
      color: "text-yellow-500",
    },
    { id: "ditolak", label: "Ditolak", color: "text-red-500" },
    { id: "block", label: "Berhenti", color: "text-red-500" },
    { id: "nonactive", label: "Tidak Aktif", color: "text-red-500" },
  ];

  const dataStatusDPA = [
    { id: "active", label: "Aktif", color: "text-green-500" },
    { id: "pending", label: "Dalam Proses DPA", color: "text-yellow-500" },
    {
      id: "pending_telco",
      label: "Dalam Proses Telkomsel",
      color: "text-yellow-500",
    },
    { id: "ditolak", label: "Ditolak", color: "text-red-500" },
    { id: "block", label: "Berhenti", color: "text-red-500" },
    { id: "nonactive", label: "Tidak Aktif", color: "text-red-500" },
  ];

  const dataStatusTelco = [
    { id: "active", label: "Aktif", color: "text-green-500" },
    { id: "pending_telco", label: "Dalam Proses", color: "text-yellow-500" },
    { id: "ditolak", label: "Ditolak", color: "text-red-500" },
    { id: "block", label: "Berhenti", color: "text-red-500" },
    { id: "nonactive", label: "Tidak Aktif", color: "text-red-500" },
  ];

  const clearString = (str) => str.replace(/\s/g, "").toLowerCase();

  const StatusRow = (values) => {
    setStatus(values);
    const statusTemp = {
      id: null,
      label: null,
      color: null,
    };

    levelId === 1
      ? dataStatusDPA.forEach((el) => {
          if (clearString(el.id) === clearString(values)) {
            statusTemp.id = el.id;
            statusTemp.label = el.label;
            statusTemp.color = el.color;
          }
        })
      : levelId === 14
      ? dataStatusTelco.forEach((el) => {
          if (clearString(el.id) === clearString(values)) {
            statusTemp.id = el.id;
            statusTemp.label = el.label;
            statusTemp.color = el.color;
          }
        })
      : dataStatus.forEach((el) => {
          if (clearString(el.id) === clearString(values)) {
            statusTemp.id = el.id;
            statusTemp.label = el.label;
            statusTemp.color = el.color;
          }
        });
    return (
      <Text
        className={`font-semibold pb-[11px] sm:pl-5 pl-6 pt-[25px] ${statusTemp.color} text-left`}
        as="p"
        variant="body1"
      >
        {statusTemp.label}
      </Text>
    );
  };

  const setHeaderData = (state) => {
    const columns = [];
    levelId === 1 || levelId === 14
      ? dataColumn.forEach((el) => {
          const column = columnHelper.accessor(el.value, {
            header: () => (
              <Text
                className="font-medium min-w-[216px] sm:pl-5 pl-6 py-[11px] text-blue_gray_400 text-left"
                as="p"
                variant="h5"
              >
                {el.label}
              </Text>
            ),
            cell: (info) => {
              switch (el.value) {
                case "app_id":
                  return (
                    <>
                      <div className="w-[200px] overflow-hidden text-ellipsis">
                        <Text
                          className="font-semibold pb-[11px] sm:pl-5 pl-6 pt-[25px] text-gray_800 text-left"
                          as="p"
                          variant="body1"
                        >
                          {info?.getValue()}
                        </Text>
                      </div>
                    </>
                  );

                case "status_name":
                  return StatusRow(info?.getValue());

                default:
                  return (
                    <Text
                      className="font-semibold pb-[11px] sm:pl-5 pl-6 pt-[25px] text-blue_gray_400 text-left"
                      as="p"
                      variant="body1"
                    >
                      {info?.getValue()}
                    </Text>
                  );
              }
            },
          });
          columns.push(column);
        })
      : dataColumnPartner.forEach((el) => {
          const column = columnHelper.accessor(el.value, {
            header: () => (
              <Text
                className="font-medium min-w-[216px] sm:pl-5 pl-6 py-[11px] text-blue_gray_400 text-left"
                as="p"
                variant="h5"
              >
                {el.label}
              </Text>
            ),
            cell: (info) => {
              switch (el.value) {
                case "app_id":
                  return (
                    <>
                      <div className="w-[200px] overflow-hidden text-ellipsis">
                        <Text
                          className="font-semibold pb-[11px] sm:pl-5 pl-6 pt-[25px] text-gray_800 text-left"
                          as="p"
                          variant="body1"
                        >
                          {info?.getValue()}
                        </Text>
                      </div>
                    </>
                  );

                case "status_name":
                  return StatusRow(info?.getValue());

                default:
                  return (
                    <Text
                      className="font-semibold pb-[11px] sm:pl-5 pl-6 pt-[25px] text-blue_gray_400 text-left"
                      as="p"
                      variant="body1"
                    >
                      {info?.getValue()}
                    </Text>
                  );
              }
            },
          });
          columns.push(column);
        });

    const url = columnHelper.accessor("status_name", {
      id: "url",
      header: () =>
        state === "Active" ? (
          <Text
            className="font-medium min-w-[84px] sm:pl-5 pl-6 py-[11px] text-blue_gray_400 text-left"
            as="p"
            variant="h5"
          >
            URL
          </Text>
        ) : (
          ""
        ),
      cell: (info) =>
        info?.getValue() === "Active" ? (
          <a href={info?.row.original.url} target="_blank" rel="noreferrer">
            {showLoader ? (
              <div className="App">
                <div className="spinner-container">
                  <div className="loading-spinner" />
                </div>
              </div>
            ) : (
              <>
                <div
                // onClick={() => {
                //   setIsOpened(true);
                //   // setDataService({
                //   //   app_id: info?.row.original.app_id,
                //   // });
                //   localStorage.setItem("app_id", info?.row.original.app_id);
                // }}
                >
                  {/* {console.log(info?.row.original)} */}
                  <Img
                    src={process.env.PUBLIC_URL + "/images/img_file_table.svg"}
                    onClick={() => {
                      getUrl(info?.row.original.app_id);
                      console.log("data info cell: ", info);
                    }}
                    className="cursor-pointer ml-[35px] "
                    alt="material-symbols:add-box-outline-rounded"
                  />
                </div>
              </>
            )}
          </a>
        ) : (
          ""
        ),
    });

    const detail = columnHelper.accessor("app_id", {
      id: "detail",
      header: () => (
        <Text
          className="font-medium min-w-[216px] sm:pl-5 pl-6 py-[11px] text-blue_gray_400 text-left"
          as="p"
          variant="h5"
        ></Text>
      ),
      cell: (info) => (
        <Button
          className="cursor-pointer ml-6 flex items-center justify-center min-w-[161px] w-auto"
          shape="RoundedBorder4"
          size="sm"
          variant="OutlineGray400_1"
          onClick={() => {
            navigate(`detail/${info?.getValue()}`);
          }}
        >
          Detail
        </Button>
      ),
    });
    // if (statusSelected==="active") {
    columns.unshift(url);
    // }
    columns.unshift(detail);
    setHeader(columns);
  };

  // Get Product
  useEffect(() => {
    setHeaderData(status);
  }, [status]);

  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();

  function getUrl(appid) {
    setShowLoader(true);
    dispatch(urlProduct({ appid: appid }))
      .then(unwrapResult)
      .then((obj) => {
        // console.log({ obj });
        setShowLoader(false);
        var urls = obj.link;
        copyToClipboard(urls);
      })
      .catch((obj) => {
        setShowLoader(false);
      });
  }

  const copyToClipboard = (content) => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // toast("Berhasil menyalin link !!!");
    toast.success("Berhasil menyalin link", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  // ================================

  return (
    <>
      <ToastContainer />
      <div className="bg-white_A700 flex flex-col gap-[26px] items-start justify-start w-full">
        <ReactTable
          columns={header}
          data={datas}
          rowClass={""}
          headerClass="bg-gray_100_01"
        />
      </div>
    </>
  );

  // ================================
};

export default ListProductTable;
