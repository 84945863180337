import React from "react";
import ModalProvider from "react-modal";
// import { useNavigate } from "react-router-dom";

import { Img, Text, Button } from "components";
import { BASE_PATH_IMAGE } from "app/constants";

const MyaccountcontactdetaileditsuccesspageModal = (props) => {
  const closeModal = () => {
    props.closePopup();
  };
  // const navigate = useNavigate();
  return (
    <>
      <ModalProvider
        appElement={document.getElementById("root")}
        className="m-auto"
        overlayClassName="bg-black_900_66 fixed flex h-full inset-y-[0] w-full"
        {...props}
      >
        <div className="max-h-[97vh] overflow-y-auto sm:w-full md:w-full">
          <div className="bg-white_A700 flex flex-col gap-8 items-center justify-start p-8 md:px-5 rounded-[16px] w-[560px] sm:w-full">
            <Img
              src={
                process.env.PUBLIC_URL + "/images/img_mdiaccountarrowright.svg"
              }
              className="h-[100px] w-[100px]"
              alt="mdiaccountarrow"
            />
            <div className="flex flex-col gap-4 items-center justify-start w-full">
              <Text
                className="font-semibold text-gray_800 text-left w-auto"
                as="h4"
                variant="h4"
              >
                Perubahan Data Diajukan
              </Text>
              <Text
                className="font-medium max-w-[496px] md:max-w-full text-blue_gray_400 text-center tracking-[0.14px]"
                variant="body1"
              >
                Terima kasih telah menggunakan DPA, kami akan proses Perubahan
                Data Anda dalam 2x24 jam. Untuk informasi cek berkala inbox dan
                email Anda.
              </Text>
            </div>
            <Button
              className="cursor-pointer font-semibold leading-[normal] min-w-[118px] text-base text-center text-white_A700 w-auto"
              shape="RoundedBorder8"
              size="md"
              variant="FillBlue900"
              onClick={() => closeModal()}
            >
              Selesai
            </Button>
          </div>
        </div>
      </ModalProvider>
    </>
  );
};

export default MyaccountcontactdetaileditsuccesspageModal;
