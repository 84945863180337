import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import axios from "axios"
import {baseUrl} from '../../../app/constants'


export const getBank = createAsyncThunk("getBank/getBank", async({uid})=>{
      /// ******** API Get Company ********
    const response = await axios.post(`${baseUrl}get_bank`, {
        uid: uid,
    })
    return response.data.response;
})

const getBankEntity = createEntityAdapter({
    selectId: (getBank) => getBank.id
})

const getBankSlice = createSlice({
    name: "getBank",
    initialState: 
    getBankEntity.getInitialState(),
    extraReducers:{
        [getBank.pending]: (state) => {
            console.log('pending')
        },
        [getBank.fulfilled]: (state, action) => {
            getBankEntity.setAll(state, action.payload);
        },
        [getBank.rejected]: (state) => {
            console.log('rejected')
        },
    }
})

export const getBankSelector = getBankEntity.getSelectors(state => state.getBank)
export default getBankSlice.reducer;