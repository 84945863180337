import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const onceTimeTransaction = createAsyncThunk(
  "onceTimeTransaction/onceTimeTransaction",
  async ({ uid }) => {
    /// ******** API oncetime_success_transaction_per_day ********
    const response = await axios.post(
      `${baseUrl}oncetime_success_transaction_per_day`,
      {
        uid: uid,
      }
    );
    return response.data.response;
  }
);

const changePasswordEntity = createEntityAdapter({
  selectId: (onceTimeTransaction) => onceTimeTransaction.id,
});

const changePasswordSlice = createSlice({
  name: "onceTimeTransaction",
  initialState: changePasswordEntity.getInitialState(),
  extraReducers: {
    [onceTimeTransaction.pending]: (state) => {
      // console.log('pending')
    },
    [onceTimeTransaction.fulfilled]: (state, action) => {
      changePasswordEntity.setAll(state, action.payload);
    },
    [onceTimeTransaction.rejected]: (state) => {
      // console.log("rejected");
    },
  },
});

export const changePasswordSelector = changePasswordEntity.getSelectors(
  (state) => state.onceTimeTransaction
);
export default changePasswordSlice.reducer;
