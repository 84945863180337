import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "app/constants";
import axios from "axios";

export const getReasonRejected = createAsyncThunk(
  "productAlt/getReasonRejected",
  async ({ app_id }) => { 
    try {
      const response = await axios.post(`${baseUrl}reason_rejected`, {
        app_id: app_id, 
      });
      return response.data.response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

