import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import axios from "axios"
import {baseUrl} from '../../../app/constants'


export const getCompany = createAsyncThunk("getCompany/getCompany", async({uid})=>{
      /// ******** API Get Company ********
    const response = await axios.post(`${baseUrl}get_company`, {
        uid: uid,
    })
    return response.data.response;
})

const getCompanyEntity = createEntityAdapter({
    selectId: (getCompany) => getCompany.id
})

const getCompanySlice = createSlice({
    name: "getCompany",
    initialState: 
    getCompanyEntity.getInitialState(),
    extraReducers:{
        [getCompany.pending]: (state) => {
            console.log('pending')
        },
        [getCompany.fulfilled]: (state, action) => {
            getCompanyEntity.setAll(state, action.payload);
        },
        [getCompany.rejected]: (state) => {
            console.log('rejected')
        },
    }
})

export const getCompanySelector = getCompanyEntity.getSelectors(state => state.getCompany)
export default getCompanySlice.reducer;