import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  eachDayOfInterval,
  format,
  startOfMonth,
  endOfMonth,
  compareDesc,
  isThisMonth,
} from "date-fns";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile } from "sheetjs-style";

const TableReportRecapIncome = forwardRef(
  ({ tableData, month, year, isLoading }, ref) => {
    const [data, setData] = useState([]);

    const staticColumns = useMemo(
      () => [
        {
          accessorKey: "subject",
          header: format(new Date(year, month - 1), "MM-yyyy"),
        },
        {
          accessorKey: "total",
          header: "Total",
        },
        {
          accessorKey: "average",
          header: "Rata-rata",
        },
        {
          accessorKey: "estimation",
          header: "Estimasi Akhir Bulan",
        },
      ],
      [month, year]
    );

    const generateDynamicColumns = (month, year) => {
      const start = startOfMonth(new Date(year, month - 1));
      let end = endOfMonth(new Date(year, month - 1));

      if (isThisMonth(start)) {
        end = new Date();
      }

      const dates = eachDayOfInterval({ start, end });
      dates.sort((a, b) => compareDesc(a, b));

      return dates.map((date) => ({
        accessorKey: format(date, "d"),
        header: format(date, "dd"),
        className: "text-right px-2 py-2",
      }));
    };

    const dynamicColumns = useMemo(
      () => generateDynamicColumns(month, year),
      [month, year]
    );

    const columns = useMemo(
      () => [...staticColumns, ...dynamicColumns],
      [staticColumns, dynamicColumns]
    );

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    const rows = table.getPrePaginationRowModel().rows;

    const handleDownload = (type) => {
      if (type === "pdf") {
        const doc = new jsPDF("landscape");
        const maxColumnsPerTable = 10;

        // Map tableData to the format expected by autoTable
        const dataTable = rows.map((row) => {
          return columns.map((col) => {
            const value = row.original[col.accessorKey];
            return typeof value === "number"
              ? value.toLocaleString("de-DE")
              : value;
          });
        });

        const tableHeaders = columns.map((c) => c.header);

        const createPDFTable = (
          doc,
          headers,
          data,
          startX = 10,
          startY = 10
        ) => {
          autoTable(doc, {
            head: [headers],
            body: data,
            startY: startY,
            margin: { left: startX, right: startX },
            styles: {
              cellWidth: "wrap",
              halign: "right", // Align right by default
            },
            headStyles: {
              0: { halign: "left" }, // Align headers to right by default
            },
            columnStyles: {
              0: { halign: "left" }, // First column align left
            },
          });
        };

        // Handle the case where the number of columns exceeds the maximum allowed per table
        if (columns.length > maxColumnsPerTable) {
          let startY = 10;
          let remainingColumns = columns.length - 4;
          let currentColumns = 4;
          let currentTableData = [];

          // Add fixed columns to each row
          const fixedColumns = columns.slice(0, 4);
          const fixedHeaders = fixedColumns.map((c) => c.header);
          const fixedData = dataTable.map((row) => row.slice(0, 4));

          while (remainingColumns > 0) {
            currentColumns = Math.min(remainingColumns, maxColumnsPerTable - 4);
            const startColumnIndex = columns.length - remainingColumns;
            const dynamicColumns = columns.slice(
              startColumnIndex,
              startColumnIndex + currentColumns
            );
            const dynamicHeaders = dynamicColumns.map((c) => c.header);
            const dynamicData = dataTable.map((row) =>
              row.slice(startColumnIndex, startColumnIndex + currentColumns)
            );

            // Combine fixed and dynamic columns for headers and data
            const headers = [...fixedHeaders, ...dynamicHeaders];
            currentTableData = fixedData.map((row, index) => [
              ...row,
              ...dynamicData[index],
            ]);

            createPDFTable(doc, headers, currentTableData, 10, startY);

            startY = doc.autoTable.previous.finalY + 10;
            remainingColumns -= currentColumns;
          }
        } else {
          createPDFTable(doc, tableHeaders, dataTable);
        }

        doc.save("rekap-pendapatan.pdf");
      } else if (type === "excel") {
        const worksheetData = [columns.map((col) => col.header)]; // Add headers
        rows.forEach((row) => {
          worksheetData.push(
            columns.map((col) => row.original[col.accessorKey])
          );
        });

        const worksheet = utils.aoa_to_sheet(worksheetData);

        const columnWidths = columns.map(() => ({ wch: 15 })); // Adjust width as needed
        worksheet["!cols"] = columnWidths;

        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };

        const backgroundStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right",
          },
        };

        const headerStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "E4EDFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right",
          },
        };

        columns.forEach((col, colIndex) => {
          const cellAddress = utils.encode_cell({ r: 0, c: colIndex });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = {
              ...headerStyle,
              border: borderStyle,
              alignment: {
                ...headerStyle.alignment,
                horizontal: colIndex === 0 ? "center" : "right", // First header column align left
              },
            };
          }
        });

        worksheetData.forEach((row, rowIndex) => {
          row.forEach((_, colIndex) => {
            const cellAddress = utils.encode_cell({ r: rowIndex, c: colIndex });
            if (worksheet[cellAddress]) {
              if (rowIndex === 0) {
                worksheet[cellAddress].s = {
                  ...headerStyle,
                  border: borderStyle,
                  alignment: {
                    ...headerStyle.alignment,
                    horizontal: colIndex === 0 ? "left" : "right", // First header column align left
                  },
                };
              } else {
                worksheet[cellAddress].s = {
                  ...backgroundStyle,
                  border: borderStyle,
                  alignment: {
                    ...backgroundStyle.alignment,
                    horizontal: colIndex === 0 ? "left" : "right", // First column align left, others align right
                  },
                };
              }
            }
          });
        });

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Report");
        writeFile(workbook, "rekap-pendapatan.xlsx");
      }
    };

    useImperativeHandle(ref, () => ({
      handleDownload,
    }));

    useEffect(() => {
      if (tableData) {
        const allDates = generateDynamicColumns(month, year).map(
          (col) => col.accessorKey
        );

        const processData = (type, entry) => {
          const dateValues = {};
          allDates.forEach((date) => {
            dateValues[date] = 0;
          });

          if (entry.dates) {
            entry.dates.forEach((d) => {
              dateValues[d.date_count] = Number(d.trx) || 0; // Ensure trx is 0 if undefined
            });
          }

          return {
            subject: type,
            total: parseInt(entry.total) === 0 ? "0" : parseInt(entry.total),
            average:
              parseInt(entry.average) === 0 ? "0" : parseInt(entry.average),
            estimation:
              parseInt(entry.estimation) === 0
                ? "0"
                : parseInt(entry.estimation),
            ...dateValues,
          };
        };

        // Generate new data, formatting values after processing
        const newData = [
          { subject: "Pembelian :" },
          tableData.data_mo?.non_premium
            ? processData("Pembelian 1x", tableData.data_mo.non_premium)
            : null,
          tableData.data_mo?.premium
            ? processData("Premium", tableData.data_mo.premium)
            : null,
          { subject: "Permintaan Pembayaran :" },
          tableData.data_mt?.non_premium
            ? processData("Pembelian 1x", tableData.data_mt.non_premium)
            : null,
          tableData.data_mt?.premium
            ? processData("Premium", tableData.data_mt.premium)
            : null,
          tableData.data_mt?.renewal
            ? processData("Renewal", tableData.data_mt.renewal)
            : null,
          { subject: "Sukses :" },
          tableData.data_mt_delivered?.non_premium
            ? processData(
                "Pembelian 1x",
                tableData.data_mt_delivered.non_premium
              )
            : null,
          tableData.data_mt_delivered?.premium
            ? processData("Premium", tableData.data_mt_delivered.premium)
            : null,
          tableData.data_mt_delivered?.data_total_rev
            ? processData(
                "Total Pendapatan",
                tableData.data_mt_delivered.data_total_rev
              )
            : null,
        ].filter((entry) => entry !== null);

        // Format the numbers after creating the newData array
        const formattedData = newData.map((entry) => ({
          ...entry,
          total: entry.total === 0 ? 0 : entry.total?.toLocaleString("id-ID"),
          average:
            entry.average === 0 ? 0 : entry.average?.toLocaleString("id-ID"),
          estimation:
            entry.estimation === 0
              ? 0
              : entry.estimation?.toLocaleString("id-ID"),
        }));

        setData(newData);
      } else {
        setData([]);
      }
    }, [tableData, month, year]);

    return (
      <table className="mx-6">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`first:rounded-l-xl last:rounded-r-xl px-2 py-4 whitespace-nowrap bg-gray_100_01 text-blue_gray_400 ${
                    header.column.id !== "subject" ? "text-right" : "text-left"
                  }`}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading
            ? // Render skeleton rows
              Array.from({ length: 10 }).map((_, index) => (
                <tr key={index}>
                  {columns.map((col) => (
                    <td key={col.id}>
                      <Skeleton width={col.width || 100} height={30} />
                    </td>
                  ))}
                </tr>
              ))
            : data.map((row, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={`whitespace-nowrap ${
                      row.subject.includes("Permintaan Pembayaran") ||
                      row.subject.includes("Sukses") ||
                      row.subject.includes("Total Pendapatan")
                        ? "border-t border-gray-200"
                        : ""
                    }`}
                  >
                    {columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className={`px-2 py-1 ${
                          column.accessorKey !== "subject" ? "text-right" : ""
                        } ${
                          (row.subject === "Pembelian :" ||
                            row.subject === "Permintaan Pembayaran :" ||
                            row.subject === "Sukses :") &&
                          column.accessorKey === "subject"
                            ? "font-bold text-gray-400 text-lg"
                            : ""
                        }`}
                      >
                        {dynamicColumns
                          .map((col) => col.accessorKey)
                          .includes(column.accessorKey)
                          ? row.subject.includes("Pembelian :") ||
                            row.subject.includes("Permintaan Pembayaran :") ||
                            row.subject.includes("Sukses :")
                            ? row[column.accessorKey]
                            : Number(row[column.accessorKey]).toLocaleString(
                                "de-DE"
                              )
                          : ["total", "average", "estimation"].includes(
                              column.accessorKey
                            )
                          ? row[column.accessorKey]
                            ? Number(row[column.accessorKey]).toLocaleString(
                                "de-DE"
                              )
                            : ""
                          : row[column.accessorKey] || ""}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
        </tbody>
      </table>
    );
  }
);

export default TableReportRecapIncome;
