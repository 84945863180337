import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getPrice = createAsyncThunk("getPrice/getPrice", async () => {
  /// ******** API Price ********
  const response = await axios.get(`${baseUrl}get_price`);
  return response.data.response;
});

const getPriceEntity = createEntityAdapter({
  selectId: (getPrice) => getPrice.id,
});

const getPriceSlice = createSlice({
  name: "getPrice",
  initialState: getPriceEntity.getInitialState(),
  extraReducers: {
    [getPrice.pending]: (state) => {
      // console.log('pending')
    },
    [getPrice.fulfilled]: (state, action) => {
      getPriceEntity.setAll(state, action.payload);
    },
    [getPrice.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getPriceSelector = getPriceEntity.getSelectors(
  (state) => state.getPrice
);
export default getPriceSlice.reducer;
