import React from "react";
import Skeleton from "react-loading-skeleton";

function TableReportTarif({ responseTarif, columns, loading, day }) {
  const renderSkeleton = () => (
    <tr>
      {columns.map((_, index) => (
        <td key={index} className="px-3 py-3 border">
          <Skeleton width={100} />
        </td>
      ))}
      {day.map((_, dayIndex) => (
        <React.Fragment key={dayIndex}>
          <td className="px-3 py-3 border">
            <Skeleton width={100} />
          </td>
        </React.Fragment>
      ))}
    </tr>
  );

  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(number);
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="table-auto border-0 w-full">
        <thead className="bg-gray_100_01 text-center border-0 rounded-xl">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="px-6 py-3 text-sm font-bold text-blue_gray_400"
              >
                {column}
              </th>
            ))}
            {day.map((day, index) => (
              <th
                key={index}
                className="px-6 py-3 text-sm font-bold text-blue_gray_400"
              >
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {loading
            ? Array(3)
                .fill()
                .map((_, index) => (
                  <React.Fragment key={index}>
                    {renderSkeleton()}
                  </React.Fragment>
                ))
            : responseTarif.map((item) => (
                <tr key={item.keyword} className="border-b hover:bg-gray-100">
                  <td className="px-3 py-3 text-left text-sm first:rounded-l-xl pl-6 last:rounded-r-xl whitespace-nowrap  text-blue_gray_400">
                    {item.keyword}
                  </td>
                  <td className="px-3 py-3 text-right text-sm text-blue_gray_400">
                    {item.tarif}
                  </td>
                  <td className="px-3 py-3 text-right text-sm text-blue_gray_400">
                    {rupiah(item.transaksiSukses)}
                  </td>
                  <td className="px-3 py-3 text-right text-sm text-blue_gray_400">
                    {rupiah(item.totalPendapatan)}
                  </td>

                  {day.map((day, dayIndex) => (
                    <React.Fragment key={dayIndex}>
                      <td
                        key={dayIndex}
                        className="px-3 py-3 text-right text-sm text-blue_gray_400"
                      >
                        {rupiah(item.dataPerDay[parseInt(day)] || 0)}
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableReportTarif;
