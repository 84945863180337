/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ModalProvider from "react-modal";
import { Text, Img, Button, List, Line, Input } from "components";
import "react-circular-progressbar/dist/styles.css";
import { getInformation } from "./Features/informationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { BASE_PATH_IMAGE } from "app/constants";

const InformationcontactpouppageModal = (props) => {
  const [informations, setInformations] = useState([]);
  const WhatsAppDPA = () => {
    const phoneNumber = "6281283826027";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };
  const WhatsAppTselSupport = () => {
    const phoneNumber = "6281311227777";
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };

  const EmailDPA = () => {
    const emailAddress = "dpa@gmail.com";
    const subject = "Subject of the email";

    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;
  };

  const EmailTelco = () => {
    const emailAddress = "telkomsel@gmail.com";
    const subject = "Subject of the email";

    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}`;
  };

  // ======= State Get Product =======
  const dispatch = useDispatch();

  // Get Product
  useEffect(() => {
    dispatch(getInformation())
      .then(unwrapResult)
      .then((obj) => {
        const information = obj;
        setInformations(information);
      })
      .catch((obj) => {
        alert(obj.message);
      });
  }, []);

  // ================================

  return (
    <>
      <ModalProvider
        appElement={document.getElementById("root")}
        //  !w-[38%]
        className="m-auto !w-[38%]"
        overlayClassName="bg-black_900_66 fixed flex h-full inset-y-[0] w-full"
        {...props}
      >
        <div className="absolute bg-black_900_66 flex flex-col h-full inset-[0] items-center justify-center m-auto p-[160px] md:px-10 sm:px-5 w-full">
          <div className="bg-white_A700 flex flex-col items-center justify-center max-w-[1592px] my-12 self-stretch w-full rounded-2xl">
            <Input
              wrapClassName="flex w-full"
              className="font-semibold leading-[normal] p-0 placeholder:text-gray_800 text-gray_800 text-left text-lg w-full"
              name="frame764"
              placeholder="Informasi Kontak"
              suffix={
                <Img
                  src={process.env.PUBLIC_URL + "/images/img_icroundclose.svg"}
                  className="ml-[35px] my-px"
                  style={{ cursor: "pointer" }}
                  alt="ic:round-close"
                  onClick={() => props.setIsOpenParse(false)}
                />
              }
              shape="CustomBorderTL16"
              size="xl"
              variant="OutlineGray400_1"
            ></Input>
            {/*  grid md:grid-cols-[repeat(0,_1fr_1px)_1fr] grid-cols-[repeat(1,_1fr_1px)_1fr] justify-start sm:px-5 px-4 py-5 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none self-stretch w-auto md:w-full */}
            <List
              className="flex sm:flex-col flex-row gap-6 w-full"
              orientation="horizontal"
            >
              <div className="py-5 flex flex-col gap-5 items-start justify-start w-full sm:pb-5 px-5 sm:border-b-2 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none">
                <Text
                  className="font-semibold text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  DPA Support
                </Text>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Email
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.dps_email}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Nomor Whatsapp
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.dps_wa}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Nomor Telepon
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.dps_phone}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Customer Service (7x24 jam)
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.dps_cs}
                  </Text>
                </div>
                <div className="flex flex-row gap-4 items-start justify-start self-stretch w-auto">
                  <Button
                    className="flex h-[50px] items-center justify-center w-[50px]"
                    shape="icbRoundedBorder6"
                    size="lgIcn"
                    variant="icbFillBlue900"
                    onClick={WhatsAppDPA}
                  >
                    <Img
                      src={process.env.PUBLIC_URL + "/images/img_call.svg"}
                      className=""
                      alt="call"
                    />
                  </Button>
                  <Button
                    className="flex h-[50px] items-center justify-center w-[50px]"
                    shape="icbRoundedBorder6"
                    size="lgIcn"
                    variant="icbFillBlue900"
                    onClick={EmailDPA}
                  >
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_icbaselineemail.svg"
                      }
                      className="h-6"
                      alt="icbaselineemail"
                    />
                  </Button>
                </div>
              </div>
              <Line className="self-center h-[258px] bg-gray_400 w-px sm:hidden" />
              <div className="flex flex-col gap-5 py-5 items-start justify-start rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none w-full pr-5 sm:px-5 sm:py-4">
                <Text
                  className="font-semibold text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Telkomsel Support
                </Text>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Email
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.telco_email}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Nomor Whatsapp
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.telco_wa}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Nomor Telepon
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.telco_phone}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <Text
                    className="flex-1 font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    Customer Service (7x24 jam)
                  </Text>
                  <Text
                    className="font-normal not-italic text-black_200 text-left tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {informations.telco_cs}
                  </Text>
                </div>
                <div className="flex flex-row gap-4 items-start justify-start self-stretch w-auto">
                  <Button
                    className="flex h-[50px] items-center justify-center w-[50px]"
                    shape="icbRoundedBorder6"
                    size="lgIcn"
                    variant="icbFillBlue900"
                    onClick={WhatsAppTselSupport}
                  >
                    <Img
                      src={process.env.PUBLIC_URL + "/images/img_call.svg"}
                      className=""
                      alt="call"
                    />
                  </Button>
                  <Button
                    className="flex h-[50px] items-center justify-center w-[50px]"
                    shape="icbRoundedBorder6"
                    size="lgIcn"
                    variant="icbFillBlue900"
                    onClick={EmailTelco}
                  >
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_icbaselineemail.svg"
                      }
                      className="h-6"
                      alt="icbaselineemail"
                    />
                  </Button>
                </div>
              </div>
            </List>
          </div>
        </div>
      </ModalProvider>
    </>
  );
};

export default InformationcontactpouppageModal;
