import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SelectBox, Img, Button } from "components";
import {
  useGetAdnetsQuery,
  useGetKeywordQuery,
  useGetPartnerQuery,
  useGetReportDRMutation,
} from "app/api1";
import TableReportDR from "./TableReportDR";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const parseDate = (dateStr) => {
  const [year, month, day] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day);
};

const today = new Date();
const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

const selectDownload = [
  // { value: "pdf", label: "PDF" },
  { value: "excel", label: "Excel" },
];

const ReportDrComponents = () => {
  const users = JSON.parse(localStorage.getItem("user"));
  const idLevel = users.idLevel;
  const tableReportRef = useRef();

  const [filter, setFilter] = useState({
    start_date: formatDate(startOfMonth),
    end_date: formatDate(today),
    keyword: "",
    partner_id: "",
    media_id: "",
    uid: users?.id,
  });

  const [getReport, { data, isLoading, reset }] = useGetReportDRMutation();
  const { data: keywords } = useGetKeywordQuery({ uid: filter.uid });
  const { data: partners } = useGetPartnerQuery();
  const { data: adnets } = useGetAdnetsQuery();

  const selectedMethod = adnets?.find((m) => m.value === filter.media_id);

  const handleSelectChange = (selectedOption) => {
    if (tableReportRef.current) {
      const type = selectedOption ? selectedOption : null;
      if (type) {
        tableReportRef.current.handleDownload(type);
      }
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();

    const startDate = parseDate(filter.start_date);
    const endDate = parseDate(filter.end_date);

    if (startDate > endDate) {
      toast.error("Tanggal Akhir harus lebih besar dari Tanggal Mulai", {
        position: "top-center",
        autoClose: 1500,
        theme: "colored",
      });

      return;
    }

    reset();
    getReport({
      start_date: filter.start_date,
      end_date: filter.end_date,
      keyword: filter.keyword,
      partner_id: filter.partner_id,
      media_id: filter.media_id,
      uid: users.id,
    });
  };

  useEffect(() => {
    getReport({
      start_date: filter.start_date,
      end_date: filter.end_date,
      keyword: filter.keyword,
      partner_id: filter.partner_id,
      media_id: filter.media_id,
      uid: users?.id,
    });
  }, []);

  return (
    <>
      <div className="bg-white_A700 flex flex-col gap-6 items-start justify-center max-w-full p-[26px] sm:px-5 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none w-full">
        {/* Filter */}
        <div
          className={
            idLevel === 1 || idLevel === 14
              ? "grid grid-cols-7 gap-2 w-full"
              : "grid grid-cols-6 gap-6 w-full"
          }
        >
          {/* Start Date */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <input
              type="date"
              className="w-full p-3 border-0 text-gray_800 rounded-md bg-white_A700 outline outline-[1px] outline-gray_200"
              value={filter.start_date}
              max={formatDate(today)}
              onChange={(e) => {
                const date = parseDate(e.target.value);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  start_date: formatDate(date),
                }));
              }}
            />
          </div>

          {/* End Date */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <input
              type="date"
              className="w-full p-3 border-0 text-gray_800 rounded-md bg-white_A700 outline outline-[1px] outline-gray_200"
              value={filter.end_date}
              max={formatDate(today)}
              onChange={(e) => {
                const date = parseDate(e.target.value);
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  end_date: formatDate(date),
                }));
              }}
            />
          </div>

          {/* Partner */}
          {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              {idLevel === 1 || idLevel === 14 ? (
                <SelectBox
                  className="sm:flex-1 font-semibold leading-[normal] whitespace-nowrap text-gray_800 text-left text-sm sm:w-full max-w-screen-mdreal"
                  placeholderClassName="text-gray_800"
                  name="frame628350"
                  placeholder={"Semua Partner"}
                  isSearchable={false}
                  isMulti={false}
                  options={[
                    { label: "Semua Partner", value: users.id }, // Default option
                    ...(partners?.response?.map((partner) => ({
                      label: partner.partner_name,
                      value: partner.value,
                    })) || []), // Existing options
                  ]}
                  getOptionLabel={(option) => (
                    <div className="flex items-center">
                      <span>{option.label}</span>
                    </div>
                  )}
                  shape="RoundedBorder6"
                  size="sm"
                  variant="OutlineGray200"
                  onChange={(selectedOption) => {
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      partner_id: selectedOption,
                      uid: selectedOption,
                    }));
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {/* Media */}
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628351"
              placeholder={selectedMethod?.name || "Semua Media"}
              isSearchable={false}
              isMulti={false}
              options={[
                { label: "Semua Media", value: "" }, // Default option
                ...(adnets?.map((adnet) => ({
                  label: adnet.id + " " + adnet.name,
                  value: adnet.id,
                })) || []), // Existing options
              ]}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span>{option.label}</span>
                </div>
              )}
              onChange={(selectedOption) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  media_id: selectedOption,
                }));
              }}
              value={selectedMethod ? selectedMethod.id : ""}
              indicator={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_arrowdown_gray_800.svg"
                  }
                  className="h-6 w-6"
                  alt="arrow_down"
                />
              }
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
            />
          </div>

          {/* Keyword */}
          <div className="col-span-3 whitespace-nowrap mdreal:col-span-1 place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm w- sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628350"
              placeholder={filter.keyword || "Semua Keyword"}
              options={[
                { label: "Semua Keyword", value: "" }, // Default option
                ...(keywords?.response?.map((keyword) => ({
                  label: keyword.service_name,
                  value: keyword.service_name,
                })) || []), // Existing options
              ]}
              getOptionLabel={(option) => (
                <div className="flex items-center">
                  <span>{option.label}</span>
                </div>
              )}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
              onChange={(selectedOption) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  keyword: selectedOption,
                }));
              }}
              required={true}
            />
          </div>

          {/* Button if not admin */}
          {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              <Button
                className="cursor-pointer flex items-center justify-center w-[125px] h-[47px]"
                leftIcon={
                  <Img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/img_search_white_a700.svg"
                    }
                    className="mr-2.5"
                    alt="search"
                  />
                }
                shape="RoundedBorder8"
                size="md"
                variant="OutlineGray200"
                onClick={handleButtonClick}
              >
                <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                  Update
                </div>
              </Button>
            </div>
          ) : (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              <Button
                className="cursor-pointer flex items-center justify-center w-[125px] h-[47px]"
                leftIcon={
                  <Img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/img_search_white_a700.svg"
                    }
                    className="mr-2.5"
                    alt="search"
                  />
                }
                shape="RoundedBorder8"
                size="md"
                variant="OutlineGray200"
                onClick={handleButtonClick}
              >
                <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                  Update
                </div>
              </Button>
            </div>
          )}

          {/* Unduh Laporan */}
          <div className="justify-end place-items-center">
            <SelectBox
              className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full whitespace-nowrap w-full"
              placeholderClassName="text-gray_800"
              name="frame628350"
              placeholder="Unduh Laporan"
              isSearchable={false}
              isMulti={false}
              options={selectDownload}
              onChange={handleSelectChange}
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
            />
          </div>

          {/* Button if admin */}
          {/* {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              <Button
                className="cursor-pointer flex items-center justify-center w-[125px] h-[47px]"
                leftIcon={
                  <Img
                    src={process.env.PUBLIC_URL + "/images/img_search_white_a700.svg"}
                    className="mr-2.5"
                    alt="search"
                  />
                }
                shape="RoundedBorder8"
                size="md"
                variant="OutlineGray200"
                onClick={handleButtonClick}
              >
                <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                  Update
                </div>
              </Button>
            </div>
          ) : (
            <></>
          )} */}
        </div>

        {/* Table */}
        <div className="bg-white_A700 flex flex-col gap-[26px] items-start justify-start w-full overflow-x-auto">
          {filter.start_date <= filter.end_date && (
            <TableReportDR
              tableData={data?.data || []}
              start_date={filter.start_date}
              end_date={filter.end_date}
              isLoading={isLoading}
              ref={tableReportRef}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ReportDrComponents;
