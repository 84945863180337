import React, { useMemo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { eachDayOfInterval, format, startOfMonth, endOfMonth, compareDesc, isThisMonth } from 'date-fns';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { utils, writeFile } from 'sheetjs-style';

const TableReportRecapIncomeTopPartner = forwardRef(({ tableData, month, year }, ref) => {
  const [data, setData] = useState([]);

  const staticColumns = useMemo(() => [
    { accessorKey: 'subject', header: format(new Date(year, month - 1), 'MM-yyyy') },
    { accessorKey: 'total', header: 'Total' },
    { accessorKey: 'average', header: 'Rata-rata' },
    { accessorKey: 'estimation', header: 'Estimasi Akhir Bulan' },
  ], [month, year]);

  const generateDynamicColumns = (month, year) => {
    const start = startOfMonth(new Date(year, month - 1));
    let end = endOfMonth(new Date(year, month - 1));

    if (isThisMonth(start)) {
      end = new Date();
    }

    const dates = eachDayOfInterval({ start, end });
    dates.sort((a, b) => compareDesc(a, b));

    return dates.map(date => ({
      accessorKey: format(date, 'd'),
      header: format(date, 'dd'),
      className: 'text-right px-2 py-2'
    }));
  };

  const dynamicColumns = useMemo(() => generateDynamicColumns(month, year), [month, year]);
  const columns = useMemo(() => [...staticColumns, ...dynamicColumns], [staticColumns, dynamicColumns]);

  useEffect(() => {
    if (tableData) {
      const allDates = generateDynamicColumns(month, year).map(col => col.accessorKey);

      const processData = (type, entry) => {
        const dateValues = allDates.reduce((acc, date) => ({ ...acc, [date]: 0 }), {});
        entry.dates?.forEach(d => { dateValues[d.date_count] = d.trx; });

        return {
          subject: type,
          total: entry.total.toLocaleString('id-ID'),
          average: entry.average.toLocaleString('id-ID'),
          estimation: entry.estimation.toLocaleString('id-ID'),
          ...dateValues,
        };
      };

      const processPartnerData = (partner) => {
        const { data_mo, data_mt, data_mt_delivered } = partner.data;
        const partnerEntries = [];

        const addEntry = (header, key, entry) => {
          if (entry[key]) partnerEntries.push(processData(header, entry[key]));
        };

        if (data_mo) {
          partnerEntries.push({ subject: 'Pembelian :', isSubHeader: true });
          addEntry('Pembelian 1x', 'non_premium', data_mo);
          addEntry('Premium', 'premium', data_mo);
        }
        if (data_mt) {
          partnerEntries.push({ subject: 'Permintaan Pembayaran :', isSubHeader: true });
          addEntry('Pembelian 1x', 'non_premium', data_mt);
          addEntry('Premium', 'premium', data_mt);
          addEntry('Renewal', 'renewal', data_mt);
        }
        if (data_mt_delivered) {
          partnerEntries.push({ subject: 'Sukses :', isSubHeader: true });
          addEntry('Pembelian 1x', 'non_premium', data_mt_delivered);
          addEntry('Premium', 'premium', data_mt_delivered);
          partnerEntries.push({ subject: '', isSubHeader: true });
          addEntry('Total Pendapatan', 'data_total_rev', data_mt_delivered);
        }

        return { partnerName: partner.partner, entries: partnerEntries };
      };

      setData(tableData.data_top_partner?.partners?.slice(0, 5).map(processPartnerData) || []);
    } else {
      setData([]);
    }
  }, [tableData, month, year]);

  useImperativeHandle(ref, () => ({
    handleDownload: (type) => {
      if (type === 'pdf') {
        const doc = new jsPDF('landscape');
        data.forEach((partnerData, partnerIndex) => {
          autoTable(doc, {
            head: [columns.map(column => column.header)],
            body: partnerData.entries.map(entry => columns.map(column => entry[column.accessorKey])),
            startY: partnerIndex === 0 ? 10 : doc.lastAutoTable.finalY + 10,
            margin: { top: 10 },
            styles: { fontSize: 8 },
            didDrawPage: (data) => {
              if (partnerIndex === 0) {
                doc.text(partnerData.partnerName, data.settings.margin.left, 6);
              } else {
                doc.text(partnerData.partnerName, data.settings.margin.left, doc.lastAutoTable.finalY + 6);
              }
            }
          });
        });
        doc.save('report.pdf');
      } else if (type === 'excel') {
        const workbook = utils.book_new();
        data.forEach((partnerData, partnerIndex) => {
          const worksheetData = [
            columns.map(column => column.header),
            ...partnerData.entries.map(entry => columns.map(column => entry[column.accessorKey])),
          ];
          const worksheet = utils.aoa_to_sheet(worksheetData);
          utils.book_append_sheet(workbook, worksheet, partnerData.partnerName);
        });
        writeFile(workbook, 'report.xlsx');
      }
    }
  }));

  return (
    <div className='w-full'>
      {data.map((partnerData, partnerIndex) => (
        <div key={partnerIndex} className="mb-8">
          <h2 className="bg-white text-lg font-bold px-4 py-4 pl-6">{partnerData.partnerName}</h2>
          <div className="overflow-x-auto">
            <table className="bg-white">
              <thead>
                <tr>
                  {columns.map((column, columnIndex) => (
                    <th key={columnIndex} className={`first:rounded-l-xl pl-6 last:rounded-r-xl px-2 py-4 whitespace-nowrap bg-gray_100_01 text-blue_gray_400 ${column.accessorKey !== 'subject' ? 'text-right' : 'text-left w-[260px] min-w-[260px]'}`}>
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {partnerData.entries.map((row, rowIndex) => (
                  row.isSubHeader ? (
                    <tr key={rowIndex}>
                      <td colSpan={columns.length} className="px-2 py-2 pl-6 font-bold text-gray-400 text-lg border-t border-gray-200 whitespace-nowrap">
                        {row.subject}
                      </td>
                    </tr>
                  ) : row.isTotal ? (
                    <tr key={rowIndex}>
                      <td colSpan={columns.length} className="px-2 py-2 pl-6 font-bold text-gray-600 text-base border-t border-gray-400 whitespace-nowrap">
                        {row.subject}
                      </td>
                    </tr>
                  ) : (
                    <tr key={rowIndex} className={row.subject.includes('Pembelian') || row.subject.includes('Permintaan Pembayaran') || row.subject.includes('Sukses') || row.subject.includes('Total Pendapatan') ? 'whitespace-nowrap' : ''}>
                      {columns.map((column, cellIndex) => (
                        <td key={cellIndex} className={`px-2 py-1 pl-6 whitespace-nowrap ${column.accessorKey !== 'subject' ? 'text-right' : ''}`}>
                          {dynamicColumns.some(col => col.accessorKey === column.accessorKey)
                            ? (row.subject.includes('Pembelian') || row.subject.includes('Permintaan Pembayaran') || row.subject.includes('Sukses'))
                              ? row[column.accessorKey]
                              : Number(row[column.accessorKey]).toLocaleString('id-ID')
                            : row[column.accessorKey]}
                        </td>
                      ))}
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
});

export default TableReportRecapIncomeTopPartner;
