/* eslint-disable eqeqeq */
import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

var data = [];
var options = [];

function BarChart({
  total,
  games,
  hiburan,
  film,
  // unit and stepSize
  annualUnit,
  annualStepSize,
  monthlyUnit,
  monthlyStepSize,
  weeklyUnit,
  weeklyStepSize,
  dailyUnit,
  dailyStepSize,
  //
  periode,
  labels,
  idLevel,
  dataSet,
  dataSet1,
  dataSet2,
  dataSet3,
  dataSet4,
  dataSet5,
  products,
}) {

  let newDataset = [{
    type: "line",
    label: "Total",
    data: total,
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "rgba(255, 0, 32, 1)",
    borderWidth: 2,
    pointHoverRadius: 16,
    pointRadius: 8}]

  for (let i in products) {
    let newRecord = {
          type: "bar",
          label: products[i].dataset,
          data: dataSet[i],
          backgroundColor: products[i].bgColor
        };
    newDataset.push(newRecord);
  }

  /// Data
  idLevel == 1 || idLevel == 14
    ? (data = {
        labels: labels,
        datasets: newDataset
      })
    : (
      data = {
        labels: labels,
        datasets: newDataset
      });

  /// Options
  /// Admin
  idLevel == 1 || idLevel == 14
    ? periode == "30day"
      ? (options = {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          borderRadius: 5,
          borderWidth: 3,
          borderColor: "#FFFFFF",
          spacing: 10,
        })
      : periode == "week"
      ? (options = {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          borderRadius: 5,
          borderWidth: 3,
          borderColor: "#FFFFFF",
          spacing: 10,
        })
      : periode == "hours"
      ? (options = {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          borderRadius: 5,
          borderWidth: 3,
          borderColor: "#FFFFFF",
          spacing: 10,
        })
      : (options = {
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          borderRadius: 5,
          borderWidth: 3,
          borderColor: "#FFFFFF",
          spacing: 10,
        })
    : /// Partner
    periode == "30day"
    ? (options = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        borderRadius: 5,
        borderWidth: 3,
        borderColor: "#FFFFFF",
        spacing: 30,
      })
    : periode == "week"
    ? (options = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        borderRadius: 5,
        borderWidth: 3,
        borderColor: "#FFFFFF",
        spacing: 10,
      })
    : periode == "hours"
    ? (options = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        borderRadius: 5,
        borderWidth: 3,
        borderColor: "#FFFFFF",
        spacing: 30,
      })
    : (options = {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        borderRadius: 5,
        borderWidth: 3,
        borderColor: "#FFFFFF",
        spacing: 30,
      });

  options.plugins = {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(context.parsed.y);
          }
          return label;
        },
      },
    },
  };

  return (
    <>
      <Chart type="bar" data={data} options={options} />
    </>
  );
}

BarChart.defaultProps = {
  products: [
    {
      app_id: "247227307789525",
      dataset: "Guardian Tales",
      bgColor: "#DBC4F0",
    },
    {
      app_id: "58754455325796",
      dataset: "Mobile Legends: Bang Bang",
      bgColor: "#FFCACC",
    },
    {
      app_id: "180686805771309",
      dataset: "CookieRun: Kingdom",
      bgColor: "#D4E2D4",
    },
    { app_id: "152091047124741", dataset: "Real Racing 3", bgColor: "#FAF0D7" },
    {
      app_id: "127672112308600",
      dataset: "testing 3, 7 feb",
      bgColor: "#AEE2FF",
    },
  ],
};

export default BarChart;
