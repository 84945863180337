import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, {
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { format, eachDayOfInterval, parse, compareDesc } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile } from "sheetjs-style";

const TableReportPembelian = forwardRef(
  ({ tableData, start_date, end_date, isLoading }, ref) => {
    const [data, setData] = useState([]);

    const staticColumns = useMemo(
      () => [
        {
          accessorKey: "media_id",
          header: "Media ID",
          className: "text-right",
        },
        {
          accessorKey: "service",
          header: "Keyword",
        },
        {
          accessorKey: "total",
          header: "Total",
          className: "text-right",
        },
      ],
      []
    );

    const generateDynamicColumns = (startDate, endDate) => {
      const start = parse(startDate, "yyyy-MM-dd", new Date());
      const end = parse(endDate, "yyyy-MM-dd", new Date());

      if (start > end) {
        toast.error("Tanggal Akhir harus lebih besar dari Tanggal Mulai", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
        return [];
      }

      if (format(start, "yyyy-MM-dd") === format(end, "yyyy-MM-dd")) {
        return Array.from({ length: 24 }, (_, i) => {
          // Format hour to always be two digits
          const formattedHour = i.toString().padStart(2, "0");
          return {
            accessorKey: `hour-${formattedHour}`,
            header: `${formattedHour}:00`,
            className: "text-right",
          };
        });
      } else {
        const dates = eachDayOfInterval({ start, end });
        dates.sort((a, b) => compareDesc(a, b)); // sort the dates for the newest first

        return dates.map((date) => ({
          accessorKey: format(date, "MM-dd"),
          header: format(date, "MM-dd"),
          className: "text-right",
        }));
      }
    };

    const dynamicColumns = useMemo(
      () => generateDynamicColumns(start_date, end_date),
      [start_date, end_date]
    );
    const columns = useMemo(
      () => [...staticColumns, ...dynamicColumns],
      [staticColumns, dynamicColumns]
    );

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
    const rows = table.getPrePaginationRowModel().rows;

    const handleDownload = (type) => {
      if (type === "pdf") {
        const doc = new jsPDF("landscape");
        const maxColumnsPerTable = 15;

        const formatNumber = (value) => {
          return typeof value === "number"
            ? value.toLocaleString("de-DE")
            : value;
        };

        const dataTable = rows.map((row) =>
          columns.map((col) => formatNumber(row.original[col.accessorKey]))
        );
        const tableHeaders = columns.map((c) => c.header);

        const createPDFTable = (
          doc,
          headers,
          data,
          startX = 10,
          startY = 10
        ) => {
          autoTable(doc, {
            head: [headers],
            body: data,
            startY: startY,
            margin: { left: startX, right: startX },
            styles: {
              cellWidth: "wrap",
              halign: "right", // Align right by default
            },
            headStyles: {
              halign: "right", // Align headers to right by default
            },
            columnStyles: {
              0: { halign: "center" }, // First column align left
            },
          });
        };

        if (columns.length > maxColumnsPerTable) {
          let startY = 10;
          let remainingColumns = columns.length - 4;
          let currentColumns = 4;
          let currentTableData = [];

          const fixedColumns = columns.slice(0, 4);
          const fixedHeaders = fixedColumns.map((c) => c.header);
          const fixedData = dataTable.map((row) => row.slice(0, 4));

          while (remainingColumns > 0) {
            currentColumns = Math.min(remainingColumns, maxColumnsPerTable - 4);
            const startColumnIndex = columns.length - remainingColumns;
            const dynamicColumns = columns.slice(
              startColumnIndex,
              startColumnIndex + currentColumns
            );
            const dynamicHeaders = dynamicColumns.map((c) => c.header);
            const dynamicData = dataTable.map((row) =>
              row.slice(startColumnIndex, startColumnIndex + currentColumns)
            );

            const headers = [...fixedHeaders, ...dynamicHeaders];
            currentTableData = fixedData.map((row, index) => [
              ...row,
              ...dynamicData[index],
            ]);

            createPDFTable(doc, headers, currentTableData, 10, startY);

            startY = doc.autoTable.previous.finalY + 10;
            remainingColumns -= currentColumns;
          }
        } else {
          createPDFTable(doc, tableHeaders, dataTable);
        }

        doc.save("rekap-pembelian.pdf");
      } else if (type === "excel") {
        const formatNumber = (value) => {
          return typeof value === "number"
            ? value.toLocaleString("de-DE")
            : value;
        };

        const worksheetData = [columns.map((col) => col.header)];
        rows.forEach((row) => {
          worksheetData.push(
            columns.map((col) => row.original[col.accessorKey])
          );
        });

        const worksheet = utils.aoa_to_sheet(worksheetData);
        const columnWidths = columns.map(() => ({ wch: 15 }));
        worksheet["!cols"] = columnWidths;

        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };

        const backgroundStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align data cells to right by default
          },
        };

        const headerStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "E4EDFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align headers to right by default
          },
        };

        columns.forEach((col, colIndex) => {
          const cellAddress = utils.encode_cell({ r: 0, c: colIndex });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = {
              ...headerStyle,
              border: borderStyle,
              alignment: {
                ...headerStyle.alignment,
                horizontal: colIndex === 1 ? "left" : "right", // First header column align left
              },
            };
          }
        });

        worksheetData.forEach((row, rowIndex) => {
          row.forEach((_, colIndex) => {
            const cellAddress = utils.encode_cell({ r: rowIndex, c: colIndex });
            if (worksheet[cellAddress]) {
              if (rowIndex === 0) {
                worksheet[cellAddress].s = {
                  ...headerStyle,
                  border: borderStyle,
                  alignment: {
                    ...headerStyle.alignment,
                    horizontal: colIndex === 1 ? "left" : "right", // First header column align left
                  },
                };
              } else {
                worksheet[cellAddress].s = {
                  ...backgroundStyle,
                  border: borderStyle,
                  alignment: {
                    ...backgroundStyle.alignment,
                    horizontal: colIndex === 1 ? "left" : "right", // First column align left, others align right
                  },
                };
              }
            }
          });
        });

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Report");
        writeFile(workbook, "rekap-pembelian.xlsx");
      }
    };

    useImperativeHandle(ref, () => ({
      handleDownload,
    }));

    useEffect(() => {
      if (tableData && tableData.data_mo?.length > 0) {
        const start = parse(start_date, "yyyy-MM-dd", new Date());
        const end = parse(end_date, "yyyy-MM-dd", new Date());

        const allDatesOrHours = generateDynamicColumns(
          start_date,
          end_date
        ).map((col) => col.accessorKey);

        const newData = tableData.data_mo?.flatMap((entry, index) => {
          const dateOrHourValues = {};
          allDatesOrHours.forEach((dateOrHour) => {
            dateOrHourValues[dateOrHour] = 0;
          });

          entry.date?.forEach((d) => {
            const accessorKey =
              format(start, "yyyy-MM-dd") === format(end, "yyyy-MM-dd")
                ? `hour-${d.time.split(":")[0]}`
                : d.time;
            dateOrHourValues[accessorKey] = Number(d.trx) || 0;
          });

          return {
            service: entry.service,
            media_id: index === 0 ? "" : entry.media_id,
            total: entry.total,
            ...dateOrHourValues,
          };
        });

        setData(newData);
      } else {
        setData([]);
      }
    }, [tableData, start_date, end_date]);

    return (
      <>
        <ToastContainer />
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`first:rounded-l-xl last:rounded-r-xl px-2 py-4 whitespace-nowrap bg-gray_100_01 text-blue_gray_400 text-left ${
                      header.column.columnDef.className || ""
                    }`}
                  >
                    {header.column.columnDef.header}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? // Render skeleton rows
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index}>
                    {columns.map((col) => (
                      <td key={col.id}>
                        <Skeleton width={col.width || 100} height={30} />
                      </td>
                    ))}
                  </tr>
                ))
              : table.getRowModel().rows.map((row, rowIndex) => (
                  <React.Fragment key={row.id}>
                    {row.original.service && rowIndex !== 0 && (
                      <tr>
                        <td
                          colSpan={columns.length}
                          style={{
                            borderBottom: "1px solid #EBEBEB",
                            height: "4px",
                          }}
                        ></td>
                      </tr>
                    )}
                    <tr className={rowIndex === 0 ? "bg-blue-100" : ""}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={`px-2 py-2 whitespace-nowrap ${
                            cell.column.columnDef.className || ""
                          } ${
                            rowIndex === 0 && cell.column.id === "service"
                              ? "font-bold"
                              : ""
                          }`}
                        >
                          {cell.column.id === "total" ||
                          dynamicColumns
                            .map((col) => col.accessorKey)
                            .includes(cell.column.id)
                            ? Number(cell.getValue()).toLocaleString("de-DE")
                            : cell.getValue()}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
          </tbody>
        </table>
      </>
    );
  }
);

export default TableReportPembelian;
