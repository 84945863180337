import React, { useState } from "react";
import { Text, Img, Button } from "components";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
const ReactTable = ({
  columns,
  data = [],
  rowClass = "",
  headerClass = "",
  enableSorting = false,
  headerProps = {},
  bodyProps = {},
  rowDataProps = {},
}) => {
  const [sorting, setSorting] = useState([]);
  const tableConfig = {
    columns,
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  };
  if (enableSorting) {
    tableConfig["getSortedRowModel"] = getSortedRowModel();
  }
  const table = useReactTable(tableConfig);
  // table.setPageSize(10);
  // Render the UI for your table
  return (
    <>
      <div className="overflow-auto w-full">
        <table
          {...{
            style: {
              // width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead {...headerProps}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className={headerClass} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "flex gap-2 items-center text-blue_gray_400"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ⥣",
                          desc: " ⥥",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...bodyProps}>
            {table.getRowModel().rows.map((row) => (
              <tr className={rowClass} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex sm:flex-col flex-row gap-10 items-center justify-start w-full">
        <Button
          className="cursor-pointer flex items-center justify-center min-w-[161px] w-auto"
          shape="RoundedBorder4"
          size="sm"
          variant={
            !table.getCanPreviousPage() ? "OutlineGray100_1" : "OutlineGray100"
          }
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          leftIcon={
            !table.getCanPreviousPage() ? (
              <Img
                src={process.env.PUBLIC_URL + "/images/img_arrowleft_gray.svg"}
                className="mr-2.5"
                alt="material-symbols:add-box-outline-rounded"
              />
            ) : (
              <Img
                src={process.env.PUBLIC_URL + "/images/img_arrowleft.svg"}
                className="mr-2.5"
                alt="material-symbols:add-box-outline-rounded"
              />
            )
          }
        >
          Sebelum
        </Button>
        <Text
          className="flex-1 font-normal font-poppins not-italic text-center text-gray_400 tracking-[0.16px] w-auto"
          as="h5"
          variant="h5"
        >
          Page {table.getState().pagination.pageIndex + 1} of{" "}
          {table.getPageCount()}
        </Text>
        <Button
          className="cursor-pointer flex items-center justify-center min-w-[161px] w-auto"
          shape="RoundedBorder4"
          size="sm"
          variant={
            !table.getCanNextPage() ? "OutlineGray100_1" : "OutlineGray100"
          }
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          rightIcon={
            !table.getCanNextPage() ? (
              <Img
                src={process.env.PUBLIC_URL + "/images/img_arrowright_gray.svg"}
                className="ml-2.5"
                alt="material-symbols:add-box-outline-rounded"
              />
            ) : (
              <Img
                src={process.env.PUBLIC_URL + "/images/img_arrowright.svg"}
                className="ml-2.5"
                alt="material-symbols:add-box-outline-rounded"
              />
            )
          }
        >
          Berikutnya
        </Button>
      </div>
      {/* <div>{table.getRowModel().rows.length} Rows</div>
      <pre>{JSON.stringify(sorting, null, 2)}</pre> */}
    </>
  );
};

export { ReactTable };
