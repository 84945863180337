import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const premiumRevenue = createAsyncThunk(
  "premiumRevenue/premiumRevenue",
  async ({ uid }) => {
    /// ******** API premium_revenue_per_day ********
    const response = await axios.post(`${baseUrl}premium_revenue_per_day`, {
      uid: uid,
    });
    return response.data.response;
  }
);

const changePasswordEntity = createEntityAdapter({
  selectId: (premiumRevenue) => premiumRevenue.id,
});

const changePasswordSlice = createSlice({
  name: "premiumRevenue",
  initialState: changePasswordEntity.getInitialState(),
  extraReducers: {
    [premiumRevenue.pending]: (state) => {
      // console.log('pending')
    },
    [premiumRevenue.fulfilled]: (state, action) => {
      changePasswordEntity.setAll(state, action.payload);
    },
    [premiumRevenue.rejected]: (state) => {
      // console.log("rejected");
    },
  },
});

export const changePasswordSelector = changePasswordEntity.getSelectors(
  (state) => state.premiumRevenue
);
export default changePasswordSlice.reducer;
