import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getInformation = createAsyncThunk(
  "getInformation/getInformation",
  async () => {
    /// ******** API Forgot Password ********
    const response = await axios.get(`${baseUrl}get_information`);
    // const response = await axios.get(`http://10.10.2.144:8081/api/get_information`)
    return response.data.response;
  }
);

const getInformationEntity = createEntityAdapter({
  selectId: (getInformation) => getInformation.id,
});

const getInformationSlice = createSlice({
  name: "getInformation",
  initialState: getInformationEntity.getInitialState(),
  // Next Task, change extraReducers notation to this for 48 files
  // extraReducers: (builder) => {
  //     builder
  //         .addCase()
  // }
  extraReducers: {
    [getInformation.pending]: (state) => {
      // console.log("pending");
    },
    [getInformation.fulfilled]: (state, action) => {
      getInformationEntity.setAll(state, action.payload);
    },
    [getInformation.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getInformationSelector = getInformationEntity.getSelectors(
  (state) => state.getInformation
);
export default getInformationSlice.reducer;
