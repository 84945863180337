import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Text } from "components";

ChartJS.register(ArcElement, Tooltip);

const DonutChart = ({
  labelData,
  donutData,
  totalTransaksi3ProdukTrending,
}) => {
  const reversedLabelData = [...labelData].reverse();
  const reversedDonutData = [...donutData].reverse();

  console.log("reversedDonutData", reversedDonutData);

  const data = {
    labels: reversedLabelData,
    datasets: [
      {
        label: "Transaksi",
        data: reversedDonutData,
        backgroundColor: [
          "rgba(245,180,63,1)",
          "rgba(144,56,120,1)",
          "rgba(228,68,127,1)",
        ],
        borderColor: [
          "rgba(245,180,63, 1)",
          "rgba(144,56,120, 1)",
          "rgba(228,68,127, 1)",
        ],
        rotation: 270,
        circumference: 180,
        cutout: "80%",
        responsive: true,
        borderRadius: 100,
        spacing: 10,
      },
    ],
  };

  const options = {
    responsive: true,
  };
  return (
    <>
      <div className="relative">
        <Doughnut data={data} options={options} />
        <div className="absolute top-1/2 left-1/4 w-1/2 text-center flex flex-col">
          <Text
            className="font-semibold text-gray_800 text-center w-auto"
            as="h4"
            variant="h4"
          >
            {totalTransaksi3ProdukTrending}
          </Text>
          <span>Total Transaksi</span>
        </div>
      </div>
    </>
  );
};

export default DonutChart;
