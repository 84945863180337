import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit"
import axios from "axios"
import {baseUrl} from '../../../app/constants'


export const cooperationStatus = createAsyncThunk("cooperationStatus/cooperationStatus", async({uid})=>{
    /// ******** API Cooperation Status ********
    const response = await axios.post(`${baseUrl}cooperation`, {
        uid: uid,
    })
    return response.data.response;
})

const getProductEntity = createEntityAdapter({
    selectId: (cooperationStatus) => cooperationStatus.id
})

const getProductSlice = createSlice({
    name: "cooperationStatus",
    initialState: 
    getProductEntity.getInitialState(),
    extraReducers:{
        [cooperationStatus.pending]: (state) => {
            console.log('pending')
        },
        [cooperationStatus.fulfilled]: (state, action) => {
            getProductEntity.setAll(state, action.payload);
        },
        [cooperationStatus.rejected]: (state) => {
            console.log('rejected')
        },
    }
})

export const getProductSelector = getProductEntity.getSelectors(state => state.cooperationStatus)
export default getProductSlice.reducer;