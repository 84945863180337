import CryptoJS from "crypto-js";

// Encode data with Base64
export const encodeBase64 = (data) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
};

// Decode data from Base64
export const decodeBase64 = (data) => {
  const bytes = CryptoJS.enc.Base64.parse(data);
  return CryptoJS.enc.Utf8.stringify(bytes);
};
