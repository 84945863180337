import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getInboxes = createAsyncThunk(
  "getInboxes/getInboxes",
  async ({ uid }) => {
    /// ******** API inboxes ********
    const response = await axios.post(`${baseUrl}inboxes`, {
      uid: uid,
    });
    return response.data.response;
  }
);

const getInboxesEntity = createEntityAdapter({
  selectId: (getInboxes) => getInboxes.id,
});

const getInboxesSlice = createSlice({
  name: "getInboxes",
  initialState: getInboxesEntity.getInitialState(),
  extraReducers: {
    [getInboxes.pending]: (state) => {
      // console.log('pending')
    },
    [getInboxes.fulfilled]: (state, action) => {
      getInboxesEntity.setAll(state, action.payload);
    },
    [getInboxes.rejected]: (state) => {
      console.log("rejected");
    },
  },
});

export const getInboxesSelector = getInboxesEntity.getSelectors(
  (state) => state.getInboxes
);
export default getInboxesSlice.reducer;
