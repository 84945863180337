import { Menu } from "@headlessui/react";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseTsel } from "app/constants";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";

const HeaderNavDropDown = () => {
  // const users = useSelector((state) => state.userSignIn);
  const users = useSelector(userSignInSelector);
  const levelId = users.idLevel;

  const handleLogout = () => {
    console.log("object");
    localStorage.removeItem("user");
    localStorage.clear();
  };

  return (
    <>
      <Menu as="div" className="relative font-poppins text-sm z-10">
        <Menu.Button
          as="button"
          className="p-2 bg-white rounded-full drop-shadow"
        >
          <BiChevronDown />
        </Menu.Button>

        {levelId === 1 || levelId === 14 ? (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to={`/${baseTsel}`} onClick={handleLogout}>
                Keluar
              </Link>
            </Menu.Item>
          </Menu.Items>
        ) : (
          <Menu.Items
            as="div"
            className="absolute right-0 mt-2 p-4 w-[200px] rounded-[10px] bg-white drop-shadow flex flex-col gap-4"
          >
            <Menu.Item as="div">
              <Link to="akun">Akun Saya</Link>
            </Menu.Item>
            <Menu.Item as="div">
              <Link to="status">Status</Link>
            </Menu.Item>
            <Menu.Item as="div" className="border"></Menu.Item>
            <Menu.Item as="div">
              <Link to={`/${baseTsel}/tnc`}>Syarat & Ketentuan</Link>
            </Menu.Item>
            <Menu.Item as="div">
              <Link to={`/${baseTsel}/policy`}>Kebijakan Privasi</Link>
            </Menu.Item>
            <Menu.Item as="div" className="border"></Menu.Item>
            <Menu.Item as="div">
              <Link to="/" onClick={handleLogout}>
                Keluar
              </Link>
            </Menu.Item>
          </Menu.Items>
        )}
      </Menu>
    </>
  );
};

export default HeaderNavDropDown;
