import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const lastTransact = createAsyncThunk(
  "lastTransact/lastTransact",
  async ({ uid }) => {
    /// ******** API oncetime_revenue_per_day ********
    const response = await axios.post(`${baseUrl}last_transaction`, {
      uid: uid,
    });
    return response.data.response;
  }
);

const changePasswordEntity = createEntityAdapter({
  selectId: (lastTransact) => lastTransact.id,
});

const changePasswordSlice = createSlice({
  name: "lastTransact",
  initialState: changePasswordEntity.getInitialState(),
  extraReducers: {
    [lastTransact.pending]: (state) => {
      // console.log('pending')
    },
    [lastTransact.fulfilled]: (state, action) => {
      changePasswordEntity.setAll(state, action.payload);
    },
    [lastTransact.rejected]: (state) => {
      // console.log("rejected");
    },
  },
});

export const changePasswordSelector = changePasswordEntity.getSelectors(
  (state) => state.lastTransact
);
export default changePasswordSlice.reducer;
