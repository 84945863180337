/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Text, Input, Img } from "components";
import { cooperationStatus } from "./Features/cooperationSlice";
import { BASE_PATH_IMAGE } from "app/constants";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";

const StatusactivepagePage = () => {

  const [cooperation, setCooperation] = useState([]);
  const users = useSelector(userSignInSelector);
  // const users = useSelector((state) => state.userSignIn);
  const id = users.id || "";


    /// Cooperation Status
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cooperationStatus({ uid: id }))
      .then(unwrapResult)
      .then((obj) => {
       setCooperation(obj)
      })
      .catch((obj) => {
        alert(obj.message);
      });
  }, []);


  return (
    <>
      <div className="flex md:flex-col flex-row md:gap-5 items-start justify-evenly w-full">
        <div className="flex flex-1 flex-col font-proximanova items-center justify-start md:px-5 w-full">
          <div className="flex flex-col font-poppins gap-6 items-start justify-start max-w-[1232px] p-8 sm:px-5 w-full">
            <Text
              className="text-gray_800 text-left w-auto"
              as="h1"
              variant="h1"
            >
              Status Kerjasama
            </Text>
            <div className="bg-white_A700 flex flex-col items-start justify-center p-[26px] sm:px-5 rounded-[16px] w-full">
              <div className="flex flex-col gap-6 items-start justify-start w-full">
                <div className="flex flex-row gap-2.5 items-center justify-start w-[548px] sm:w-full">
                  <Text
                    className="font-medium text-gray_800 text-left w-auto"
                    as="h5"
                    variant="h5"
                  >
                    Status
                  </Text>
                  <div className="bg-green_50 border border-light_green_A700 border-solid flex flex-row gap-2 items-center justify-start px-3 py-1.5 rounded-md self-stretch w-auto">
                    <div className="bg-light_green_A700 h-3 rounded-[50%] w-3"></div>
                    <Text
                      className="font-medium text-gray_800 text-left tracking-[0.14px] w-auto"
                      variant="body1"
                    >
                      {cooperation.status}
                    </Text>
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
                  <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                    <Text
                      className="font-medium text-gray_800 text-left w-auto"
                      as="h5"
                      variant="h5"
                    >
                      Nama Perusahaan
                    </Text>
                    <Input
                      wrapClassName="flex w-full"
                      className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                      name="frame628247"
                      placeholder={cooperation.company_name}
                      prefix={
                        <Img
                          src={BASE_PATH_IMAGE+"img_heroiconsbuildingoffice2solid.svg"}
                          className="mt-auto mb-px mr-2.5"
                          alt="heroicons:building-office-2-solid"
                        />
                      }
                      shape="RoundedBorder10"
                      size="md"
                      variant="OutlineGray400"
                    ></Input>
                  </div>
                  <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                    <Text
                      className="font-medium text-gray_800 text-left w-auto"
                      as="h5"
                      variant="h5"
                    >
                      Nama Direktur
                    </Text>
                    <Input
                      wrapClassName="flex w-full"
                      className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                      name="frame628247_One"
                      placeholder={cooperation.director_first_name +" "+cooperation.director_last_name}
                      prefix={
                        <Img
                          src={BASE_PATH_IMAGE+"img_iconparkoutlineeditname.svg"}
                          className="mt-px mb-auto mr-2.5"
                          alt="icon-park-outline:edit-name"
                        />
                      }
                      shape="RoundedBorder10"
                      size="md"
                      variant="OutlineGray400"
                    ></Input>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-medium text-gray_800 text-left w-auto"
                    as="h5"
                    variant="h5"
                  >
                    Alamat Perusahaan
                  </Text>
                  <Input
                    wrapClassName="flex w-full"
                    className="font-normal leading-[normal] md:h-auto not-italic p-0 placeholder:text-gray_800 sm:h-auto text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                    name="frame628247_Two"
                    placeholder={cooperation.address +", "+cooperation.city +", "+cooperation.province+", "+cooperation.postal_code}
                    prefix={
                      <Img
                        src={BASE_PATH_IMAGE+"img_carbonlocationcompanyfilled.svg"}
                        className="mt-px mb-auto mr-2.5"
                        alt="carbon:location-company-filled"
                      />
                    }
                    shape="RoundedBorder10"
                    size="lg"
                    variant="OutlineGray400"
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-[548px] sm:w-full">
                  <Text
                    className="font-medium text-gray_800 text-left w-auto"
                    as="h5"
                    variant="h5"
                  >
                    Tanggal Kerjasama
                  </Text>
                  <Text
                    className="font-normal not-italic text-left text-red_500 tracking-[0.14px] w-auto"
                    variant="body1"
                  >
                    {cooperation.created_at} s.d. {cooperation.until_date}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusactivepagePage;
