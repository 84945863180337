import React from "react";

const variantClasses = {
  h1: "font-semibold text-3xl sm:text-[26px] md:text-[28px]",
  h2: "font-semibold text-2xl md:text-[22px] sm:text-xl",
  h3: "font-semibold text-xl",
  h4: "text-lg",
  h5: "text-base",
  h6: "text-[15px]",
  body1: "text-sm",
  body2: "text-xs",
  body3: "font-normal text-[10px]",
};

const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variant && variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
