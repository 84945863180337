import { Text, Input, Img } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPic } from "../Features/getPicSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { useSelector } from "react-redux";
import { BASE_PATH_IMAGE } from "app/constants";

export const ContactDetailComponents = (props) => {
  const dispatch = useDispatch();
  const [pic, setPic] = useState([]);
  const users = useSelector(userSignInSelector);

  // Get PIC
  useEffect(() => {
    if (users) {
      let uuid = users.id;
      // console.log(uuid);
      dispatch(getPic({ uid: uuid }))
        .then(unwrapResult)
        .then((obj) => {
          const pics = obj;
          // console.log(pics);
          setPic(pics);
        })
        .catch((obj) => {
          alert(obj.message);
          // console.log(obj);
        });
    }
  }, [dispatch, users]);

  // ================================
  return (
    <>
      <div className="flex flex-col gap-6 items-start justify-start w-full">
        {/* Menu Edit PIC */}
        <div
          className="cursor-pointer flex flex-row gap-2.5 items-center justify-end self-stretch w-auto"
          onClick={(e) => {
            e.preventDefault();
            props?.onClickEdit();
          }}
        >
          <Img
            src={
              process.env.PUBLIC_URL +
              "/images/img_materialsymbolseditrounded.svg"
            }
            className="h-6 w-6"
            alt="materialsymbols"
          />
          <Text
            className="font-semibold text-blue_900 text-left w-auto"
            as="h4"
            variant="h4"
          >
            Ubah Detail Kontak
          </Text>
        </div>
        {/* Detail PIC */}
        {pic.map((dataPic) => (
          <>
            <div className="flex flex-row md:gap-10 items-center justify-between w-full">
              <Text
                className="flex-1 font-semibold text-gray_800 text-left w-auto"
                as="h4"
                variant="h4"
              >
                PIC {dataPic.pic_category_name}
              </Text>
            </div>
            <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nama Depan
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                  name="frame628247"
                  placeholder={dataPic?.first_name ?? ""}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_iconparkoutlineeditname.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="icon-park-outline:edit-name"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                ></Input>
              </div>
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nama Belakang
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                  name="frame628247_One"
                  placeholder={dataPic?.last_name ?? ""}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_iconparkoutlineeditname.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="icon-park-outline:edit-name"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                ></Input>
              </div>
            </div>
            <div className="flex md:flex-col flex-row gap-5 items-start justify-start w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Email
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                  type="email"
                  name="inputEmail"
                  placeholder={dataPic?.email ?? ""}
                  prefix={
                    <Img
                      src={process.env.PUBLIC_URL + "/images/img_mail.svg"}
                      className="mt-auto mb-px mr-2.5"
                      alt="mail"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                ></Input>
              </div>
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  Nomor Telepon/Handphone
                </Text>
                <Input
                  wrapClassName="flex w-full"
                  className="font-normal leading-[normal] not-italic p-0 placeholder:text-gray_800 text-gray_800 text-left text-sm tracking-[0.14px] w-full"
                  // type="number"
                  name="noTelepon"
                  placeholder={dataPic?.phone_number ?? ""}
                  prefix={
                    <Img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/img_solarphonebold.svg"
                      }
                      className="mt-px mb-auto mr-2.5"
                      alt="solar:phone-bold"
                    />
                  }
                  shape="RoundedBorder10"
                  size="md"
                  variant="OutlineGray400"
                ></Input>
              </div>
            </div>
          </>
        ))}

        {pic.length === 0 && (
          <div className="text-center w-full">
            <p>data not found</p>
          </div>
        )}
        {/* ======================= */}
      </div>
    </>
  );
};
export default ContactDetailComponents;
