import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./constants";

export const dpsApi = createApi({
  reducerPath: "dpsApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getMessages: builder.mutation({
      query: (data) => ({
        url: `inboxes`,
        method: "POST",
        body: data,
      }),
      transformResponse: (res) => res.response,
      providesTags: ["Inbox"],
      invalidatesTags: ["Inbox"],
    }),
    updateMessage: builder.mutation({
      query: (data) => ({
        url: `update_inboxes`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Inbox"],
      invalidatesTags: ["Inbox"],
    }),
    getReportDR: builder.mutation({
      query: (data) => ({
        url: `v2/report_dr_status`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
      invalidatesTags: ["Report"],
    }),
    getReportRecap: builder.mutation({
      query: (data) => ({
        url: `v2/report_recap`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
      invalidatesTags: ["Report"],
    }),
    getReportDetail: builder.mutation({
      query: (data) => ({
        url: `v2/rpt_trx_detail`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Report"],
    }),
    getReportPurchase: builder.mutation({
      query: (data) => ({
        url: `v2/report_purchase`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
      invalidatesTags: ["Report"],
    }),
    getReportRecapIncome: builder.mutation({
      query: (data) => ({
        url: `v2/report_data_recap`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Report"],
      invalidatesTags: ["Report"],
    }),
    getKeyword: builder.query({
      query: ({ uid }) => `v2/get_keyword?uid=${uid}`,
    }),
    getPartner: builder.query({
      query: () => `/partner_list`,
    }),
    getAdnets: builder.query({
      query: () => `/v2/adnet_list`,
      transformResponse: (response) => response.data,
    }),
  }),
  tagTypes: ["Inbox", "Report"],
});

export const {
  useGetMessagesMutation,
  useUpdateMessageMutation,
  useGetReportDRMutation,
  useGetReportRecapMutation,
  useGetReportPurchaseMutation,
  useGetReportRecapIncomeMutation,
  useGetReportDetailMutation,
  useGetKeywordQuery,
  useGetPartnerQuery,
  useGetAdnetsQuery,
} = dpsApi;
