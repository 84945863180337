/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from "react";
import { Text, SelectBox, Img, Input, Button, List } from "components";
import ListProductTable from "./Components/list_product_table";
import { CloseSVG } from "../../assets/images";
import { getProduct } from "./Features/productSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
// import { data } from "autoprefixer";

const ProductPage = ({ someProp, cickCreateProduct, clickDetail }) => {
  const [inputvalue, setInputval] = React.useState("");
  const [products, setProducts] = useState([]);
  const [datas, setData] = useState([]);
  const navigate = useNavigate();
  const users = useSelector(userSignInSelector);
  // const users = useSelector((state) => state.userSignIn);
  const levelId = users?.idLevel ?? 0;
  const [selectvalue, setSelectval] = React.useState(
    levelId === 14 ? "pending_telco" : "pending"
  );

  const clearString = (str) => str.replace(/\s/g, "").toLowerCase();

  const setInputvalue = (event) => {
    setInputval(event);
    const data = {
      nodes: products.filter(
        (item) =>
          (item.service_name.toLowerCase().includes(event.toLowerCase()) ||
            item.app_id.toLowerCase().includes(event.toLowerCase())) &&
          clearString(item.status_name.toLowerCase()) === selectvalue
      ),
    };
    setData(data.nodes);
  };

  const setListData = () => {
    if (users) {
      let uuid = users.id;
      dispatch(getProduct({ uid: uuid }))
        .then(unwrapResult)
        .then((obj) => {
          // console.log(obj);
          setProducts(obj);
          const data = {
            nodes: obj.filter(
              (item) =>
                clearString(item.status_name.toLowerCase()) === selectvalue
            ),
          };
          setData(data.nodes);
        })
        .catch((obj) => {
          toast.error(obj.message, {
            position: "top-center",
            autoClose: false,
            theme: "colored",
          });
        });
    }
  };

  // ======= State Get Product =======
  const dispatch = useDispatch();

  // Get Product
  useEffect(() => {
    setListData();
  }, []);

  function detailClickHandler() {
    clickDetail();
  }

  // ================================

  const selectOptions = [
    { value: "active", label: "Aktif" },
    { value: "pending", label: "Dalam Proses DPA" },
    { value: "pending_telco", label: "Dalam Proses Telkomsel" },
    { value: "ditolak", label: "Ditolak" },
    { value: "block", label: "Berhenti" },
    { value: "nonactive", label: "Tidak Aktif" },
  ];

  const selectOptionsDPA = [
    { value: "active", label: "Aktif" },
    { value: "pending", label: "Dalam Proses DPA" },
    { value: "pending_telco", label: "Dalam Proses Telkomsel" },
    { value: "ditolak", label: "Ditolak" },
    { value: "block", label: "Berhenti" },
    { value: "nonactive", label: "Tidak Aktif" },
  ];

  const selectOptionsTelco = [
    { value: "active", label: "Aktif" },
    { value: "pending_telco", label: "Dalam Proses" },
    { value: "ditolak", label: "Ditolak" },
    { value: "block", label: "Berhenti" },
    { value: "nonactive", label: "Tidak Aktif" },
  ];

  const onChangeHand = (value) => {
    setSelectval(value);
    const data = {
      nodes: products.filter(
        (item) =>
          item.service_name.toLowerCase().includes(inputvalue.toLowerCase()) &&
          clearString(item.status_name.toLowerCase()) === value
      ),
    };
    // console.log(data.nodes, "value");
    setData(data.nodes);
  };

  return (
    <>
      <ToastContainer />
      <Suspense fallback={<>Loading ...</>}>
        <div className="flex flex-col font-poppins gap-6 items-start justify-start p-8 max-w-full sm:px-5 w-full">
          <Text className="text-gray_800 text-left w-auto" as="h1" variant="h1">
            Daftar Produk
          </Text>
          <div className="flex md:flex-col flex-row gap-6 items-center justify-start w-full">
            <div className="flex flex-1 sm:flex-col flex-row gap-5 items-start justify-start w-full">
              <SelectBox
                value={selectvalue}
                className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm min-w-[158px] sm:w-full"
                placeholderClassName="text-gray_800"
                name="frame628297"
                placeholder={
                  levelId === 1
                    ? "Dalam Proses DPA"
                    : levelId === 14
                    ? "Dalam Proses"
                    : "Dalam Proses DPA"
                }
                defaultValue="pending"
                isSearchable={true}
                isMulti={false}
                options={
                  levelId === 1
                    ? selectOptionsDPA
                    : levelId === 14
                    ? selectOptionsTelco
                    : selectOptions
                }
                indicator={
                  <Img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/img_arrowdown_gray_800.svg"
                    }
                    className="h-6 w-6"
                    alt="arrow_down"
                  />
                }
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineGray200"
                onChange={onChangeHand}
              ></SelectBox>
              <Input
                value={inputvalue}
                onChange={(e) => setInputvalue(e)}
                wrapClassName="flex sm:flex-1 gap-2.5 w-[31%] sm:w-full"
                className="font-normal leading-[normal] not-italic p-0 placeholder:text-blue_gray_400 text-blue_gray_400 text-left text-sm tracking-[0.5px] w-full"
                name="frame628299"
                placeholder="Cari..."
                prefix={
                  <Img
                    src={process.env.PUBLIC_URL + "/images/img_search.svg"}
                    className="cursor-pointer mr-2.5 my-auto"
                    alt="search"
                  />
                }
                suffix={
                  <CloseSVG
                    fillColor="#8c8c8c"
                    className="cursor-pointer my-auto"
                    onClick={() => setInputvalue("")}
                    style={{
                      visibility:
                        inputvalue?.length <= 0 ? "hidden" : "visible",
                    }}
                    height={24}
                    width={24}
                    viewBox="0 0 24 24"
                  />
                }
                shape="srcRoundedBorder6"
                size="smSrc"
                variant="srcOutlineGray200"
              ></Input>
            </div>
            {((levelId === 2) && (
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[161px] w-auto"
              leftIcon={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_materialsymbolsaddboxoutlinerounded.svg"
                  }
                  className="mr-2.5"
                  alt="material-symbols:add-box-outline-rounded"
                />
              }
              shape="RoundedBorder8"
              size="md"
              variant="OutlineGray200"
              onClick={() => navigate(`create`)}
            >
              <div className="font-semibold leading-[normal] text-left text-sm text-white_A700">
                Buat Produk
              </div>
            </Button>
          ))}

          </div>
          <div className="bg-white_A700 flex flex-col gap-[26] items-start justify-start p-8 sm:px-5 w-full">
            <ListProductTable
              datas={datas}
              detailHandler={detailClickHandler}
            />
          </div>
        </div>
      </Suspense>
    </>
  );

  // ================================
};

export default ProductPage;
