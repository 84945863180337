// export const baseUrl = "http://localhost:8181/api/v1/"; /// Local
export const baseUrl = "https://staging.ina17.com/api/v1/"; /// Staging
// export const baseUrl = "https://api.digipac.id/"; /// Production

// PAYMENT TEXT
export const PAYMENT_TEXT = "Pembayaran 1x";
// export const urlService = "http://localhost:3000/tsel/";
export const urlService = "https://staging.digipac.id/tsel/";
// export const urlService = "https://digipac.id/tsel/";
// export const basePath = '/dps-dev-staging/'

// BASE PATH
export const basePath = "pages/";
export const basePathVrbt = "pages/vrbt/";
export const baseTsel = "tsel";
export const baseVrbt = "/cms-vrbt/"; /// staging
// export const baseVrbt = "/"; /// production
export const BASE_PATH_IMAGE = process.env.PUBLIC_URL + "/images/";

/// export report
export const report_value = [
  { id: 0, value: "Export Report" },
  { id: 1, value: "Excel" },
  { id: 2, value: "PDF" },
];

/// status content
export const status_content = [
  {
    id: 0,
    value: "Waiting for Admin",
  },
  { id: 1, value: "Waiting for Telco" },
  { id: 2, value: "Active" },
  { id: 3, value: "Inactive" },
  { id: 4, value: "Rejected" },
  { id: 5, value: "Stopped" },
];

// export const BASE_PATH_IMAGE = "https://staging.ina17.com/dcb-lp/image/";
// export const basePath = '/dps-dev-staging/'
