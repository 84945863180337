/* eslint-disable eqeqeq */
import { Text, Line } from "components";
import React, {useState} from "react";

import CompanyDetailComponents from "./Components/company_detail_components";
import BankAccountComponents from "./Components/bank_account_components";
import ContactDetailComponents from "./Components/contact_detail_components";
import ContactDetailEditComponents from "./Components/contact_detail_edit_components";

const MyaccountcompanydetailpagePage = () => {
  const [statusRoute, setStatusRoute] = useState("company");

  function companyCallBack() {
    setStatusRoute("company")
  }

  function accountCallBack() {
    setStatusRoute("account")
  }

  function contactCallBack() {
    setStatusRoute("contact")
  }

  function contactEditCallBack() {
    setStatusRoute("edit_contact")
  }


  return (
    <>
      <div className="flex md:flex-col flex-row md:gap-5 items-center justify-evenly w-full">
          <div className="flex flex-1 flex-col font-proximanova items-center justify-start md:px-5 w-full">
            <div className="flex flex-col font-poppins gap-6 items-start justify-start max-w-[1232px] p-8 sm:px-5 w-full">
              <Text
                className="text-gray_800 text-left w-auto"
                as="h1"
                variant="h1"
              >
                Akun Saya
              </Text>
              <div className="bg-white_A700 flex flex-col gap-6 items-start justify-center p-[26px] sm:px-5 rounded-[16px] w-full">
                <div className="flex sm:flex-col flex-row gap-6 items-start justify-start self-stretch w-auto sm:w-full">
                  {/* ===================== */}
                  {/* Detail Perusahaan */}
                  <div className="cursor-pointer flex flex-col gap-2.5 items-start justify-start self-stretch w-auto"
                    onClick={companyCallBack}
                  >
                   {
                    statusRoute == "company" ?
                    <div>
                      <Text
                        className="font-semibold text-gray_800 text-left w-auto" as="h4"variant="h4">
                        Detail Perusahaan
                      </Text>
                      <Line className="bg-blue_gray_300 h-[3px] w-full" />
                    </div>
                    :
                    <Text
                    className="font-medium text-gray_400 text-left w-auto" as="h4" variant="h4">
                      Detail Perusahaan
                    </Text>
                   }
                  </div>
                  {/* account Bank */}
                  <div className="cursor-pointer flex flex-col gap-2.5 items-start justify-start self-stretch w-auto"
                    onClick={accountCallBack}
                  >
                    {
                      statusRoute == "account" ?
                      <div>
                        <Text
                          className="font-semibold text-gray_800 text-left w-auto" as="h4"variant="h4">
                          Rekening Bank
                        </Text>
                        <Line className="bg-blue_gray_300 h-[3px] w-full" />
                      </div>
                      :
                      <Text
                      className="font-medium text-gray_400 text-left w-auto" as="h4" variant="h4">
                        Rekening Bank
                      </Text>
                    }
                  </div>
                  {/* Detail Kontak */}
                  <div className="cursor-pointer flex flex-col gap-2.5 items-start justify-start self-stretch w-auto"
                    onClick={contactCallBack}
                  >
                    {
                      statusRoute == "contact" ?
                      <div>
                        <Text
                          className="font-semibold text-gray_800 text-left w-auto" as="h4"variant="h4">
                          Detail Kontak
                        </Text>
                        <Line className="bg-blue_gray_300 h-[3px] w-full" />
                      </div>
                      :
                      <Text
                      className="font-medium text-gray_400 text-left w-auto" as="h4" variant="h4">
                        Detail Kontak
                      </Text>
                    }
                  </div>
                  {/* ===================== */}
                </div>

                {
                  statusRoute == "company"
                  ? <CompanyDetailComponents/>
                  : statusRoute == "account"
                  ? <BankAccountComponents/>
                  : statusRoute == "contact"
                  ? <ContactDetailComponents
                    onClickEdit={contactEditCallBack}
                  />
                  :<ContactDetailEditComponents
                  onClickBack={contactCallBack}
                  />
                }

                {/* ===================== */}
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default MyaccountcompanydetailpagePage;
