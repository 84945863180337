/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import MessageList from "./Components/MessageList";
import { Route, Routes } from "react-router-dom";
import MessageContent from "./Components/MessageContent";
import { getInboxes } from "./Features/inboxSlice";
import { useSelector } from "react-redux";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";

const InboxpagePage = () => {
  const users = useSelector(userSignInSelector);
  const id = users.id;
  const [inboxData, setInboxData] = useState([]);
  const dispatch = useDispatch();
  const countMessage = useSelector((state) => state.messageNotification.count);
  /// Get API Inboxes
  useEffect(() => {
    dispatch(getInboxes({ uid: id }))
      .then(unwrapResult)
      .then((obj) => {
        // console.log({ obj });
        setInboxData(obj);
        localStorage.setItem("messageCount", obj.length);
      })
      .catch((obj) => {
        alert(obj.message);
      });
  }, [countMessage]);

  useEffect(() => {
    dispatch(getInboxes({ uid: id }))
      .then(unwrapResult)
      .then((obj) => {
        // console.log({ obj });
        setInboxData(obj);
        localStorage.setItem("messageCount", obj.length);
      })
      .catch((obj) => {
        alert(obj.message);
      });
  }, []);


  return (
    <>
      {inboxData?.length > 0 && <div className="flex font-poppins w-full min-h-[calc(100vh-83px)]">
        <MessageList messages={inboxData} />
        <div className="grow flex items-center justify-center">
          <Routes>
            <Route
              path=":messageId"
              element={<MessageContent messages={inboxData} />}
            />
            <Route index element={<div>Pilih pesan apa pun dalam daftar untuk melihatnya di sini.</div>} />
          </Routes>
        </div>
      </div>}
    </>
  );
};

export default InboxpagePage;
