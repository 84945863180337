import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../../../app/constants";

export const getProduct = createAsyncThunk(
  "getProduct/getProduct",
  async ({ uid }) => {
    /// ******** API Get Product ********
    const response = await axios.post(`${baseUrl}get_service`, {
      uid: uid,
    });
    return response.data.response;
  }
);

const getProductEntity = createEntityAdapter({
  selectId: (getProduct) => getProduct.id,
});

const getProductSlice = createSlice({
  name: "getProduct",
  initialState: getProductEntity.getInitialState(),
  extraReducers: {
    [getProduct.pending]: (state) => {
      // console.log('pending')
    },
    [getProduct.fulfilled]: (state, action) => {
      getProductEntity.setAll(state, action.payload);
    },
    [getProduct.rejected]: (state) => {
      // console.log("rejected");
    },
  },
});

export const getProductSelector = getProductEntity.getSelectors(
  (state) => state.getProduct
);
export default getProductSlice.reducer;
