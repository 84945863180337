import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "pages/Home";
// import NotFound from "pages/NotFound";
import { basePath, basePathVrbt, baseTsel, baseVrbt } from "./app/constants";
import DashboardLayout from "pages/Dashboard/Layout";
import PageLayout from "pages/vrbt/PageLayout/";
import HomeDashboard from "pages/Dashboardpage/Components/home_dasboard";
import ProductPage from "pages/ProductPage";
import InboxpagePage from "pages/Inboxpage";
import PartnerPage from "pages/PartnerPage";
import PartnerDetailPage from "pages/PartnerDetailPage";
import MyaccountcompanydetailpagePage from "pages/Myaccountcompanydetailpage";
import StatusactivepagePage from "pages/Statusactivepage";
import HomeCreateProduct from "pages/Createproductpage/Components/RevampHomeCreateProduct";
import DetailproductactivepagePage from "pages/ProductDetailPage/RevampDetailProduk";
import ProductreporttotalactiveuserspagePage from "pages/Productreporttotalactiveuserspage";
import TncPage from "pages/TnC";
import PolicyPage from "pages/Policy";
// import SuccessPage from "pages/Dashboardpage/Components/Features/sucess_page";
import SuccessPage from "pages/SuccessPage";
import SelfCarePage from "pages/SelfCare";
import Faq from "pages/Faq";
import { AuthProvider } from "app/AuthContext";

const Inboxreadpage = React.lazy(() => import(basePath + "Inboxreadpage"));
const Inboxpage = React.lazy(() => import(basePath + "Inboxpage"));
const Productreportdrstatuspage = React.lazy(() =>
  import(basePath + "Productreportdrstatuspage")
);
const Productreportstatusmediapage = React.lazy(() =>
  import(basePath + "Productreportstatusmediapage")
);
const Productreportreportrecappage = React.lazy(() =>
  import(basePath + "Productreportreportrecappage")
);
const Productreporttotalactiveuserspage = React.lazy(() =>
  import(basePath + "Productreporttotalactiveuserspage")
);
const Productreportdetailpricepage = React.lazy(() =>
  import(basePath + "Productreportdetailpricepage")
);
const Productreportdetailproductpage = React.lazy(() =>
  import(basePath + "Productreportdetailproductpage")
);
const Productreportrequesttransactionpage = React.lazy(() =>
  import(basePath + "Productreportrequesttransactionpage")
);
const Productreportrevenuerecappage = React.lazy(() =>
  import(basePath + "Productreportrevenuerecappage")
);
const Extensiononepage = React.lazy(() =>
  import(basePath + "Extensiononepage")
);
const Extensionthreepage = React.lazy(() =>
  import(basePath + "Extensionthreepage")
);
const Extensiontwopage = React.lazy(() =>
  import(basePath + "Extensiontwopage")
);
const Statusnotactivepage = React.lazy(() =>
  import(basePath + "Statusnotactivepage")
);
const Statusactivepage = React.lazy(() =>
  import(basePath + "Statusactivepage")
);
const Myaccountcontactdetaileditpage = React.lazy(() =>
  import(basePath + "Myaccountcontactdetaileditpage")
);
const Myaccountcontactdetailpage = React.lazy(() =>
  import(basePath + "Myaccountcontactdetailpage")
);
const Myaccountbankaccountpage = React.lazy(() =>
  import(basePath + "Myaccountbankaccountpage")
);

const Myaccountcompanydetailpage = React.lazy(() =>
  import(basePath + "Myaccountcompanydetailpage")
);

const Detailproductstoppage = React.lazy(() =>
  import(basePath + "Detailproductstoppage")
);

const Listproductstoppage = React.lazy(() =>
  import(basePath + "Listproductstoppage")
);

const Detailproductpausepage = React.lazy(() =>
  import(basePath + "Detailproductpausepage")
);

const Listproductpausepage = React.lazy(() =>
  import(basePath + "Listproductpausepage")
);

const Detailproductrejectedpage = React.lazy(() =>
  import(basePath + "Detailproductrejectedpage")
);

const Listproductrejectedpage = React.lazy(() =>
  import(basePath + "Listproductrejectedpage")
);

const Detailproductactivepage = React.lazy(() =>
  import(basePath + "Detailproductactivepage")
);

const Listproductactivepage = React.lazy(() =>
  // import(basePath + "Listproductactivepage")
  import(basePath + "ProductPage")
);

const Detailprodcutwaitingtwopage = React.lazy(() =>
  import(basePath + "Detailprodcutwaitingtwopage")
);

const Detailprodcutwaitingonepage = React.lazy(() =>
  import(basePath + "Detailprodcutwaitingonepage")
);

const Listproductwaitingpage = React.lazy(() =>
  import(basePath + "Listproductwaitingpage")
);

const Createproductpage = React.lazy(() =>
  import(basePath + "Createproductpage")
);

const Dashboardhelppage = React.lazy(() =>
  import(basePath + "Dashboardhelppage")
);

const Dashboarddropdownpage = React.lazy(() =>
  import(basePath + "Dashboarddropdownpage")
);

const Forgotpasswordthreepage = React.lazy(() =>
  import(basePath + "Forgotpasswordthreepage")
);
const Forgotpasswordtwopage = React.lazy(() =>
  import(basePath + "Forgotpasswordtwopage")
);
const Forgotpasswordonepage = React.lazy(() =>
  import(basePath + "Forgotpasswordonepage")
);
const Signupthreepage = React.lazy(() => import(basePath + "Signupthreepage"));
const Signuptwopage = React.lazy(() => import(basePath + "Signuptwopage"));
const Signuponepage = React.lazy(() => import(basePath + "Signuponepage"));
const Signinpage = React.lazy(() => import(basePath + "Signinpage"));

// Campign Page
const CreateIklan = React.lazy(() =>
  import(basePath + "DashboardCampign/CreateIklan/CreateIklan.jsx")
);
const ListIklan = React.lazy(() =>
  import(basePath + "DashboardCampign/ListIklan/ListIklan.jsx")
);
const DetailIklan = React.lazy(() =>
  import(basePath + "DashboardCampign/ListIklan/DetailIklan.jsx")
);
const TopUp = React.lazy(() =>
  import(basePath + "DashboardCampign/TopUp/TopUp.jsx")
);
const TopUpPembayaran = React.lazy(() =>
  import(basePath + "DashboardCampign/TopUp/TopUpPembayaran.jsx")
);
const TopUpInvoice = React.lazy(() =>
  import(basePath + "DashboardCampign/TopUp/TopUpInvoice.jsx")
);

///// VRBT CMS
const SignIn = React.lazy(() => import(basePathVrbt + "SignInPage/"));
const Dashboard = React.lazy(() => import(basePathVrbt + "Dashboard/"));
const ContentManagementPage = React.lazy(() =>
  import(basePathVrbt + "ContentManagementPage/")
);
const ContentManagementDetailPage = React.lazy(() =>
  import(basePathVrbt + "ContentListPage/ContentDetail/index.jsx")
);

const ReportPage = React.lazy(() => import(basePathVrbt + "ReportPage/"));
const InboxPage = React.lazy(() => import(basePathVrbt + "InboxPage/"));

const ContentListPage = React.lazy(() =>
  import(basePathVrbt + "ContentListPage/")
);
const CreateContent = React.lazy(() =>
  import(basePathVrbt + "ContentListPage/CreateContent")
);
const PreviewCreateContent = React.lazy(() =>
  import(basePathVrbt + "ContentListPage/Preview")
);
const ArtistPage = React.lazy(() => import(basePathVrbt + "ArtistPage/"));
const HelpPage = React.lazy(() => import(basePathVrbt + "HelpPage/"));
const AccountPage = React.lazy(() => import(basePathVrbt + "MyAccountPage/"));
const PartnerExtendPage = React.lazy(() =>
  import(basePathVrbt + "PartnershipExtendPage/")
);
const SignUpPage = React.lazy(() => import(basePathVrbt + "SignUpPage/"));
const ContentClass = React.lazy(() => import(basePathVrbt + "ContentClass/"));
const ContentClassDetail = React.lazy(() =>
  import(basePathVrbt + "ContentClass/ClassDetail")
);
const PartnerManagement = React.lazy(() =>
  import(basePathVrbt + "PartnerManagement/")
);
const PartnerDetail = React.lazy(() =>
  import(basePathVrbt + "PartnerManagement/PartnerDetail/PartnerDetail.jsx")
);
const PrivateRoute = React.lazy(() =>
  import(basePathVrbt + "PrivateRoutePage/PrivateRoute")
);
///// VRBT CMS

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <AuthProvider>
        <Router>
          <Routes>
            {/* VRBT CMS */}
            <Route path={`/`} element={<SignIn />} />
            <Route path="*" element={<SignIn />} />

            <Route path={baseVrbt} element={<PrivateRoute />}>
              <Route path="dashboard" element={<PageLayout />}>
                <Route index element={<Dashboard />} />
                <Route
                  path="content-management"
                  // element={<ContentManagementPage />}
                  element={<ContentListPage />}
                />
                <Route
                  path="content-management/content-list"
                  element={<ContentListPage />}
                />
                <Route
                  path="content-management/detail/*"
                  element={<ContentManagementDetailPage />}
                />
                <Route
                  path="content-management/content-list/create"
                  element={<CreateContent />}
                />
                <Route
                  path="content-management/content-list/preview"
                  element={<PreviewCreateContent />}
                />
                <Route
                  path="content-management/artist/talent"
                  element={<ArtistPage />}
                />
                <Route
                  path="content-management/content-class"
                  element={<ContentClass />}
                />
                <Route
                  path="content-management/content-class/*"
                  element={<ContentClassDetail />}
                />
                <Route
                  path="partner-management"
                  element={<PartnerManagement />}
                />
                <Route
                  path="partner-management/detail/*"
                  element={<PartnerDetail />}
                />
                <Route path="account" element={<AccountPage />} />
                <Route path="help" element={<HelpPage />} />
                <Route path="report" element={<ReportPage />} />
                <Route path="inbox/*" element={<InboxPage />} />
                <Route path="extend" element={<PartnerExtendPage />} />
              </Route>
            </Route>

            <Route path="signup" element={<SignUpPage />} />
            <Route path="policy" element={<>policy</>} />
            <Route path="tnc" element={<>tnc</>} />
            {/* VRBT CMS */}

            {/* <Route path={`/${baseTsel}`} element={<Signinpage />} />
          <Route path="*" element={<Signinpage />} />
          <Route path={basePath + "masuk"} element={<Signinpage />} /> */}
            {/* 
            path={basePath + "detail-perusahaan"}
            element={<Signuponepage />}
          /> */}
            {/* <Route
            path={`/${baseTsel}/detail-perusahaan`}
            element={<Signuponepage />}
          />
          <Route path={`/${baseTsel}/signup`} element={<Signuponepage />} />

          <Route path={`/${baseTsel}/tnc`} element={<TncPage />} />

          <Route path={`/${baseTsel}/faq`} element={<Faq />} />

          <Route path={`/${baseTsel}/policy`} element={<PolicyPage />} />

          <Route
            path={`/${baseTsel}/detail-rekening-bank`}
            element={<Signuptwopage />}
          />

          <Route
            path={`/${baseTsel}/detail-kontak`}
            element={<Signupthreepage />}
          />
          <Route
            path={basePath + "lupa-kata-sandi"}
            element={<Forgotpasswordonepage />}
          />

          <Route
            path={`/${baseTsel}/successPage/:link`}
            element={<SuccessPage />}
          />
          <Route path={`/${baseTsel}/selfcare`} element={<SelfCarePage />} />
          <Route
            path={basePath + "forgotpasswordtwopage"}
            element={<Forgotpasswordtwopage />}
          />
          <Route
            path={basePath + "forgotpasswordthreepage"}
            element={<Forgotpasswordthreepage />}
          />
          <Route
            path={basePath + "/forgotpasswordthreepage/:id"}
            element={<Forgotpasswordthreepage />}
          /> */}

            {/* Please define pages beneath /dashboard here */}

            {/* <Route path="/tsel/dashboard" element={<DashboardLayout />}>
            <Route index element={<HomeDashboard />} />
            <Route path="produk" element={<ProductPage />} />
            <Route path="produk/create" element={<HomeCreateProduct />} />
            <Route
              path="produk/detail/:id"
              element={<DetailproductactivepagePage />}
            />
            <Route
              path="laporan"
              element={<ProductreporttotalactiveuserspagePage />}
            />
            <Route path="kotak-masuk/*" element={<InboxpagePage />} />
            <Route path="partner-manajemen" element={<PartnerPage />} />
            <Route
              path="partner-manajemen/detail/:id"
              element={<PartnerDetailPage />}
            />
            <Route path="akun" element={<MyaccountcompanydetailpagePage />} />
            <Route path="status" element={<StatusactivepagePage />} /> */}

            {/* Iklan page */}
            {/* <Route path="iklan" element={<CreateIklan />} />
            <Route path="iklan/detail/:id" element={"Create Iklan"} /> */}
            {/* list iklan */}
            {/* <Route path="list-iklan" element={<ListIklan />} />
            <Route path="list-iklan/detail/:id" element={<DetailIklan />} /> */}
            {/* top up */}
            {/* <Route path="topup" element={<TopUp />} />
            <Route
              path="topup/detail/pembayaran/:id"
              element={<TopUpPembayaran />}
            />
            <Route path="topup/detail/invoice/:id" element={<TopUpInvoice />} /> */}
            {/* </Route> */}

            {/* end of dashboard route */}

            {/* <Route
            path={basePath + "dashboarddropdownpage"}
            element={<Dashboarddropdownpage />}
          />
          <Route
            path={basePath + "dashboardhelppage"}
            element={<Dashboardhelppage />}
          />
          <Route
            path={basePath + "createproductpage"}
            element={<Createproductpage />}
          />
          <Route
            path={basePath + "listproductwaitingpage"}
            element={<Listproductwaitingpage />}
          />
          <Route
            path={basePath + "detailprodcutwaitingonepage"}
            element={<Detailprodcutwaitingonepage />}
          />
          <Route
            path={basePath + "detailprodcutwaitingtwopage"}
            element={<Detailprodcutwaitingtwopage />}
          />
          <Route
            path={basePath + "listproductactivepage"}
            element={<Listproductactivepage />}
          />
          <Route
            path={basePath + "detailproductactivepage"}
            element={<Detailproductactivepage />}
          />
          <Route
            path={basePath + "listproductrejectedpage"}
            element={<Listproductrejectedpage />}
          />
          <Route
            path={basePath + "detailproductrejectedpage"}
            element={<Detailproductrejectedpage />}
          />
          <Route
            path={basePath + "listproductpausepage"}
            element={<Listproductpausepage />}
          />
          <Route
            path={basePath + "detailproductpausepage"}
            element={<Detailproductpausepage />}
          />
          <Route
            path={basePath + "listproductstoppage"}
            element={<Listproductstoppage />}
          />
          <Route
            path={basePath + "detailproductstoppage"}
            element={<Detailproductstoppage />}
          />
          <Route
            path={basePath + "myaccountcompanydetailpage"}
            element={<Myaccountcompanydetailpage />}
          />
          <Route
            path={basePath + "myaccountbankaccountpage"}
            element={<Myaccountbankaccountpage />}
          />
          <Route
            path={basePath + "myaccountcontactdetailpage"}
            element={<Myaccountcontactdetailpage />}
          />
          <Route
            path={basePath + "myaccountcontactdetaileditpage"}
            element={<Myaccountcontactdetaileditpage />}
          />
          <Route
            path={basePath + "statusactivepage"}
            element={<Statusactivepage />}
          />
          <Route
            path={basePath + "statusnotactivepage"}
            element={<Statusnotactivepage />}
          />
          <Route
            path={basePath + "extensiontwopage"}
            element={<Extensiontwopage />}
          />
          <Route
            path={basePath + "extensionthreepage"}
            element={<Extensionthreepage />}
          />
          <Route
            path={basePath + "extensiononepage"}
            element={<Extensiononepage />}
          />
          <Route
            path={basePath + "productreportrevenuerecappage"}
            element={<Productreportrevenuerecappage />}
          />
          <Route
            path={basePath + "productreportrequesttransactionpage"}
            element={<Productreportrequesttransactionpage />}
          />
          <Route
            path={basePath + "productreportdetailproductpage"}
            element={<Productreportdetailproductpage />}
          />
          <Route
            path={basePath + "productreportdetailpricepage"}
            element={<Productreportdetailpricepage />}
          />
          <Route
            path={basePath + "productreporttotalactiveuserspage"}
            element={<Productreporttotalactiveuserspage />}
          />
          <Route
            path={basePath + "productreportreportrecappage"}
            element={<Productreportreportrecappage />}
          />
          <Route
            path={basePath + "productreportstatusmediapage"}
            element={<Productreportstatusmediapage />}
          />
          <Route
            path={basePath + "productreportdrstatuspage"}
            element={<Productreportdrstatuspage />}
          />
          <Route
            path={basePath + "inboxreadpage"}
            element={<Inboxreadpage />}
          /> */}
          </Routes>
        </Router>
      </AuthProvider>
    </React.Suspense>
  );
};
export default ProjectRoutes;
