import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, {
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  eachDayOfInterval,
  format,
  startOfMonth,
  endOfMonth,
  compareDesc,
  isThisMonth,
} from "date-fns";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { utils, writeFile } from "sheetjs-style";

const TableReportDetail = forwardRef(
  ({ tableData, month, year, isLoading }, ref) => {
    const [data, setData] = useState([]);

    const staticColumns = useMemo(
      () => [
        {
          accessorKey: "media_id",
          header: "Media ID",
          className: "text-right",
        },
        {
          accessorKey: "service",
          header: "Keyword",
        },
        {
          accessorKey: "total",
          header: "Total",
          className: "text-right",
        },
      ],
      []
    );

    const generateDynamicColumns = (month, year) => {
      const start = startOfMonth(new Date(year, month - 1)); // month is 0-based in JavaScript Date
      let end = endOfMonth(new Date(year, month - 1));

      // Jika bulan dan tahun adalah bulan dan tahun ini, atur tanggal akhir ke hari ini
      if (isThisMonth(start)) {
        end = new Date();
      }

      const dates = eachDayOfInterval({ start, end });
      dates.sort((a, b) => compareDesc(a, b));

      return dates.map((date) => ({
        accessorKey: format(date, "dd"),
        header: format(date, "dd"),
        className: "text-right px-2 py-2",
      }));
    };

    const dynamicColumns = useMemo(
      () => generateDynamicColumns(month, year),
      [month, year]
    );
    const columns = useMemo(
      () => [...staticColumns, ...dynamicColumns],
      [staticColumns, dynamicColumns]
    );

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
    const rows = table.getPrePaginationRowModel().rows;

    const handleDownload = (type) => {
      if (type === "pdf") {
        const doc = new jsPDF("landscape");
        const maxColumnsPerTable = 15;

        const formatNumber = (value) => {
          return typeof value === "number"
            ? value.toLocaleString("de-DE")
            : value;
        };

        const dataTable = rows.map((row) =>
          columns.map((col) => formatNumber(row.original[col.accessorKey]))
        );
        const tableHeaders = columns.map((c) => c.header);

        const createPDFTable = (
          doc,
          headers,
          data,
          startX = 10,
          startY = 10
        ) => {
          autoTable(doc, {
            head: [headers],
            body: data,
            startY: startY,
            margin: { left: startX, right: startX },
            styles: {
              cellWidth: "wrap",
              halign: "right", // Align right by default
            },
            headStyles: {
              halign: "right", // Align headers to right by default
            },
            columnStyles: {
              0: { halign: "center" },
              1: { halign: "left" }, // First column align left
            },
          });
        };

        if (columns.length > maxColumnsPerTable) {
          let startY = 10;
          let remainingColumns = columns.length - 4;
          let currentColumns = 4;
          let currentTableData = [];

          const fixedColumns = columns.slice(0, 4);
          const fixedHeaders = fixedColumns.map((c) => c.header);
          const fixedData = dataTable.map((row) => row.slice(0, 4));

          while (remainingColumns > 0) {
            currentColumns = Math.min(remainingColumns, maxColumnsPerTable - 4);
            const startColumnIndex = columns.length - remainingColumns;
            const dynamicColumns = columns.slice(
              startColumnIndex,
              startColumnIndex + currentColumns
            );
            const dynamicHeaders = dynamicColumns.map((c) => c.header);
            const dynamicData = dataTable.map((row) =>
              row.slice(startColumnIndex, startColumnIndex + currentColumns)
            );

            const headers = [...fixedHeaders, ...dynamicHeaders];
            currentTableData = fixedData.map((row, index) => [
              ...row,
              ...dynamicData[index],
            ]);

            createPDFTable(doc, headers, currentTableData, 10, startY);

            startY = doc.autoTable.previous.finalY + 10;
            remainingColumns -= currentColumns;
          }
        } else {
          createPDFTable(doc, tableHeaders, dataTable);
        }

        doc.save("rekap-produk.pdf");
      } else if (type === "excel") {
        const formatNumber = (value) => {
          return typeof value === "number"
            ? value.toLocaleString("de-DE")
            : value;
        };

        const worksheetData = [columns.map((col) => col.header)];
        rows.forEach((row) => {
          worksheetData.push(
            columns.map((col) => row.original[col.accessorKey])
          );
        });

        const worksheet = utils.aoa_to_sheet(worksheetData);
        const columnWidths = columns.map(() => ({ wch: 15 }));
        worksheet["!cols"] = columnWidths;

        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };

        const backgroundStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align data cells to right by default
          },
        };

        const headerStyle = {
          fill: {
            patternType: "solid",
            fgColor: { rgb: "E4EDFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "right", // Align headers to right by default
          },
        };

        columns.forEach((col, colIndex) => {
          const cellAddress = utils.encode_cell({ r: 0, c: colIndex });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = {
              ...headerStyle,
              border: borderStyle,
              alignment: {
                ...headerStyle.alignment,
                horizontal: colIndex === 1 ? "left" : "right", // First header column align left
              },
            };
          }
        });

        worksheetData.forEach((row, rowIndex) => {
          row.forEach((_, colIndex) => {
            const cellAddress = utils.encode_cell({ r: rowIndex, c: colIndex });
            if (worksheet[cellAddress]) {
              if (rowIndex === 0) {
                worksheet[cellAddress].s = {
                  ...headerStyle,
                  border: borderStyle,
                  alignment: {
                    ...headerStyle.alignment,
                    horizontal: colIndex === 1 ? "left" : "right", // First header column align left
                  },
                };
              } else {
                worksheet[cellAddress].s = {
                  ...backgroundStyle,
                  border: borderStyle,
                  alignment: {
                    ...backgroundStyle.alignment,
                    horizontal: colIndex === 1 ? "left" : "right", // First column align left, others align right
                  },
                };
              }
            }
          });
        });

        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Report");
        writeFile(workbook, "rekap-produk.xlsx");
      }
    };

    useImperativeHandle(ref, () => ({
      handleDownload,
    }));

    useEffect(() => {
      if (tableData && tableData?.length > 0) {
        const allDates = generateDynamicColumns(month, year).map(
          (col) => col.accessorKey
        );

        const newData = tableData.map((entry) => {
          const dateValues = {};
          allDates.forEach((date) => {
            dateValues[date] = 0;
          });

          entry.dates?.forEach((d) => {
            dateValues[d.date_count] = Number(d.trx) || 0;
          });

          return {
            media_id: entry.media_id,
            service: entry.service,
            total: entry.total,
            ...dateValues,
          };
        });

        setData(newData);
      } else {
        setData([]);
      }
    }, [tableData, month, year]);

    return (
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`first:rounded-l-xl last:rounded-r-xl px-2 py-4 whitespace-nowrap bg-gray_100_01 text-blue_gray_400 text-left ${
                    header.column.columnDef.className || ""
                  }`}
                >
                  {header.column.columnDef.header}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading
            ? // Render skeleton rows
              Array.from({ length: 10 }).map((_, index) => (
                <tr key={index}>
                  {columns.map((col) => (
                    <td key={col.id}>
                      <Skeleton width={col.width || 100} height={30} />
                    </td>
                  ))}
                </tr>
              ))
            : table.getRowModel().rows.map((row, rowIndex) => (
                <React.Fragment key={row.id}>
                  {row.original.media_id && rowIndex !== 0 && (
                    <tr key={`separator-${row.id}`}>
                      <td
                        colSpan={columns.length}
                        style={{
                          borderBottom: "1px solid #EBEBEB",
                          height: "4px",
                        }}
                      ></td>
                    </tr>
                  )}
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={`px-2 py-2 whitespace-nowrap ${
                          cell.column.columnDef.className || ""
                        }`}
                      >
                        {cell.column.id === "total" ||
                        dynamicColumns
                          .map((col) => col.accessorKey)
                          .includes(cell.column.id)
                          ? Number(cell.getValue()).toLocaleString("id-ID")
                          : cell.getValue()}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
        </tbody>
      </table>
    );
  }
);

export default TableReportDetail;
