import React, { useState, useEffect } from "react";
import { SelectBox, Img, Button, Tabel } from "components";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getMethod } from "./Feature/methods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_PATH_IMAGE, baseUrl } from "app/constants";
import axios from "axios";
import {
  fetchLaporanMediaStatusColumns,
  fetchLaporanMediaStatusData,
} from "../Feature/tabelLaporan.slice";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { data_rekap } from "dummy-db/constant";
import Table from "./TableCustomFormatRecap";
import TableCustomFormatMedia from "./TableCustomFormatMedia";

const ReportMediaStatusComponents = ({
  tableData,
  onButtonClick,
}) => {
  const users = useSelector(userSignInSelector);
  // const users = useSelector((state) => state.userSignIn);
  const id = users.id || "";
  const idLevel = users.idLevel;
  const [methodData, setMethodData] = useState([]);
  const dispatch = useDispatch();

  /// Get Partner List
  const [partnerList, setPartnerList] = useState([]);
  useEffect(() => {
    try {
      async function fetchPartnerApis() {
        const dataResponse = await axios.get(`${baseUrl}partner_list`);
        if (dataResponse.data.response == null) {
          setPartnerList([]);
        } else {
          setPartnerList([{ value: 0, partner_name: "Semua Partner", email: "", level: "" }].concat(dataResponse.data.response));
        }
      }
      fetchPartnerApis();
    } catch (error) {
      setPartnerList([]);
      console.log(error);
    }
  }, []);

  //get method
  useEffect(() => {
    dispatch(getMethod())
      .then(unwrapResult)
      .then((obj) => {
        console.log({ obj });
        setMethodData(
          [{ id: 0, value: "Semua Metode" }].concat(
            obj.map((item, index) => {
              return { id: index + 1, value: item.price_method };
            })
          )
        );
        console.log(methodData);
      })
      .catch((obj) => {
        toast.error(obj.message, {
          position: "top-center",
          autoClose: 5000,
          theme: "colored",
        });
      });
  }, []);

  //

  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") ||
    startOfMonth.toISOString().split("T")[0]
  );

  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") || today.toISOString().split("T")[0]
  );

  // const [startDate, setStartDate] = useState(
  //   // localStorage.getItem("startDate") ||
  //   //   new Date(new Date().setDate(new Date().getDate() - 7))
  //   //     .toISOString()
  //   //     .split("T")[0]
  //   localStorage.getItem("startDate")
  // );
  // const [endDate, setEndDate] = useState(
  //   // localStorage.getItem("endDate") || new Date().toISOString().split("T")[0]
  //   localStorage.getItem("endDate")
  // );
  const [selectedMethod, setSelectedMethod] = useState(
    localStorage.getItem("selectedMethod4")
  );
  const [selectedPartner, setSelectedPartner] = useState(
    localStorage.getItem("selectedPartner4")
  );

  const convertDate = (inputFormat) => {
    const date = new Date(inputFormat);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    return [day, month, year].join("/");
  };

  const [endDateError, setEndDateError] = useState(false);
  const handleButtonClick = (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate);
      const formattedEndDate = new Date(endDate);
      if (formattedEndDate < formattedStartDate) {
        setEndDateError(true);
        // alert("Tanggal Akhir harus lebih besar dari Tanggal Mulai");
        toast.error("Tanggal Akhir harus lebih besar dari Tanggal Mulai", {
          position: "top-center",
          autoClose: 5000,
          theme: "colored",
        });
        return;
      } else {
        setEndDateError(false);
      }

      onButtonClick(convertDate(startDate), convertDate(endDate));
    } else {
      // alert("Tanggal Mulai dan Tanggal Akhir harus dipilih!");
      toast.error("Tanggal Akhir harus lebih besar dari Tanggal Mulai", {
        position: "top-center",
        autoClose: 5000,
        theme: "colored",
      });
    }
    console.log("🚀 ~ handleButtonClick ~ convertDate(startDate), convertDate(endDate):", convertDate(startDate), convertDate(endDate))
  };

  const [data, setData] = useState(data_rekap);

  const handleUpdate = () => {
    // You can add your logic to fetch data based on selected values
    setData(data === data_rekap);
  };

  const selectMonth = [
    { value: "apr", label: "April" },
    { value: "mei", label: "Mei" },
    { value: "jun", label: "Juni" },
  ];

  const selectYears = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ];

  const selectMethod = [
    { value: "all", label: "Premium" },
    { value: "all", label: "Pembelian 1x" },
    { value: "all", label: "Semua" },
  ];

  const selectDownload = [
    { value: "pdf", label: "PDF" },
    { value: "exl", label: "Excel" },
  ];

  const dummyData1 = {
    "Total": { "Permintaan Pembayaran": 100, "Tidak Diketahui": 20, "Gagal": 10, "Sukses": 70, "Pendapatan": 500, "Sukses Ratio": "70%" },
    "MO;PREMIUM;TEXT": { "Permintaan Pembayaran": 50, "Tidak Diketahui": 5, "Gagal": 3, "Sukses": 42, "Pendapatan": 300, "Sukses Ratio": "84%" },
    "MO;1X;TEXT": { "Permintaan Pembayaran": 50, "Tidak Diketahui": 15, "Gagal": 7, "Sukses": 28, "Pendapatan": 200, "Sukses Ratio": "56%" }
  };

  const dummyData2 = {
    "Total": { "Permintaan Pembayaran": 120, "Tidak Diketahui": 25, "Gagal": 15, "Sukses": 80, "Pendapatan": 600, "Sukses Ratio": "66%" },
    "MO;PREMIUM;TEXT": { "Permintaan Pembayaran": 60, "Tidak Diketahui": 10, "Gagal": 5, "Sukses": 45, "Pendapatan": 350, "Sukses Ratio": "75%" },
    "MO;1X;TEXT": { "Permintaan Pembayaran": 60, "Tidak Diketahui": 15, "Gagal": 10, "Sukses": 35, "Pendapatan": 250, "Sukses Ratio": "58%" }
  };



  //
  return (
    <>
      <div className="bg-white_A700 flex flex-col gap-6 items-start justify-center max-w-full p-[26px] sm:px-5 rounded-bl-[16px] rounded-br-[16px] rounded-tl-none rounded-tr-none w-full">
        <div className={idLevel === 1 || idLevel === 14 ? "grid grid-cols-6 gap-6 w-[100%]" : "grid grid-cols-7 gap-6 w-[100%]"} >
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <input
              type="date"
              className="w-full p-3 border-0 text-gray_800 rounded-md bg-white_A700 outline outline-[1px] outline-gray_200"
              value={startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                setStartDate(e.target.value);
                localStorage.setItem("startDate", e.target.value);
              }}
            />
          </div>
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <input
              type="date"
              className="w-full p-3 border-0 text-gray_800 rounded-md bg-white_A700 outline outline-[1px] outline-gray_200"
              value={endDate}
              // max={startDate}
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => {
                setEndDate(e.target.value);
                localStorage.setItem("endDate", e.target.value);
              }}
            />
          </div>
          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <SelectBox
              className="md:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
              placeholderClassName="text-gray_800"
              name="frame628351"
              // placeholder={selectedMethod || "Semua Metode"}
              placeholder="Premium"
              isSearchable={false}
              isMulti={false}
              options={selectMethod}
              // onChange={(value) => {
              //   // setMethodData(value);
              //   setSelectedMethod(value);
              //   localStorage.setItem("selectedMethod4", value);
              //   // console.log(value);
              // }}
              // getOptionLabel={(e) => (
              //   <div className="flex items-center">
              //     <span>{e.value}</span>
              //   </div>
              // )}
              indicator={
                <Img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/img_arrowdown_gray_800.svg"
                  }
                  className="h-6 w-6"
                  alt="arrow_down"
                />
              }
              shape="RoundedBorder6"
              size="sm"
              variant="OutlineGray200"
            />
          </div>

          {idLevel === 1 || idLevel === 14 ? (
            <div className="col-span-3 mdreal:col-span-1 place-items-center">
              <SelectBox
                className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
                placeholderClassName="text-gray_800"
                name="frame628350"
                placeholder={
                  localStorage.getItem("selectedPartnerName4") ||
                  "Semua Partner"
                }
                isSearchable={false}
                isMulti={false}
                options={partnerList}
                getOptionLabel={(e) => (
                  <div className="flex items-center">
                    <span>{e.partner_name}</span>
                    {localStorage.setItem(
                      "selectedPartnerName4",
                      e.partner_name
                    )}
                  </div>
                )}
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineGray200"
                onChange={(value) => {
                  setSelectedPartner(value);
                  localStorage.setItem("selectedPartner4", value);
                  // console.log(value);
                }}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[104px]  w-[125px] h-[47px]"
              leftIcon={
                <Img
                  src={
                    process.env.PUBLIC_URL + "/images/img_search_white_a700.svg"
                  }
                  className="mr-2.5"
                  alt="search"
                />
              }
              shape="RoundedBorder8"
              size="md"
              variant="OutlineGray200"
              onClick={handleButtonClick}
            >
              <div className="font-semibold leading-[normal] text-left text-sm text-white_A700 w-[150px]">
                Update
              </div>
            </Button>
          </div>

          {idLevel === 1 || idLevel === 14 ? (
            // <div className="col-span-3 mdreal:col-span-1 place-items-center">
            <div className="justify-end place-items-center">
              <SelectBox
                className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full"
                placeholderClassName="text-gray_800"
                name="frame628350"
                placeholder="Unduh Laporan"
                isSearchable={false}
                isMulti={false}
                options={selectDownload}
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineGray200"
              />
            </div>
          ) : (
            <div className="flex justify-end col-span-3 place-items-center">
              <SelectBox
                className="sm:flex-1 font-semibold leading-[normal] text-gray_800 text-left text-sm sm:w-full w-[175px]"
                placeholderClassName="text-gray_800"
                name="frame628350"
                placeholder="Unduh Laporan"
                isSearchable={false}
                isMulti={false}
                options={selectDownload}
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineGray200"
              />
            </div>
          )}

        </div>
        <div className="bg-white_A700 flex flex-col gap-[26px] items-start justify-start w-full overflow-x-auto">
          {/* <Tabel
            tableHeader={tableHeader}
            tableData={tableData}
            isCurency={false}
          /> */}
          <TableCustomFormatMedia data={tableData} />
        </div>
      </div>
    </>
  );
};

export default ReportMediaStatusComponents;
