import React, { useState, useEffect } from "react";
import { Img, Line, Text } from "components";
import { useDispatch, useSelector } from "react-redux";
import InformationcontactpouppageModal from "modals/Informationcontactpouppage/Informationcontactpouppage";
import DropdownMenuComponent from "./Components/dropdown_menu_component";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-router-dom";
import HeaderNavDropDown from "./Components/HeaderNavDropDown";
import {
  getInboxes,
  getInboxesSelector,
} from "pages/Inboxpage/Features/inboxSlice";
import { userSignInSelector } from "pages/Signinpage/Features/signInSlice";
import { useGetMessagesMutation } from "app/api1";

const DashboardPageHeader = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const users = useSelector(userSignInSelector);
  const [getMessages] = useGetMessagesMutation();
  // const users = useSelector((state) => state.userSignIn);
  const inboxes = useSelector(getInboxesSelector.selectAll);
  const [isOpen, setIsOpen] = useState(true);

  const hasUnreadMessages = inboxes.some((inbox) => inbox.status === 1);
  const dispatch = useDispatch();

  const countMessage = useSelector((state) => state.messageNotification.count);

  useEffect(() => {
    let messageCount = localStorage.getItem("messageCount");
    let readMessages = localStorage.getItem("readMessages");
    getMessages({ uid: users.id })
      .unwrap()
      .then((payload) => {
        const countStatusOne = payload.reduce((count, item) => {
          return item.status === 1 ? count + 1 : count;
        }, 0);

        if (countStatusOne > 1) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      });
  }, [countMessage]);

  useEffect(() => {
    let messageCount = localStorage.getItem("messageCount");
    let readMessages = localStorage.getItem("readMessages");
    if (messageCount && readMessages) {
      if (parseInt(messageCount) !== parseInt(readMessages)) {
        setIsOpen(false);
        console.log("open");
      }
      console.log("close");
    }
  }, []);

  useEffect(() => {
    if (users && users.id) {
      dispatch(getInboxes({ uid: users.id }));
    }
    getMessages({ uid: users.id })
      .unwrap()
      .then((payload) => {
        const countStatusOne = payload.reduce((count, item) => {
          return item.status === 1 ? count + 1 : count;
        }, 0);

        if (countStatusOne > 1) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      });
  }, []);

  return (
    <>
      <div className={props.className}>
        <header className="flex items-center justify-between mdreal:justify-end w-full my-2">
          <div className="block mdreal:hidden px-7">
            <button onClick={props.toggleSideBar}>
              <BiMenu className="w-8 h-8" />
            </button>
          </div>
          <div className="flex items-center justify-end gap-7 px-8 py-2">
            <Img
              src={
                process.env.PUBLIC_URL +
                "/images/img_solarquestioncirclebold.svg"
              }
              className="h-8 w-8"
              style={{ cursor: "pointer" }}
              alt="solarquestionci"
              onClick={() =>
                !isOpened ? setIsOpened(true) : setIsOpened(false)
              }
            />

            <InformationcontactpouppageModal
              isOpen={isOpened}
              setIsOpenParse={setIsOpened}
            />

            <Link to="kotak-masuk">
              <Img
                src={
                  isOpen
                    ? process.env.PUBLIC_URL + "/images/img_ionmailread.svg"
                    : process.env.PUBLIC_URL + "/images/img_ionmailunread.svg"
                }
                className="h-8 w-8"
                style={{ cursor: "pointer" }}
                alt="ionmailunread"
              />
            </Link>

            <Line className="hidden mdreal:block bg-gray_100 h-[37px] sm:h-px sm:w-full w-px" />

            <div className="flex flex-row gap-4 mdreal:gap-[22px] items-center justify-start self-stretch w-auto">
              <Text
                className="bg-light_blue_500_33 flex font-proximanova h-8 mdreal:h-[50px] items-center justify-center rounded-[50%] text-center text-light_blue_500 w-8 mdreal:w-[50px] !text-xs mdreal:!text-xl"
                as="h3"
                variant="h3"
              >
                {props?.groupone}
              </Text>
              <div className="flex flex-col items-start justify-center self-stretch w-auto">
                <Text
                  className="font-medium font-poppins text-gray_800 text-left w-auto !text-xs mdreal:!text-base"
                  as="h5"
                  variant="h5"
                >
                  {props?.theoedwards}
                </Text>
                <Text
                  className="font-normal font-poppins not-italic text-blue_200 text-left tracking-[0.60px] w-auto !text-[10px] mdreal:text-xs"
                  variant="body2"
                >
                  {props?.administrator}
                </Text>
              </div>
              <HeaderNavDropDown />
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

DashboardPageHeader.defaultProps = {
  groupone: "TE",
  theoedwards: "PT Digital Technology",
  administrator: "Partner",
};

export default DashboardPageHeader;
