import { BASE_PATH_IMAGE } from "app/constants";
import { Button, Img, List, Text } from "components";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../app/constants";
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../Features/store";
import { useUpdateMessageMutation } from "app/api1";

const MessageList = ({ messages }) => {
  const [prev, setPrev] = useState(0);
  const [next, setNext] = useState(5);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [readMessages, setReadMessages] = useState([]);
  const [isopen, setIsOpen] = useState([]);
  const [updateMessage] = useUpdateMessageMutation();

  const countMessage = useSelector((state) => state.messageNotification.count);
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize state from localStorage
    const storedReadMessages =
      JSON.parse(localStorage.getItem("readMessages")) || [];
    const storedIsOpen = JSON.parse(localStorage.getItem("isopen")) || [];
    setReadMessages(storedReadMessages);
    setIsOpen(storedIsOpen);
  }, []);

  useEffect(() => {
    // Persist readMessages to localStorage
    const user = JSON.parse(localStorage.getItem("user"));
    let name = "readMessages_" + user.id;
    localStorage.setItem(name, JSON.stringify(readMessages));
    localStorage.setItem("readMessages", JSON.stringify(readMessages));
  }, [readMessages]);

  useEffect(() => {
    // Persist isopen to localStorage
    localStorage.setItem("isopen", JSON.stringify(isopen));
    localStorage.setItem("isopen", JSON.stringify(isopen));
    localStorage.setItem("readMessages", isopen.length);
  }, [isopen]);

  const handleCounterNext = () => {
    if (next < messages.length) {
      setPrev(next);
      setNext(next + 5);
      setAtStart(false);
      if (next + 5 >= messages.length) {
        setAtEnd(true);
      } else {
        setAtEnd(false);
      }
    }
  };

  const handleCounterPrev = () => {
    if (prev > 0) {
      setPrev(prev - 5);
      setNext(next - 5);
      setAtEnd(false);
      if (prev - 5 <= 0) {
        setAtStart(true);
      }
    }
  };

  const handleMessageClick = async (id) => {
    setSelectedMessage(id);
    if (!isopen.includes(id)) {
      const updatedReadMessages = [...isopen, id];
      setReadMessages(updatedReadMessages);

      try {
        const response = await fetch(`${baseUrl}update_inboxes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);

        updateMessage({ id: id })
          .unwrap()
          .then((payload) => {
            console.log(payload);
          });

        const updatedIsOpen = [...isopen, id];
        setIsOpen(updatedIsOpen);
        localStorage.setItem("isOpen", isopen);
        // localStorage.setItem("readMessages", isopen.length);
        dispatch(increment());
        console.log("messages click count: ", countMessage);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="w-[420px] min-h- border-r border-gray_200">
      <div className="bg-white_A700 border-b border-gray_200 border-solid flex flex-col items-start justify-start p-8 sm:px-5 w-full">
        <div className="flex flex-row gap-4 items-center justify-start w-full">
          <Text
            className="flex-1 font-semibold text-gray_800 text-left w-auto"
            as="h4"
            variant="h4"
          >
            Kotak Masuk
          </Text>
          <Text
            className="font-normal not-italic text-gray_800 text-left tracking-[0.14px] w-auto"
            variant="body1"
          >
            {next !== 5 ? prev : 1}-
            {next > messages.length ? messages.length : next} dari{" "}
            {messages.length}
          </Text>
          <div className="flex">
            <button type="button" onClick={handleCounterPrev}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.0417 2.91602C14.25 3.12435 14.3542 3.37102 14.3542 3.65602C14.3542 3.94102 14.25 4.1874 14.0417 4.39518L7.93749 10.4993L14.0625 16.6243C14.2569 16.8188 14.3542 17.0618 14.3542 17.3535C14.3542 17.6452 14.25 17.8952 14.0417 18.1035C13.8333 18.3118 13.5867 18.416 13.3017 18.416C13.0167 18.416 12.7703 18.3118 12.5625 18.1035L5.56249 11.0827C5.47916 10.9993 5.41999 10.9091 5.38499 10.8118C5.34999 10.7146 5.33277 10.6105 5.33332 10.4993C5.33332 10.3882 5.35082 10.2841 5.38582 10.1868C5.42082 10.0896 5.47971 9.99935 5.56249 9.91601L12.5833 2.89518C12.7778 2.70074 13.0175 2.60352 13.3025 2.60352C13.5875 2.60352 13.8339 2.70768 14.0417 2.91602Z"
                  fill={atStart ? "#C5C5C5" : "#404040"}
                />
              </svg>
            </button>
            <button type="button" onClick={handleCounterNext}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.95834 18.084C5.75001 17.8757 5.64584 17.629 5.64584 17.344C5.64584 17.059 5.75001 16.8126 5.95834 16.6048L12.0625 10.5007L5.93751 4.37565C5.74307 4.18121 5.64584 3.93815 5.64584 3.64648C5.64584 3.35482 5.75001 3.10482 5.95834 2.89648C6.16668 2.68815 6.41334 2.58398 6.69834 2.58398C6.98334 2.58398 7.22973 2.68815 7.43751 2.89648L14.4375 9.91732C14.5208 10.0007 14.58 10.0909 14.615 10.1882C14.65 10.2854 14.6672 10.3895 14.6667 10.5007C14.6667 10.6118 14.6492 10.7159 14.6142 10.8132C14.5792 10.9104 14.5203 11.0007 14.4375 11.084L7.41668 18.1048C7.22223 18.2993 6.98251 18.3965 6.69751 18.3965C6.41251 18.3965 6.16612 18.2923 5.95834 18.084Z"
                  fill={atEnd ? "#C5C5C5" : "#404040"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <List
        className="flex-col gap-px grid items-center w-full"
        orientation="vertical"
      >
        {messages.slice(prev, next).map((message) => (
          <Link
            key={message.id}
            to={`${message.id}`}
            relative="path"
            onClick={() => handleMessageClick(message.id)}
          >
            <div
              className={`border-b border-gray_200 border-solid flex flex-1 flex-row gap-2.5 items-center justify-start sm:px-5 px-8 py-[22px] w-full ${
                message.status === 0 ? "bg-white_A700" : "bg-gray-200"
              } ${selectedMessage === message.id ? "bg-blue-50" : ""}`}
            >
              <div className="flex h-[70px]">
                <Button
                  className="flex h-16 items-center justify-center rounded-[50%] w-16"
                  size="mdIcn"
                  variant="icbFillBluegray300"
                >
                  {message.status === 0 ? (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ion_mail-open-outline.svg`}
                      className="h-7"
                      alt="mail"
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/img_mail_white_a700.svg`}
                      className="h-7"
                      alt="mail"
                    />
                  )}
                </Button>
              </div>
              <div className="flex flex-col gap-1 items-start justify-start self-stretch w-auto">
                <Text
                  className="font-medium text-gray_800 text-left w-auto"
                  as="h5"
                  variant="h5"
                >
                  {message.title}
                </Text>
                <Text
                  className="font-normal not-italic text-blue_gray_400 text-left tracking-[0.14px] w-auto"
                  variant="body1"
                >
                  {message.date}
                </Text>
              </div>
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};

export default MessageList;
