import React, { useState, useEffect } from "react";
import { Img, Line, Text } from "components";
import { BiMenu } from "react-icons/bi";

import HeaderNavDropDown from "./HeaderNavDropDown";

const DashboardPageHeader = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const users = {
    id: 1,
  };

  useEffect(() => {
    let messageCount = localStorage.getItem("messageCount");
    let readMessages = localStorage.getItem("readMessages");
    if (messageCount && readMessages) {
      if (parseInt(messageCount) !== parseInt(readMessages)) {
        setIsOpen(false);
        console.log("open");
      }
      console.log("close");
    }
  }, []);

  return (
    <>
      <div className={props.className}>
        <header className="flex items-center justify-between mdreal:justify-end w-full my-2">
          <div className="block mdreal:hidden px-7">
            <button onClick={props.toggleSideBar}>
              <BiMenu className="w-8 h-8" />
            </button>
          </div>
          <div className="flex items-center justify-end gap-7 px-8 py-2">
            {/* <Img
              src={
                process.env.PUBLIC_URL +
                "/images/img_solarquestioncirclebold.svg"
              }
              className="h-8 w-8"
              style={{ cursor: "pointer" }}
              alt="solarquestionci"
              onClick={() =>
                !isOpened ? setIsOpened(true) : setIsOpened(false)
              }
            />

            <InformationcontactpouppageModal
              isOpen={isOpened}
              setIsOpenParse={setIsOpened}
            /> */}

            {/* <Link to="kotak-masuk">
              <Img
                src={
                  isOpen
                    ? process.env.PUBLIC_URL + "/images/img_ionmailread.svg"
                    : process.env.PUBLIC_URL + "/images/img_ionmailunread.svg"
                }
                className="h-8 w-8"
                style={{ cursor: "pointer" }}
                alt="ionmailunread"
              />
            </Link> */}

            <Line className="hidden mdreal:block bg-gray_100 h-[37px] sm:h-px sm:w-full w-px" />

            <div className="flex flex-row gap-4 mdreal:gap-[22px] items-center justify-start self-stretch w-auto">
              <Text
                className="bg-[#DAE0E9] flex font-proximanova h-8 mdreal:h-[50px] items-center justify-center rounded-[50%] text-center text-[#404040] w-8 mdreal:w-[50px] !text-xs mdreal:!text-xl"
                as="h3"
                variant="h3"
              >
                {props?.groupone}
              </Text>
              <div className="flex flex-col items-start justify-center self-stretch w-auto">
                <Text
                  className="font-medium font-poppins text-gray_800 text-left w-auto !text-xs mdreal:!text-base"
                  as="h5"
                  variant="h5"
                >
                  {props?.theoedwards}
                </Text>
                <Text
                  className="font-normal font-poppins not-italic text-[#ED0226] text-left tracking-[0.60px] w-auto !text-[10px] mdreal:text-xs"
                  variant="body2"
                >
                  {props?.administrator}
                </Text>
              </div>
              <HeaderNavDropDown />
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

DashboardPageHeader.defaultProps = {
  groupone: "TE",
  theoedwards: "PT Digital Technology",
  administrator: "Partner",
};

export default DashboardPageHeader;
