import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);

const LineAreaChart = ({
  dataset1Name,
  dataset1,
  showDataset1,
  dataset2Name,
  dataset2,
  showDataset2,
  labels,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: dataset2Name,
        data: dataset2,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: true,
        tension: 0.2,
        hidden: showDataset2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value / 1000000 + " Jt";
          },
          stepSize: 500000,
        },
      },
    },
  };

  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

export default LineAreaChart;
