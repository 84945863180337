import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "app/constants";

export const getServiceKeyword = createAsyncThunk(
  "service/getServiceKeyword",
  async ({ appid }) => {
    /// ******** API product ********
    const response = await axios.post(`${baseUrl}get_all_service_keyword`, {
      app_id: appid,
    });
    return response.data.response;
  }
);

// const productsInEntity = createEntityAdapter({
//   selectId: (getProduct) => getProduct.service_name,
// });

// const productsSlice = createSlice({
//   name: "products",
//   initialState: productsInEntity.getInitialState(),
//   extraReducers: {
//     [getProduct.pending]: (state) => {
//       console.log("pending");
//     },
//     [getProduct.fulfilled]: (state, action) => {
//       productsInEntity.setAll(state, action.payload);
//     },
//     [getProduct.rejected]: (state) => {
//       console.log("rejected");
//     },
//   },
// });

// export const productSelector = productsInEntity.getSelectors(
//   (state) => state.product
// );
// export default productsSlice.reducer;
