import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "app/constants";

export const getMethod = createAsyncThunk("method/getMethod", async () => {
  /// ******** API method ********
  const response = await axios.get(`${baseUrl}get_payment_method`);
  return response.data.response;
});

const methodsInEntity = createEntityAdapter({
  selectId: (method) => method.methods_id,
});

const methodsSlice = createSlice({
  name: "methods",
  initialState: methodsInEntity.getInitialState(),
  extraReducers: {
    [getMethod.pending]: (state) => {
      // console.log('pending')
    },
    [getMethod.fulfilled]: (state, action) => {
      methodsInEntity.setAll(state, action.payload);
    },
    [getMethod.rejected]: (state) => {
      // console.log('rejected')
    },
  },
});

export const methodSelector = methodsInEntity.getSelectors(
  (state) => state.method
);
export default methodsSlice.reducer;
